import { useContext, useState, useEffect } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { convertIPFSToHTTPS, getNftType, getPaymentTokenDecimalsByAddress } from 'src/utils/helper';
import ReactPlayer from 'react-player/lazy';
import { AppContext } from 'src/contexts/AppContext';
import { getTokenSymbol } from '../utils/helper';
import { convertFromWei } from 'src/utils/blockchainInteractor';
import moment from 'moment';
import { NftType } from '../constant';
import { getMetaByHashedKey } from '../services/metaService';

const WithdrawCard = ({ hashedKey, withdrawal }) => {
  const { t } = useTranslation(['component']);
  const { account, web3 } = useContext(AppContext);
  const [nftDetails, setNftDetails] = useState<Nft.Information>();
  const [decimals, setDecimals] = useState(18);

  useEffect(() => {
    init();
  }, [hashedKey]);

  const init = async () => {
    const { data: nft } = await getMetaByHashedKey(hashedKey);
    if (!nft.tokenID) {
      return;
    }
    if (nft) {
      setNftDetails({
        NFTImage: nft?.NFTImage,
        title: nft?.title,
        contentType: nft?.contentType,
        hashedKey: hashedKey,
      });
    }
    const dec = getPaymentTokenDecimalsByAddress(withdrawal?.tokenPayment);
    setDecimals(dec);
  };

  return (
    <div className="d-item col-lg-3 col-md-3 col-sm-6 col-xs-12 mb-4">
      <div
        className={classNames({
          'nft__item m-1 p-3': true,
        })}
      >
        <div className="nft__item_wrap mt-0">
          {getNftType(nftDetails?.contentType as string) === NftType.Audio ||
          getNftType(nftDetails?.contentType as string) === NftType.Video ? (
            <ReactPlayer
              controls
              loop
              playing
              width="100%"
              height={150}
              url={convertIPFSToHTTPS(nftDetails?.NFTImage || '')}
            />
          ) : (
            <img
              src={convertIPFSToHTTPS(nftDetails?.NFTImage || '') || '/assets/images/preloader.png'}
              alt={nftDetails?.title}
              style={{ height: '5.75rem' }}
            />
          )}
        </div>
        <div className="d-flex text-primary justify-content-between">
          <span className="text-white">{t('Refund')}</span>
          <span className="text-success">
            {Number(convertFromWei(withdrawal.offerPrice, decimals)).toFixed(3)} &nbsp;
            {getTokenSymbol(withdrawal?.tokenPayment)}
          </span>
        </div>
        <div className="d-flex justify-content-between">
          <span className="text-white">{t('Bid At')}</span>
          <span className="text">{moment.unix(withdrawal.bidAt).format('lll')}</span>
        </div>
      </div>
    </div>
  );
};

export default WithdrawCard;
