import Web3 from 'web3';
import { provider } from 'web3-core';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import type { AbiItem } from 'web3-utils';
import React, { useContext } from 'react';
import tokenConfig from '../configs/tokenConfig';
import {
  toppyMarketPlace,
  toppyRedeem,
  toppyMint,
  toppyEventHistory,
  toppyMaster,
  toppyStaking,
  moralisXWinAddress,
  wbnbaddress,
  xwinaddress,
  usdtaddress,
} from '../constant';
import { getGasPrice } from 'src/utils/helper';
import { moralisGetMaticPrice, moralisGetPrice, moralisGetXWINPrice } from './moralisInteractor';
import ERC721 from './contracts/toppyStandardNFT.json';
import ERC20 from './contracts/ERC20.json';
import EventHistory from './contracts/toppyEventHistory.json';
import ToppyMint from './contracts/toppyMint.json';
import ToppyStaking from './contracts/toppyStaking.json';
import MasterSetting from './contracts/toppyMaster.json';
import ToppyRedeem from './contracts/toppyRedeem.json';
import MarketPlaceNew from './contracts/toppyMarketplace.json';
import { getNetworkById } from 'src/utils/helper';
import systemConfig from 'src/configs/systemConfig';
import Torus from "@toruslabs/torus-embed";
import Authereum from "authereum";
import { ethers } from "ethers";

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        56: '/',
      },
      network: 'binance',
    },
  },
  torus: {
    package: Torus,
    options: {
      networkParams: {
        host: getNetworkById(systemConfig.chainId).rpcUrls[0], // optional
        chainId: getNetworkById(systemConfig.chainId).chainId, // optional
        networkId: getNetworkById(systemConfig.chainId).chainId // optional
      },
    }
  },
  authereum: {
    package: Authereum // required
  },
};
export const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions,
});

const providerNetwork = getNetworkById(systemConfig.chainId).rpcUrls[0]; // tokenConfig.default.network;

//hardcode testnet
export const useNetwork = () => {
  const [account, setAccount] = React.useState<string>('');
  const [network, setNetwork] = React.useState<number>();
  const [isConnected, setIsConnected] = React.useState<boolean>();
  const [baseBalance, setBaseBalance] = React.useState<string>();
  const [web3, setWeb3] = React.useState(new Web3(getNetworkById(systemConfig.chainId).rpcUrls[0]));
  const [prices, setPrices] = React.useState({
    xwin: 1,
    bnb: 1,
    usdt: 1,
  });

  const connectWallet = async (): Promise<string | undefined> => {
    try {
      web3Modal.clearCachedProvider();
      const provider = await web3Modal.connect();
      await (provider as any).enable();
      web3.setProvider(provider);
      setIsConnected(true);
      let accounts = await web3.eth.getAccounts();
      if (accounts.length == 0) return;
      let balance = await web3.eth.getBalance(accounts[0]);
      balance = web3.utils.fromWei(balance.toString());
      setBaseBalance(Number(balance).toFixed(5));
      setAccount(accounts[0] as string);
      setWeb3(web3);
      getPrices();
      const chainId = await web3.eth.getChainId();
      setNetwork(chainId);
      return accounts[0];
    } catch (error) {
      console.log(error);
    }
  };

  const connectCached = async (): Promise<string | undefined> => {
    if (web3Modal.cachedProvider) {
      try {
        const provider = await web3Modal.connect();
        await (provider as any).enable();
        web3.setProvider(provider);
        setIsConnected(true);
        let accounts = await web3.eth.getAccounts();
        if (accounts.length == 0) return;
        let balance = await web3.eth.getBalance(accounts[0]);
        balance = web3.utils.fromWei(balance.toString());
        setBaseBalance(Number(balance).toFixed(5));
        setAccount(accounts[0] as string);
        setWeb3(web3);
        getPrices();
        const chainId = await web3.eth.getChainId();
        setNetwork(chainId);
        return accounts[0];
      } catch (error) {
        console.log(error);
      }
    }
    return undefined;
  };

  const disconnectWallet = async () => {
    localStorage.clear();
    web3Modal.clearCachedProvider();
    if (web3 && web3.currentProvider && (web3.currentProvider as any).close) {
      await (web3.currentProvider as any).close();
    }
    setAccount('');
    setBaseBalance('0');
    setWeb3(new Web3(providerNetwork));
    setIsConnected(false);
    setNetwork(0);
  };

  const getCurrentProvider = (): provider => {
    return web3.currentProvider as provider;
  };

  const getCurrentWalletBalance = async () => {
    let accounts = await web3.eth.getAccounts();
    let balance = await web3.eth.getBalance(accounts[0]);
    return web3.utils.fromWei(balance.toString());
  };

  const getPrices = async () => {
    const xwinPrice = await moralisGetXWINPrice();
    const bnbPrice = (systemConfig.chainId==137 || systemConfig.chainId==80001) ? await moralisGetMaticPrice() : await moralisGetPrice(wbnbaddress) ;
    
    if (xwinPrice && bnbPrice) {
      setPrices({
        xwin: xwinPrice,
        bnb: bnbPrice,
        usdt: 1,
      });
    }
  };

  return {
    prices,
    account,
    isConnected,
    baseBalance,
    web3,
    network,
    connectWallet,
    disconnectWallet,
    getCurrentProvider,
    getCurrentWalletBalance,
    setAccount,
    setIsConnected,
    connectCached,
  };
};

export function convertFromWei(_number: string, decimals : number = 18) {
  if (_number == null) {
    return '0';
  }
  if (_number == undefined) {
    return '0';
  }
  if (isNaN(parseFloat(_number))) {
    return '0';
  }
  return ethers.utils.formatUnits(_number, decimals).toString();
}

export function convertToWei(_number: string, decimals : number = 18) {  
  return ethers.utils.parseUnits(_number, decimals).toString();
}

export async function getTimeStampFromBlockNumber(web3: Web3, blocknumber: string) {
  try {
    const block = await web3.eth.getBlock(blocknumber);
    return block.timestamp;
      
  } catch (error) {
    console.log(error)
    return "0";
  }
}

export function loadListingContract(web3: Web3) {
  return new web3.eth.Contract(MarketPlaceNew as unknown as AbiItem, toppyMarketPlace);
}

export function loadMintContract(web3: Web3) {
  return new web3.eth.Contract(ToppyMint as unknown as AbiItem, toppyMint);
}

export function loadStakingContract(web3: Web3) {
  return new web3.eth.Contract(ToppyStaking as unknown as AbiItem, toppyStaking);
}

export function loadDynamicERC721(web3: Web3, nftContract: string) {
  return new web3.eth.Contract(ERC721 as unknown as AbiItem, nftContract);
}

export function loadEventHistoryContract(web3: Web3) {
  return new web3.eth.Contract(EventHistory as unknown as AbiItem, toppyEventHistory);
}

export function loadMasterSettingContract(web3: Web3) {
  return new web3.eth.Contract(MasterSetting as unknown as AbiItem, toppyMaster);
}

export function loadToppyRedeemContract(web3: Web3) {
  return new web3.eth.Contract(ToppyRedeem as unknown as AbiItem, toppyRedeem);
}

export function loadERC20Contract(web3: Web3, contractaddress) {
  return new web3.eth.Contract(ERC20 as unknown as AbiItem, contractaddress);
}

export async function isTokenApprove(
  account: string,
  web3: Web3,
  amount = '0',
  contractaddress,
  targetContract: string = toppyMarketPlace,
) {
  const sourceToken = new web3.eth.Contract(ERC20 as unknown as AbiItem, contractaddress);
  let contractAllowance = await sourceToken.methods.allowance(account, targetContract).call();
  const decimals = await sourceToken.methods.decimals().call();
  return contractAllowance >= parseFloat(convertToWei(amount, decimals));
}

export async function isEnoughBalance(
  account: string,
  web3: Web3,
  tokenAddress: string,
  amountCompare: string,
) {
  if (
    tokenAddress.toLowerCase() === xwinaddress.toLowerCase() ||
    tokenAddress.toLowerCase() === usdtaddress.toLowerCase()
  ) {
    const contract = loadERC20Contract(web3, tokenAddress);
    const decimals = await contract.methods.decimals().call();
    const balance = await contract.methods.balanceOf(account).call();
    const bal = convertFromWei(balance, decimals);
    return Number(bal) >= Number(amountCompare);
  } else {
    let accounts = await web3.eth.getAccounts();
    const balance = await web3.eth.getBalance(accounts[0]);
    const bal = convertFromWei(balance);
    return Number(bal) >= Number(amountCompare);
  }
}

export async function approvePaymentToken(
  account: string,
  web3: Web3,
  contractaddress,
  targetContract = toppyMarketPlace,
) {
  const sourceToken = new web3.eth.Contract(ERC20 as unknown as AbiItem, contractaddress);
  try {
    const latestGasPrice:any = await getGasPrice(web3);
    await sourceToken.methods.approve(targetContract, '1000000000000000000000000000').send({
      from: account,
      value: 0,
      ...latestGasPrice
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export function getTokenIdFromTxn(web3: Web3, txn) {
  if (systemConfig.chainId == 80001 || systemConfig.chainId == 137) {
    return web3.utils.hexToNumber(txn.events[2].raw.topics[3]).toString();
  }
  return web3.utils.hexToNumber(txn.events[0].raw.topics[3]).toString();
}

export const getHashKey = (nftContract: string, tokenId: string, web3: Web3) => {
  let hashAddress = web3.eth.abi.encodeParameter('address', String(nftContract));
  let hashId = web3.eth.abi.encodeParameter('int256', String(tokenId));
  return web3.utils.soliditySha3(hashAddress, hashId) || '';
};

// export async function walletOfOwner (nftContract: string, owner: string, web3: Web3) {
//   const contract = loadDynamicERC721(web3, nftContract)
//   let tokenIds: string[] = [];
//   const ownerTokenCount = await contract.methods.balanceOf(owner).call();
//   for (let i=0; i < ownerTokenCount; i++) {
//     const tokenId = await contract.methods.tokenOfOwnerByIndex(owner, i).call();
//     tokenIds.push(tokenId)
//   }
//   return tokenIds;
// }
