import Web3 from 'web3';
import type { AbiItem } from 'web3-utils';
import { convertIPFSToHTTPS } from '../utils/helper';
import { moralisGetNFT } from './moralisInteractor';
import toppyMysteriousNFT from './contracts/toppyMysteriousNFT.json';
import { convertToWei, getHashKey, loadMintContract } from './blockchainInteractor';
import { getGasPrice } from 'src/utils/helper';

export function loadMysteriousERC721(web3: Web3, nftContract: string) {
  return new web3.eth.Contract(toppyMysteriousNFT as unknown as AbiItem, nftContract);
}

export async function getTotalSupply(web3: Web3, nftContract: string) {
  const contract = loadMysteriousERC721(web3, nftContract);

  try {
    const txn = await contract.methods.totalSupply().call();

    return txn;
  } catch (e) {
    return null;
  }
}

export async function getMaxSupply(web3: Web3, nftContract: string) {
  const contract = loadMysteriousERC721(web3, nftContract);

  try {
    const txn = await contract.methods.maxSupply().call();

    return txn;
  } catch (e) {
    return null;
  }
}

export async function getCostPerNFT(web3: Web3, nftContract: string) {
  const contract = loadMysteriousERC721(web3, nftContract);

  try {
    const txn = await contract.methods.cost().call();

    return txn;
  } catch (e) {
    return null;
  }
}

export async function getOwnerFromERCByTokenId(web3: Web3, nftContract: string, id: string) {
  const contract = loadMysteriousERC721(web3, nftContract);

  try {
    const txn = await contract.methods.ownerOf(id).call();

    return txn;
  } catch (e) {
    return null;
  }
}

export async function getCollectionProperty(web3: Web3, nftContract: string) {
  const contract = loadMysteriousERC721(web3, nftContract);
  try {
    const property = await contract.methods.getProperties().call();
    const symbol = property[0];
    const name = property[1];
    const totalSupply = property[2];
    const maxSupply = property[3];
    const cost = property[4];
    const rarityURI = property[5];

    return { symbol, name, totalSupply, maxSupply, cost, rarityURI };
  } catch (e) {
    return null;
  }
}

export async function getCollectionSymbol(web3: Web3, nftContract: string) {
  const contract = loadMysteriousERC721(web3, nftContract);
  try {
    const txn = await contract.methods.symbol().call();
    return txn;
  } catch (e) {
    return null;
  }
}

export async function getCollectionName(web3: Web3, nftContract: string) {
  const contract = loadMysteriousERC721(web3, nftContract);
  try {
    const txn = await contract.methods.name().call();
    return txn;
  } catch (e) {
    return null;
  }
}

export async function buyNFT(
  account: string,
  web3: Web3,
  nftContract: string,
  totalCost: string,
  totalCopy: Number,
  priceType: string,
) {
  const contract = loadMysteriousERC721(web3, nftContract);
  const totalCostInWei = convertToWei(totalCost.toString());
  const latestGasPrice:any = await getGasPrice(web3);
  
  const txn = await contract.methods.mint(account, totalCopy).send({
    from: account,
    value: priceType == '0' ? totalCostInWei : 0,
    ...latestGasPrice
  });

  return txn;
}

export async function revealNFT(account: string, web3: Web3, nftContract: string, tokenId: string) {
  const contract = loadMintContract(web3);
  
  const latestGasPrice:any = await getGasPrice(web3);
  const txn = await contract.methods.reveal(nftContract, tokenId).send({
    from: account,
    value: 0,
    ...latestGasPrice
  });

  return txn;
}

export async function revealAllNFT(
  account: string,
  web3: Web3,
  nftContract: string,
  tokenIds: string[],
) {
  try {
    const contract = loadMintContract(web3);
    const latestGasPrice:any = await getGasPrice(web3);
    const txn = await contract.methods.revealAll(nftContract, tokenIds).send({
      from: account,
      value: 0,
      ...latestGasPrice
  });
    return txn;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

//TODO try and replace with moralis and see performance difference
export async function getTokensExternal(
  web3: Web3,
  address: string,
  owner: string,
): Promise<Nft.NftMeta[]> {
  let nftdetails: Nft.NftMeta[] = [];
  try {
    if (owner) {
      const contract = loadMysteriousERC721(web3, address);
      const txn = await moralisGetNFT(owner, address);
      if (!txn) {
        throw new Error('Unable to get NFT');
      }
      const promises = txn.map(async (c) => {
        const promiseData = await Promise.all([
          contract.methods.tokenURI(c.token_id).call(),
          contract.methods.revealNFT(c.token_id).call(),
        ]);
        const tokenURI = promiseData[0]; //await contract.methods.tokenURI(c).call();
        const isReveal = promiseData[1]; //await contract.methods.revealNFT(c.token_id).call();
        const dataLink: any = convertIPFSToHTTPS(tokenURI);
        const response = await fetch(dataLink);
        if (!response.ok) {
          const message = `An error has occured: ${response.status}`;
          throw new Error(message);
        }
        const data = await response.json();
        const hashedKey = getHashKey(address, c.token_id, web3);
        let newNFT: Nft.NftMeta = {
          tokenID: c.token_id,
          hashedKey: hashedKey,
          title: data?.name,
          description: data?.description,
          ownerAddress: owner,
          NFTImage: convertIPFSToHTTPS(data?.image),
          tokenPayment: '',
          isReveal: isReveal,
        };
        return newNFT;
      });
      nftdetails = await Promise.all(promises);
      return nftdetails;
    }
  } catch (e) {
    console.log(e);
  }
  return nftdetails;
}

export async function getTokenMetaData(
  web3: Web3,
  address: string,
  tokenId: string,
): Promise<Nft.Information> {
  const contract = loadMysteriousERC721(web3, address);

  let newNFT: Nft.Information = {
    tokenID: '',
    hashedKey: '',
    title: '',
    description: '',
    ownerAddress: '',
    NFTImage: '',
    tokenPayment: '',
    contractAddress: '',
    attributes: [],
  };

  try {
    const symbol = await contract.methods.symbol().call();
    // const isReveal = await contract.methods.revealNFT(tokenId).call();
    const ownerAddress = await contract.methods.ownerOf(tokenId).call();
    const tokenURI = await contract.methods.tokenURI(tokenId).call();
    const dataLink: any = convertIPFSToHTTPS(tokenURI);
    const response = await fetch(dataLink);
    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }
    const data = await response.json();
    const hashedKey = getHashKey(address, tokenId, web3);
    newNFT = {
      tokenID: tokenId,
      hashedKey: hashedKey,
      title: data?.name,
      description: data?.description,
      ownerAddress: ownerAddress,
      NFTImage: data?.image,
      tokenPayment: '',
      contractAddress: address,
      category: symbol,
      externalLink: tokenURI,
      attributes: data?.attributes?.map((attribute) => {
        return {
          trait_type: attribute?.trait_type,
          value: attribute?.value,
        };
      }),
    };
    return newNFT;
  } catch (e) {
    console.log(e);
  }
  return newNFT;
}
