import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { toast, ToastContent } from 'react-toastify';
import { Modal } from 'reactstrap';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Loader from './components/Loader';

import ScrollToTopBtn from './components/menu/ScrollToTop';

import { createGlobalStyle } from 'styled-components';
import { getUserByAddress, getUserByUsername } from './services/userService';
import UserContext from './contexts/UserContext';
import { changeToBSCNetwork, resolvePromise } from 'src/utils/helper';
import { AppContext } from './contexts/AppContext';
import { signIn, signUp } from './utils/authInteractor';
import { useTheme } from './contexts/ThemeContext';

import SignUpForm from './components/SignUpForm';
import Analytics from 'src/utils/Analytics';

import ReactGA from 'react-ga';

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID as string;
ReactGA.initialize(TRACKING_ID);

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

type UserContextState = {
  openDownloadMetaMask: boolean;
  openSignUp: boolean;
  openSignIn: boolean;
  user?: User.Information | null;
};

export const ScrollTop = ({ children, path }) => {
  React.useEffect(() => window.scrollTo(0, 0), [path]);
  return children;
};

const App: React.FunctionComponent = () => {
  const { themeType, theme } = useTheme();
  // const { account, web3, connectWallet, disconnectWallet, connectCached} = useContext(AppContext);
  const {
    account,
    web3,
    network,
    getCurrentProvider,
    connectWallet,
    disconnectWallet,
    connectCached,
  } = useContext(AppContext);
  const [darkTheme, setDarkTheme] = useState(themeType == 'dark');
  const [activity, setActivity] = React.useState<UserContextState>({
    openDownloadMetaMask: false,
    openSignUp: false,
    openSignIn: false,
    user: null,
  });

  const events = {
    handleChangeState: (updated) => {
      setActivity({ ...activity, ...updated });
    },
    toggleModal: (key) => {
      events.handleChangeState({ [key]: !activity[key] });
    },
    logout: async () => {
      disconnectWallet();
      setActivity({
        openDownloadMetaMask: false,
        openSignUp: false,
        openSignIn: false,
        user: null,
      });
    },
    openAuthFlow: async (address) => {
      if (address) {
        try {
          const userResponse = await getUserByAddress(address);
          if (userResponse?.status === 200) {
            await signIn(address, web3);
            events.handleChangeState('openSignIn');
            setActivity({ ...activity, user: userResponse.data });
          } else if (userResponse.status === 404) {
            events.handleChangeState('openSignUp');
            setActivity({ ...activity, user: null });
          } else {
            events.handleChangeState('openSignUp');
            setActivity({ ...activity, user: null });
          }
        } catch (err) {
          let error: any = err;
          if (error?.message.includes('404')) {
            events.handleChangeState({ openSignUp: true, user: null });
            return;
          }

          toast.error(err as ToastContent);
        }
      }
    },
    handleStart: async () => {
      await changeToBSCNetwork(network, getCurrentProvider);
      const connectedAccount = await connectWallet();
      if (connectedAccount) {
        await events.openAuthFlow(connectedAccount);
      } else {
        events.handleChangeState({
          openDownloadMetaMask: true,
          openSignUp: false,
          openSignIn: false,
          user: null,
        });
      }
    },
    handleSignIn: async () => {
      const { user } = await signIn(account as string, web3);
      events.handleChangeState('openSignIn');
      setActivity({ ...activity, user });
    },
    handleSignUp: async (val, t) => {
      const [userCheck] = await resolvePromise(getUserByUsername(val.username || ''));

      if (userCheck) {
        toast.error(t('page.profile:error.duplicateUsername'));
        return false;
      }

      const { user } = await signUp(account as string, val, web3);

      events.handleChangeState({ openSignUp: false, user });
    },
    autoConnect: async () => {
      await changeToBSCNetwork(network, getCurrentProvider);
      const connectedAccount = await connectCached();
      if (connectedAccount) {
        await events.openAuthFlow(connectedAccount);
      } else {
        events.handleChangeState({
          openDownloadMetaMask: true,
          openSignUp: false,
          openSignIn: false,
          user: null,
        });
      }
    },
  };

  function handleChainChanged(_chainId) {
    window.location.reload();
  }

  useEffect(() => {
    if (!web3?.currentProvider) {
      events.handleChangeState({ user: null, openDownloadMetaMask: true });
      events.logout();
    } else {
      events.autoConnect();
    }
    if (window.ethereum) {
      window.ethereum.on('chainChanged', handleChainChanged);
    }
  }, [network]);

  useEffect(() => {
    setDarkTheme(themeType == 'dark');
  }, [themeType]);

  const initial = {
    state: activity,
    changeState: events.handleChangeState,
    handleStart: events.handleStart,
  };

  return (
    <div className="wraper" style={{ ...(theme as React.CSSProperties) }}>
      <GlobalStyles />

      <React.Suspense fallback={<Loader />}>
        <UserContext.Provider value={initial}>
          <BrowserRouter>
            <Analytics />
            <ScrollTop path="/">
              <Switch>
                <Route
                  exact
                  path="/"
                  render={(routeProps) => (
                    <Home
                      {...routeProps}
                      logout={events.logout}
                      handleStart={events.handleStart}
                      onLoad={events.autoConnect}
                    />
                  )}
                />
                <Route
                  render={(routeProps) => (
                    <Dashboard
                      {...routeProps}
                      logout={events.logout}
                      handleStart={events.handleStart}
                      openSignUp={activity.openSignUp}
                      toggleModal={events.toggleModal}
                      handleSignUp={events.handleSignUp}
                      onLoad={events.autoConnect}
                    />
                  )}
                />
              </Switch>
            </ScrollTop>
          </BrowserRouter>
          <Modal
            isOpen={activity.openSignUp}
            toggle={() => events.toggleModal('openSignUp')}
            backdrop
            keyboard={false}
          >
            <div className="checkout">
              <div className="maincheckout">
                <button
                  className={`btn-close ${darkTheme ? 'green' : 'purple'}`}
                  onClick={() => events.toggleModal('openSignUp')}
                >
                  x
                </button>
                <SignUpForm onSignUp={(value, t) => events.handleSignUp(value, t)} />
              </div>
            </div>
          </Modal>
        </UserContext.Provider>
        <ScrollToTopBtn />
      </React.Suspense>
    </div>
  );
};

export default App;
