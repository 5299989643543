import systemConfig from '../configs/systemConfig';
import http from './httpService';

const url = `${systemConfig.apiUrl}/api/toppy/collections`;

export const getCollections = async (
  ownerAddress?: string | null,
  withNftCount = false,
  keyWord?: string | null,
  page = 1,
  perPage = 100,
): Promise<Collection.PaginatedMyCollection> => {
  return (
    await http.get(url, {
      params: {
        chainId: systemConfig.chainId.toString(),
        withNftCount,
        ownerAddress,
        keyWord,
        page,
        perPage,
      },
    })
  ).data;
};

export const getCollectionByName = async (name: string): Promise<Collection.MyCollection> => {
  return (
    await http.get(`${url}/get-collection-by-name/${systemConfig.chainId.toString()}/${name}?withNftCount=true`)
  ).data;
};

export const createCollection = (data) => {
  return http.post(url, data);
};
