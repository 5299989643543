import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../contexts/ThemeContext';
import { AppContext } from 'src/contexts/AppContext';
import { redeemNFT } from '../utils/toppyRedeemInteractor';
import { toast } from 'react-toastify';
import ProgressLoader from '../components/ProgressLoader';
import { displayMessage, resolvePromise } from '../utils/helper';
import confetti from 'canvas-confetti';
import { checkIfApprovedRedeem, approveNFTForRedeem } from 'src/utils/erc721Interactor';
import Accordion from '../components/Accordion';
import { updateMeta } from '../services/metaService';
import { ListingType } from 'src/constant';

const RedeemNFT = ({ hashedKey, nftDetails, reset }) => {
  const { themeType } = useTheme();
  const { t } = useTranslation(['page.nft.detail', 'component', 'page.profile']);
  const [isApproving, setIsApproving] = useState(true);
  const { account, web3 } = useContext(AppContext);
  const [darkTheme] = useState(themeType == 'dark');
  const [isLoading, setIsLoading] = useState({
    loading: false,
    type: 'page',
    message: '',
  });

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
      return;
    }
    try {
      setIsLoading({ loading: true, type: 'page', message: '' });

      let isApproved = await checkIfApprovedRedeem(
        web3,
        nftDetails.tokenID,
        nftDetails.contractAddress,
      );
      setIsApproving(isApproved);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading({ loading: false, type: 'page', message: '' });
    }
  };
  const approveNFT = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
      return;
    }
    try {
      setIsLoading({
        loading: true,
        type: 'page',
        message: t('component:enable-nft'),
      });

      const [app, appError] = await resolvePromise(
        approveNFTForRedeem(account, web3, nftDetails.tokenID, nftDetails.contractAddress),
      );
      if (!appError) {
        setIsApproving(true);
        await handleRedeemNFT();
      }else{
        displayMessage(appError, t)
      }
    } catch (e) {
      console.error(e);
      toast.error('Something went wrong!');
    } finally {
      setIsLoading({ loading: false, type: 'page', message: '' });
    }
  };

  const handleRedeemNFT = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
      return;
    }
    try {
      setIsLoading({
        loading: true,
        type: 'page',
        message: t('redeem.redeeming'),
      });

      const [redeemData, redeemError] = await resolvePromise(
        redeemNFT(web3, account, nftDetails.contractAddress, nftDetails.tokenID),
      );
      if (!redeemError) {
        await resolvePromise(
          updateMeta(hashedKey, {
            listingType: ListingType.NOT_LISTED,
            ownerAddress: nftDetails?.originalOwner,
          }),
        );
        confetti();
        reset();
      }
    } catch (e) {
      toast.error(t('common:toast.something-went-wrong'));
    } finally {
      setIsLoading({ loading: false, type: 'page', message: '' });
    }
  };

  return (
    <>
      <ProgressLoader
        loading={isLoading.loading}
        type={isLoading.type}
        message={isLoading.message}
      />
      <div className="wallet-detected-info">
        <div className="mt-4">
          <h3>{t('redeem.title')}</h3>
        </div>
        <div className="mt-4">
          <p>{t('redeem.desc')}</p>
        </div>
        <div className="detailcheckout mt-4">
          <div className="listcheckout">
            <h6>{t('redeem.merchantaddress')}</h6>
            <input
              disabled={true}
              value={nftDetails.originalOwner}
              className="form-control"
              type="text"
              name="originalOwner"
            />
          </div>
        </div>

        <Accordion defaultOpen={false} className="mt-4" title={'NFT Details'}>
          <div className="detailcheckout mt-4">
            <div className="listcheckout">
              <h6>{t('redeem.contractaddress')}</h6>
              <input
                disabled={true}
                value={nftDetails.contractAddress}
                className="form-control"
                type="text"
                name="contractAddress"
              />
            </div>
          </div>
          <div className="detailcheckout mt-3">
            <div className="listcheckout">
              <h6>{t('redeem.tokenid')}</h6>
              <input
                disabled={true}
                value={nftDetails.tokenID}
                className="form-control"
                type="text"
                name="tokenID"
              />
            </div>
          </div>
        </Accordion>

        <div className="mt-2">
          <div className="d-flex justify-content-right">
           {isApproving ? (
              ''
            ) : (
              <button
                onClick={approveNFT}
                className={`btn-main1 ${darkTheme ? 'green' : 'purple'} m-1`}
              >
                {t('Enable')}
              </button>
            )}

            {isApproving ? (
              <button
                onClick={handleRedeemNFT}
                className={`btn-main1 ${darkTheme ? 'green' : 'purple'} m-1`}
              >
                {t('redeem.btn')}
              </button>
            ) : (
              <button className="btn-disable m-1">{t('redeem.btn')}</button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default RedeemNFT;
