import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { countNftByOwner, getNftCategoriesList } from '../services/metaService';
import { getLandingPageData } from '../services/landingPageService';
import { resolvePromise, getTokenIcon } from '../utils/helper';
import { getMediumLatestFeeds } from '../utils/external-request';
import { CommonRoutes, LandingPageType } from '../constant';
import { Link, NavLink } from 'react-router-dom';

import { getTotalListedNFT } from '../utils/newMarketPlaceInteractor';
import Particle from '../components/Particle';
import Header from '../components/menu/Header';
import Footer from '../components/menu/Footer';
import Artists from '../components/Artists';
import Banner from '../components/Banner';
import CreateSell from '../components/CreateSell';
import Explore from '../components/Explore';
import ExploreCollections from '../components/ExploreCollections';
import Resources from '../components/Resources';
import Trending from '../components/Trending';
import useLoader from '../hooks/useLoader';
import { AppContext } from 'src/contexts/AppContext';
import { getCollections } from 'src/services/collectionService';
import { createGlobalStyle } from 'styled-components';
import { ThemeProvider, useTheme } from 'src/contexts/ThemeContext';
import { countTotalUsers } from '../services/userService';

import NewBanner from '../assets/images/newbanner.png';
import MessageBar from '../components/MessageBar';

//TODO this css should be merge to style.css
const GlobalStyles = createGlobalStyle`
header#myHeader.navbar.sticky.white {
  background: var(--navbar-grey);
  border-bottom: 0;
  box-shadow: 0 4px 20px 0 rgba(10,10,10, .8);
}
header#myHeader.navbar .search #quick_search{
  color: #fff;
  background: rgba(255, 255, 255, .1);
}
header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
  color: var(--text);
}
header#myHeader .dropdown-toggle::after{
  color: #fff;
}
header#myHeader .logo .d-block{
  display: none !important;
}
header#myHeader .logo .d-none{
  display: none !important;
}
header#myHeader .logo .d-4{
  display: block !important;
}
.navbar .search #quick_search{
  border-radius: 20px;
}
.navbar .navbar-item .lines {
  border-bottom: 2px solid var(--button);
}
.navbar .mainside a{
  text-align: center;
  color: #fff !important;
  background: var(--button);
  border-radius: 30px;
}
.navbar .mainside a:hover {
  box-shadow: 2px 2px 20px 0 #85c324;
  transition: all .3s ease;
}
.navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
  background: #fff;
}
.item-dropdown{
  color: #fff !important;
  background: var(--background);
  box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
}
.item-dropdown .dropdown a{
  color: var(--text) !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.item-dropdown .dropdown a:hover{
  color: #fff !important;
  background: var(--button);
}
footer.footer-light .subfooter span img.d-1{
  display: none !important;
}
footer.footer-light .subfooter span img.d-4{
  display: inline-block !important;
}
.de_countdown{
  right: 10px;
  color: #fff;
}
.author_list_pp{
  margin-left:0;
}
footer.footer-light .subfooter{
  border-top: 1px solid rgba(255,255,255,.1);
}
#scroll-to-top div {
  background: var(--button);
}
@media only screen and (max-width: 1199px) {
  .navbar {
    background: var(--navbar-grey);
  }
}


/*----*/
  .slick-dots li button:before {
    opacity: 1 !important;
    color: rgba(133, 195, 36, 0.2) !important;
  }
  .slick-dots li.slick-active button:before {
    opacity: 1 !important;
    color: #85c324 !important;
  }
`;
//#85c324
//#85c324

type Props = {
  logout: () => Promise<void>;
  handleStart: () => void;
  autoConnect: () => void;
};

const Home: React.FunctionComponent<Props> = (props) => {
  const { theme } = useTheme();
  const [currentTheme, setCurrentTheme] = useState(false);
  const { t } = useTranslation(['component']);
  const { web3 } = useContext(AppContext);
  const [loader, showLoader, hideLoader] = useLoader();
  const [nftCategories, setNftCategories] = useState<Nft.NftCategory[]>([]);
  const [landingPageInfo, setLandingPageInfo] = useState<LandingPage.LandingPageInfo[]>([]);
  const [totalNFTListed, setTotalNFTListed] = useState('0');
  const [totalUsers, setTotalUsers] = useState('0');
  const [latestFeeds, setLatestFeeds] = useState<LandingPage.LandingPageLatestFeeds>({});
  const [collections, setCollections] = useState<Collection.MyCollection[]>([]);
  
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (theme['--background'] == '#212428') {
      setCurrentTheme(true);
    } else {
      setCurrentTheme(false);
    }
  });

  const init = async () => {
    showLoader();
    const [nftCategoryResponse, getNftCategoryError] = await resolvePromise(getNftCategoriesList());
    if (!getNftCategoryError) {
      setNftCategories(nftCategoryResponse?.data?.data);
    }

    const [response] = await resolvePromise(getCollections(null, true, null, 1, 3));
    setCollections(response?.data || []);
    
    const [landingPageInfo, getLandingPageError] = await resolvePromise(getLandingPageData());
    if (!getLandingPageError) {
      const featuredArtists = (landingPageInfo?.data.data || []).filter(
        (data) => data.type === LandingPageType.FEATURED_ARTISTS,
      );
      for (const artist of featuredArtists) {
        const [nftCountResp] = await resolvePromise(countNftByOwner(artist.address));

        artist.nftTotalCount = nftCountResp?.data || 0;

      }
      const nonFeaturedArtists = (landingPageInfo?.data.data || []).filter(
        (data) => data.type !== LandingPageType.FEATURED_ARTISTS,
      );

      setLandingPageInfo([...nonFeaturedArtists, ...featuredArtists]);

      const totalListed = await getTotalListedNFT(web3);
      setTotalNFTListed(totalListed);

      const [totalUsersCount] = await resolvePromise(countTotalUsers());

      setTotalUsers(totalUsersCount?.data?.totalCount);
    }

    const [latestFeedsResponse, getLatestFeedError] = await resolvePromise(getMediumLatestFeeds());
    if (!getLatestFeedError) {
      setLatestFeeds(latestFeedsResponse as LandingPage.LandingPageLatestFeeds);
    }

    hideLoader();

  };

  return (
    <>
      <Header logout={() => props.logout()} onStart={props.handleStart} />
      <div className="greyscheme">
      <GlobalStyles />
        <section
          style={
            currentTheme
              ? {
                  backgroundImage: `url(${'./assets/images/background/8.jpg'})`,
                }
              : {
                  backgroundImage: `url(${'./assets/images/background/9.png'})`,
                }
          }
        >
          <MessageBar show={true}/>
          <Banner
            listing={(landingPageInfo || []).filter((data) => data.type === LandingPageType.TOP)}
            totalNFTListed={totalNFTListed}
            totalUsers={totalUsers}
          />
        </section>

         {collections.length >= 3 && (
            <>
            <br/>
            <section className="container no-top no-bottom">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12 mb-3">
                    <h2>{t('Explore Collections')}</h2>
                  </div>
                  <ExploreCollections collections={collections} />
                </div>
              </div>
          </section>
          </>
         )}
        
        <section className="container no-top no-bottom">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 mb-3">
                <h2>{t('explore.title')}</h2>
              </div>
              <Explore nftCategories={nftCategories} />
            </div>
          </div>
        </section>

        <section className="container no-top no-bottom">
          <div className="row">
            <div className="spacer-double"></div>
            <div className="col-lg-12 mb-3">
              <h2>{t('create-sell.header')}</h2>
            </div>
            <CreateSell />
          </div>
        </section>

        <section className="container no-top no-bottom">
          <div className="row">
            <div className="spacer-double"></div>
            <div className="col-lg-12">
              <h2>{t('artists.title')}</h2>
            </div>
            <div className="col-lg-12">
              <Artists
                featuredArtists={(landingPageInfo || []).filter(
                  (data) => data.type === LandingPageType.FEATURED_ARTISTS,
                )}
              />
            </div>
          </div>
        </section>

        <section className="container no-top no-bottom">
          <div className="row">
            <div className="spacer-double"></div>
            <div className="col-lg-12 mb-2">
              <h2>{t('resources.media')}</h2>
            </div>
            <div className="col-lg-12">
              <Resources data={latestFeeds} />
            </div>
          </div>
        </section>

        {/* <Stay /> */}
        {/* <LogoSlider /> */}

        <Footer />
      </div>
    </>
  );
};

export default Home;
