import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../contexts/ThemeContext';
import { AppContext } from 'src/contexts/AppContext';
import { toast } from 'react-toastify';
import ProgressLoader from '../components/ProgressLoader';
import { resolvePromise } from '../utils/helper';
import confetti from 'canvas-confetti';
import Accordion from '../components/Accordion';
import { updateMeta } from '../services/metaService';
import { ListingType } from 'src/constant';

const MoveToCollection = ({ hashedKey, nftDetails, reset, myCollection }) => {
  const { themeType } = useTheme();
  const { t } = useTranslation(['page.nft.detail', 'component', 'page.profile']);
  const { account, web3 } = useContext(AppContext);
  const [selectedCollectionId, setSelectedCollectionId] = useState("");
  const [darkTheme] = useState(themeType == 'dark');
  
  const handleUpdateCollection = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
      return;
    }
    if (selectedCollectionId === "") {
      toast.error(t('moveCollection.select-collection'));
      return;
    }
    try {
      await resolvePromise(
        updateMeta(hashedKey, {
          listingType: ListingType.NOT_LISTED,
          userCollectionId: selectedCollectionId,
        }),
      );
      confetti();
      toast.success(t('moveCollection.collection-updated'));
      reset();
  } catch (e) {
      toast.error(t('common:toast.something-went-wrong'));
    } finally {
    }
  };

  const setCollectionId = (event) => {
    setSelectedCollectionId(event.target.value);
  }

  return (
    <>
      <div className="wallet-detected-info">
        <div className="mt-4">
          <h3>{t('moveCollection.title')}</h3>
        </div>
        <div className="mt-4">
          <p>{t('moveCollection.desc')}</p>
        </div>
        <div className="detailcheckout mt-4">
          <div className="listcheckout">
            <h6>{t('moveCollection.collection-id')}</h6>
            <select
              className="form-control"
              value={selectedCollectionId}
              onChange={setCollectionId}
              name="userCollectionId"
            >
              <option disabled value="">
                Select Collection
              </option>
              {myCollection.map((c) => {
                if(c.category.toLowerCase() === nftDetails.category.toLowerCase()
                   && c.ownerAddress.toLowerCase() === nftDetails.originalOwner.toLowerCase()
                )
                {
                  return (
                    <option key={c._id} value={c._id}>
                      {c.name}
                    </option>
                  );
                }
              })}
            </select>
          </div>
        </div>

        <div className="mt-2">
          <div className="d-flex justify-content-right">
            <button
              onClick={handleUpdateCollection}
              className={`btn-main1 ${darkTheme ? 'green' : 'purple'} m-1`}
            >
              {t('moveCollection.btn')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default MoveToCollection;
