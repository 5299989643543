import React from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

type Props = {
  data: LandingPage.LandingPageLatestFeeds;
};

const SampleNextArrow = (props) => {
  const { className, onClick } = props;

  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { className, onClick } = props;

  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );
};

const ResourceSlider = (props: Props) => {
  const { data } = props;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    initialSlide: 0,
    centerMode: false,
    nextArrow: <SampleNextArrow className="icon next" />,
    prevArrow: <SamplePrevArrow className="icon prev" />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Slider className="border-slider" {...settings}>
      {(data?.items || []).map((item, i) => {
        return (
          // <div className="col-lg-4 col-md-6 mb30" key={i}>
          <div className="bloglist item">
            <a href={item?.link || '#'} target="_blank">
              <div className="post-content">
                <div className="post-image">
                  <img alt="" src={item.thumbnail} className="lazy" />
                </div>
                <div className="post-text">
                  <span className="p-tagline">Tips &amp; Tricks</span>
                  <span className="p-date">{moment(item.pubDate).format('L, LT')}</span>
                  <h4>
                    <span>{item?.title}</span>
                  </h4>
                </div>
              </div>
            </a>
          </div>
          // </div>
        );
      })}
    </Slider>
  );
};

const Resources = (props: Props) => {
  const { t } = useTranslation(['component']);
  const { data } = props;
  if (data?.status !== 'ok') {
    return <></>;
  }
  return (
    <div className="row">
      <ResourceSlider {...props} />
    </div>
  );
};

export default Resources;
