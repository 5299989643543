import { useState, useEffect } from 'react';
import { Collapse } from '@material-ui/core';
import Alert from 'react-bootstrap/Alert'
import { NavLink } from 'react-router-dom';
import NewBanner from '../assets/images/newbanner.png';
import ServiceBanner from '../assets/images/redeemservice.png';
import { Breakpoint, BreakpointProvider, setDefaultBreakpoints } from 'react-socks';
import ReactInterval from 'react-interval';

const banners = [
  {
    name: "service",
    imgPath: "/assets/images/redeemservice.png",
    link: "/marketplace/SERVICES"
  },
  {
    name: "mint",
    imgPath: "/assets/images/newbanner.png",
    link: "/create-nft"
  },
  {
    name: "mysterybox",
    imgPath: "/assets/images/mysterybox.png",
    link: "/mystery-box"
  },
];

const MessageBar = ({show}) => {
  const [open, setOpen] = useState(true);
  let [selected, setSelected] = useState(0);
  useEffect(() => {
    init();
  }, []);
  
  const init = async () => {    
    let newCounter = selected > 2 ? 0 : selected++
    setSelected(newCounter)
  }

  

  const hideMessageTxn = () => {
    setOpen(false);
  };

  
  
  if (show) {
    return (
      <Collapse in={open}>
        <ReactInterval
          timeout={3000}
          enabled={true}
          callback={() => init()}
        />
        <Alert 
          max-width={"75%"}
          variant="success" onClose={() => hideMessageTxn()} dismissible>
          <NavLink to={banners[selected].link}>
          <div className="d-flex justify-content-center">
          <BreakpointProvider>
            <Breakpoint l down>
              <img
                src={banners[selected]?.imgPath}
                width={"100%"}
                height="100%"
              />
            </Breakpoint>
            <Breakpoint xl>
              <img
                src={banners[selected]?.imgPath}
                width={"100%"}
                height="100%"
              />
            </Breakpoint>
            
          </BreakpointProvider>
          
          </div>
        </NavLink>
        </Alert>
      </Collapse>
    );
  }else{
    return <></>
  }
};

export default MessageBar;
