import systemConfig from '../configs/systemConfig';
import http from './httpService';

const paymentTypesEndpoint = `${systemConfig.apiUrl}/api/toppy/payment-types`;

export const getPaymentTypesList = (page = 1, perPage = 100) => {
  return http.get(paymentTypesEndpoint, {
    params: {
      chainId: systemConfig.chainId.toString(),
      page,
      perPage,
      active: true,
    },
  });
};
