import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useHistory } from 'react-router-dom';
import { convertIPFSToHTTPS } from 'src/utils/helper';
import { getRarityJson } from '../services/metaService';

const AttributeSlider = ({ configData }) => {
  const { t } = useTranslation(['component']);
  const history = useHistory();
  const [rarityJson, setRarityJson] = useState<any>();

  useEffect(() => {
    init();
  }, [configData]);

  const init = async () => {
    const data = await getRarityJson(convertIPFSToHTTPS(configData.rarityURL));
    setRarityJson(data);
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: 100,
    arrows: true,
    // dots: true,
    // centerMode: true,
    // adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="nft__item_info m-3">
        <h2 className="nft__item_wrap mt-4">
          {t('component:mystery-box-attributes.attributes-title')}
        </h2>

        {rarityJson?.map((traits) => (
          <div>
            <h4 key={traits.traittype}>{traits.traittype}</h4>
            <Slider {...settings}>
              {traits.attributes.map((attributes) => (
                <>
                  <div className="nft__item m-0 p-3">
                    <img
                      className="p-0 h-40 w-50"
                      src={convertIPFSToHTTPS(
                        attributes?.layerURI || '/assets/images/preloader.png',
                      )}
                    />
                    <div className="spacer-10"></div>
                    <h4>{attributes.trait}</h4>
                    <div className="row text-left">
                      <div className="col">
                        {/* {t("component:mystery-box-attributes.appearance")} */}
                        <h4>{attributes.chance}%</h4>
                      </div>
                    </div>
                  </div>
                  <div className="spacer-20"></div>
                </>
              ))}
            </Slider>
          </div>
        ))}
      </div>
    </>
  );
};

export default AttributeSlider;
