import ReactDOM from 'react-dom';
import dotenv from 'dotenv';
import i18next, { InitOptions } from 'i18next';
import HttpApi from 'i18next-http-backend';
import I18nextLanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';

import './assets/animated.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import '../node_modules/elegant-icons/style.css';
import '../node_modules/et-line/style.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import './assets/style.scss';
import './assets/style_grey.scss';
import App from './App';

import reportWebVitals from './reportWebVitals';
import { Language } from './constant';
import AppContext from './contexts/AppContext';
import { ThemeProvider } from './contexts/ThemeContext';

dotenv.config();

const options: InitOptions = {
  backend: {
    addPath: '/locales/{{ lng }}/{{ ns }}.missing.json',
    loadPath: '/locales/{{ lng }}/{{ ns }}.json',
  },
  defaultNS: 'common',
  detection: {
    caches: ['cookie'],
    lookupCookie: 'language',
    lookupLocalStorage: 'language',
    order: ['cookie'],
    cookieDomain: 'localhost', // Need to change according to environment
  },
  fallbackLng: Language.English,
  interpolation: {
    escapeValue: false,
  },
  load: 'languageOnly',
};

i18next.use(HttpApi).use(initReactI18next).use(I18nextLanguageDetector).init(options);

i18next.on('languageChanged', (language) => {
  moment.locale(language);
});

ReactDOM.render(
  <AppContext>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </AppContext>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
