import { useState } from 'react';
import { Watch, CradleLoader } from 'react-loader-spinner';
import { Dialog, DialogContent } from '@material-ui/core';
import { useTheme } from 'src/contexts/ThemeContext';
import { Wave, Random } from 'react-animated-text';

const ProgressLoader = ({ loading, type, message }) => {
  const { themeType } = useTheme();
  const [darkTheme] = useState(themeType == 'dark');
  const exampleStyle = {
    display: 'inline-block',
    padding: '1em 1em 1em 1em',
    width: '100%',
    fontSize: '1.00rem',
  };
  const dialogStyle = {
    display: 'inline-block',
    padding: '1em 1em 1em 1em',
    marginBottom: '1em',
    border: '1px solid #ccc',
    width: '100%',
  };

  if (loading) {
    return (
      <Dialog open={loading} keepMounted>
        <DialogContent>
          <div style={dialogStyle}>
            {type == 'page' && (
              <div className="d-flex justify-content-center">
                <Watch color={darkTheme ? '#85c324' : '#8364E2'} arialLabel="loading-indicator" />
              </div>
            )}
            {message !== '' && (
              <div className="d-flex justify-content-center #85c324">
                <div style={exampleStyle}>
                  <Wave text={message} effect="stretch" effectChange={2.0} />
                </div>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
  return null;
};

export default ProgressLoader;
