import React, { memo } from 'react';
import xWinLogo from '../assets/images/xWinLogo.png';
import { Link, NavLink } from 'react-router-dom';
import { convertIPFSToHTTPS } from 'src/utils/helper';

//react functional component
const Artist = ({ artist }) => {
  return (
    <>
      <div className="author_list_pp">
        <NavLink to={`/user/${artist?.user?.username}`}>
          <img
            className="lazy"
            src={convertIPFSToHTTPS(artist?.user?.profileImage) || xWinLogo}
            alt=""
          />
          <i className="fa fa-check"></i>
        </NavLink>
      </div>
      <div className="author_list_info">
        <NavLink to={`/user/${artist?.user?.username}`}>
          <span>{artist.user?.username}</span>
          <span className="bot">{artist?.nftTotalCount || 0} NFTs</span>
        </NavLink>
      </div>
    </>
  );
};

export default memo(Artist);
