import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Countdown from '../components/Countdown';
import { toInteger } from 'lodash';
import { useTheme } from '../contexts/ThemeContext';
import useLoader from '../hooks/useLoader';
import { isEnoughBalance } from '../utils/blockchainInteractor';
import { AppContext } from 'src/contexts/AppContext';
import { displayMessage, getTokenSymbol, resolvePromise } from '../utils/helper';
import tokenConfig from 'src/configs/tokenConfig';
import { makeOffer, makeOfferERC20 } from '../utils/newMarketPlaceInteractor';
import ProgressLoader from '../components/ProgressLoader';

const AuctionEnglishOffer = ({ nftDetails, highestOffer, reset }) => {
  const { themeType } = useTheme();
  const { account, web3 } = useContext(AppContext);
  const { t } = useTranslation(['common', 'component']);
  const [darkTheme] = useState(themeType == 'dark');
  // const [auctionDetail, setAuctionDetail] = useState({
  //   startedAt: 0,
  //   duration: 0,
  // });
  const [auctionForm, setAuctionForm] = useState({
    reservePrice: 0,
    offerPrice: 0,
    symbol: tokenConfig.default.token,
    address: tokenConfig.default.address,
    priceType: 0,
  });
  const [isLoading, setIsLoading] = useState({
    loading: false,
    type: 'page',
    message: '',
  });
  const [offerPrice, setOfferPrice] = useState('');

  useEffect(() => {
    init();
  }, []);

  const handleChange = (e) => {
    setOfferPrice(e.target.value);
    const updated = { [e.target.name]: e.target.value };
    setAuctionForm({ ...auctionForm, ...updated });
  };

  const init = async () => {
    try {
      setIsLoading({ loading: true, type: 'page', message: '' });
      // setAuctionDetail({
      //   startedAt: toInteger(nftDetails?.startAt),
      //   duration: nftDetails?.duration,
      // });
      const offerPrice =
        highestOffer.highestPrice > 0
          ? Number(highestOffer.highestPrice).toFixed(4)
          : Number(nftDetails.reservePrice).toFixed(4);
      const newprice = Number(offerPrice) * 1.1;
      setAuctionForm({
        ...auctionForm,
        offerPrice: newprice,
        reservePrice: nftDetails.reservePrice,
      });
      setOfferPrice(Number(newprice.toString()).toFixed(4));
    } catch (e) {
      console.error(e);
      toast.error(t('component:auction-english-offer.toast.approve-token'));
    } finally {
      setIsLoading({ loading: false, type: 'page', message: '' });
    }
  };

  const handleMakeOffer = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
      return;
    }

    const validBal = await isEnoughBalance(
      account,
      web3,
      nftDetails?.tokenPayment as string,
      auctionForm.offerPrice.toString(),
    );
    if (!validBal) {
      toast.error(t('component:not-enough-balance'));
      return;
    }

    if (
      Number(auctionForm.offerPrice) <= Number(highestOffer.highestPrice) ||
      Number(auctionForm.offerPrice) <= Number(auctionForm.reservePrice)
    ) {
      toast.error(t('component:offer-must-greater-currentbid'));
      return;
    }

    try {
      setIsLoading({
        loading: true,
        type: 'page',
        message: t('component:making-offer'),
      });
      // let res;
      let txnData
      let txnError
      if (nftDetails.priceType == 0) {
        
        [txnData, txnError] = await resolvePromise(
          makeOffer(
            account,
            web3,
            nftDetails.hashedKey,
            auctionForm.offerPrice.toString(),
          ),
        );
      } else {
        [txnData, txnError] = await resolvePromise(
          makeOfferERC20(
            account,
            web3,
            nftDetails,
            auctionForm.offerPrice.toString(),
          ),
        );        
      }
      if (!txnError) {
        reset();
      }else{
        displayMessage(txnError, t)
      }
    } catch (e) {
      toast.error(t('common:toast.something-went-wrong'));
    } finally {
      setIsLoading({ loading: false, type: 'page', message: '' });
    }
  };

  return (
    <>
      <ProgressLoader
        loading={isLoading.loading}
        type={isLoading.type}
        message={isLoading.message}
      />
      <div className="wallet-detected-info">
        <div className="mt-4">
          <h3>{t('component:auction-english-offer.title')}</h3>
        </div>

        <div className="detailcheckout mt-4">
          <div className="listcheckout">
            <h6>
              {t('component:auction-english-offer.label.reserve-price', {
                token: getTokenSymbol(nftDetails?.tokenPayment),
              })}
            </h6>
            <input
              disabled={true}
              value={Number(auctionForm.reservePrice).toFixed(4)}
              className="form-control"
              type="text"
              name="reservePrice"
            />
          </div>
        </div>
        <div className="detailcheckout mt-3">
          <div className="listcheckout">
            <h6>
              {' '}
              {t('component:auction-english-offer.label.highest-offer', {
                token: getTokenSymbol(nftDetails?.tokenPayment),
              })}
            </h6>
            <input
              disabled={true}
              value={Number(highestOffer.highestPrice).toFixed(4)}
              className="form-control"
              type="text"
              name="highestPrice"
            />
          </div>
        </div>
        <div className="detailcheckout mt-3">
          <div className="listcheckout">
            <h6>
              {' '}
              {t('component:auction-english-offer.label.your-offer', {
                token: getTokenSymbol(nftDetails?.tokenPayment),
              })}
            </h6>
            <input
              value={offerPrice}
              onChange={handleChange}
              className="form-control"
              type="text"
              name="offerPrice"
            />
          </div>
        </div>

        <div>
          <div className="mt-2">
            <Countdown
              deadline={
                new Date(
                  (toInteger(nftDetails?.startedAt) + toInteger(nftDetails?.duration)) * 1000,
                )
              }
            />
          </div>
        </div>
        <div className="mt-2">
          <button
            disabled={nftDetails?.isExpired}
            onClick={handleMakeOffer}
            className={`btn-main1 ${darkTheme ? 'green' : 'purple'} m-1`}
          >
            {t('component:auction-english-offer.button.offer-now')}
          </button>
        </div>
      </div>
    </>
  );
};

export default AuctionEnglishOffer;
