// Local
// const systemConfig = {
//     apiUrl: 'http://localhost:3001', // Localhost
//     chainId: 97, // BINANCE TESTNET
//     // chainId: 80001, // POLOGON TESTNET
//   polygonUrl: 'https://toppy-polygon.web.app',
//   binanceUrl: 'https://toppy.market',
//  secondPerBlock: 3,
// };

// Staging
// const systemConfig = {
//   apiUrl: 'https://api.xwin.com.my', // Staging server
//   chainId: 97, // use testnet network
//   // chainId: 80001, // POLOGON TESTNET
//   polygonUrl: 'https://toppy-polygon.web.app',
//   binanceUrl: 'https://toppynft.web.app',
//   // secondPerBlock: 3,
//   secondPerBlock: 2.3,
// };

// Production
const systemConfig = {
  apiUrl: 'https://api.toppy.market', // Production Server
  // chainId: 56, // BINANCE MAINNET,
  chainId: 137, // POLOGON MAINNET
  polygonUrl: 'https://polygon.toppy.market',
  binanceUrl: 'https://toppy.market',
  secondPerBlock: 3,
};

export default systemConfig;
