import { CommonRoutes } from '../constant';
import xWinLogo from '../assets/images/xWinLogo.png';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import Reveal from 'react-awesome-reveal';
import { keyframes } from '@emotion/react';
import { convertIPFSToHTTPS } from 'src/utils/helper';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import CountDownClock from './CountDownClock';
import { Animated } from 'react-animated-css';

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;
const inline = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
  .d-inline{
    display: inline-block;
   }
`;

type Props = {
  listing: LandingPage.LandingPageInfo[];
  totalNFTListed: string;
  totalUsers: string;
};

const Banner = (props: Props) => {
  const { t } = useTranslation(['component']);
  const { listing, totalNFTListed, totalUsers } = props;

  const renderTopNft = (data: LandingPage.LandingPageInfo) => {
    if (!data) {
      return <div />;
    }
    return (
      <div className="col-md-auto">
        <div className="row">
          <div className="item">
            <div className="image">
              <Link to={`/nft/${data?.nftMeta?.hashedKey}`}>
                <img
                  src={convertIPFSToHTTPS(data?.nftMeta?.NFTImage || '')}
                  alt=""
                  width={'100%'}
                  height={550}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="item_info mt-3">
          <div className="item_author">
            <div className="author_list_pp">
              <span>
                <img src={convertIPFSToHTTPS(data?.user?.profileImage || xWinLogo)} />
              </span>
            </div>
            <div className="author_list_info">
              <NavLink to={`/user/${data?.user?.username}`}>
                <span>{''}</span>
                <span className="bot">
                  {t('banner.label.user', { username: data?.user?.username })}
                </span>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container1">
      <div className="row align-items-center">
        <div className="col-md-6">
          <h6>
            <span className="text-uppercase color">{t('banner.title.nft-marketplace')}</span>
          </h6>
          <h1 className="wow fadeInUp animated" data-wow-delay=".75s">
            Discover rare
            <Animated
              animationInDelay={1500}
              animationOutDelay={1500}
              animationIn="lightSpeedIn"
              animationOut="lightSpeedOut"
              isVisible={true}
            >
              <span className="text-warning">artworks</span>
            </Animated>
            by world class artists
          </h1>
          <div className="spacer-10"></div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-4 mb30">
              <Reveal
                className="onStep d-inline"
                keyframes={inline}
                delay={800}
                duration={900}
                triggerOnce
              >
                <NavLink to={CommonRoutes.MARKETPLACE} className="nav-link">
                  <span className="btn-main inline lead">{t('banner.button.explore')}</span>
                  <div className="mb-sm-30"></div>
                </NavLink>
              </Reveal>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-4 mb30">
              <Reveal
                className="onStep d-inline"
                keyframes={inline}
                delay={800}
                duration={900}
                triggerOnce
              >
                <NavLink to={CommonRoutes.CREATE_NFT} className="nav-link">
                  <span className="btn-main inline lead">{t('banner.button.create')}</span>
                </NavLink>
                <div className="mb-sm-30"></div>
              </Reveal>
            </div>
          </div>
          <Reveal
            className="onStep d-inline"
            keyframes={inline}
            delay={900}
            duration={1200}
            triggerOnce
          >
            <div className="row">
              <div className="spacer-single"></div>
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-4 mb30">
                  <div className="de_count text-left">
                    <h3>
                      <span className="text-success">{totalNFTListed}</span>
                    </h3>
                    <h5 className="id-color">Active Listing</h5>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-4 mb30">
                  <div className="de_count text-left">
                    <h3>
                      <span className="text-success">{totalUsers}</span>
                    </h3>
                    <h5 className="id-color">Total Users</h5>
                  </div>
                </div>
                {/* <div className="col-lg-4 col-md-6 col-sm-4 mb30">
                  <div className="de_count text-left">
                    <h3>
                      <span className="bot">Active Listing : {totalNFTListed} </span>
                    </h3>
                  </div>
                </div> */}
                {/* <div className="col-lg-4 col-md-6 col-sm-4 mb30">
                  <div className="de_count text-left">
                    <h3 className="bot">Total Users : {totalUsers}</h3>
                  </div>
                </div> */}
              </div>
            </div>
          </Reveal>
        </div>
        <div className="col-md-6 xs-hide">
          <Reveal
            className="onStep d-inline"
            keyframes={inline}
            delay={300}
            duration={1200}
            triggerOnce
          >
            {renderTopNft(listing?.[0])}
            {renderTopNft(listing?.[1])}
          </Reveal>
        </div>
      </div>
    </div>
  );
};

export default Banner;
