import { useTranslation } from 'react-i18next';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ReactPlayer from 'react-player/lazy';
import { useContext, useEffect, useState } from 'react';
import NftCardTemp from './NftCardTemp';
import { ListingType, NftType } from '../constant';
import { convertIPFSToHTTPS, resolvePromise, getNftType, truncateTitle } from '../utils/helper';

import { getTokensERC721ByAddress } from '../utils/importNFTInteractor';
import { getPaginatedNFTs } from '../utils/commonInteractor';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

type Props = {
  ownerAddress: string;
};

const MoreNft = (props: Props) => {
  const { t } = useTranslation(['component']);
  const { ownerAddress } = props;
  const [metadata, setMetadata] = useState<Nft.NftMeta[]>([]);
  const history = useHistory();
  const [contentType, setContentType] = React.useState();

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: 300,
    arrows: true,
    dots: true,
    centerMode: true,
    // adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  useEffect(() => {
    init();
  }, [ownerAddress]);

  const init = async () => {
    // setMetadata([]);
    if (ownerAddress == '') return;

    const listFilter = [ListingType.AUCTION, ListingType.ENGLISH_AUCTION, ListingType.MARKETPLACE];
    const [response] = await resolvePromise(
      getPaginatedNFTs({
        listingType: listFilter,
        ownerAddress,
        page: 1,
      }),
    );
    if (response) {
      setMetadata(response?.data);
    }
  };

  return (
    <>
      <div className="nft__item_info mb-1">
        <h4>{t('More from this user...')}</h4>
      </div>
      <Slider {...settings}>
        {metadata?.map((card) => (
          // <h4>{card?.title}</h4>
          <div
          // className={classNames({
          //   "nft__item m-0 p-3": true,
          // })}
          >
            <Link to={card?.hashedKey}>
              <div onClick={() => window.scrollTo(0, 0)} className="nft__item_wrap mt-1">
                {getNftType(card?.contentType as string) === NftType.Audio ||
                getNftType(card?.contentType as string) === NftType.Video ? (
                  <ReactPlayer
                    controls
                    loop
                    playing={false}
                    height={'50%'}
                    url={convertIPFSToHTTPS(card?.NFTImage || '')}
                  />
                ) : (
                  <img
                    height={'200'}
                    className="img-rounded mb-sm-30 w-100"
                    src={convertIPFSToHTTPS(card?.NFTImage || '/assets/images/preloader.png')}
                  />
                )}
              </div>
              <div className="nft__item_info mb-1">
                <p>{truncateTitle(card?.title)}</p>
              </div>
            </Link>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default MoreNft;
