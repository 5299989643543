import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Accordion from '../components/Accordion';
import ImportNFTFAQ from '../components/FAQ/ImportNFTFAQ';
import NftCardTemp from '../components/NftCardTemp';
import ProgressLoader from '../components/ProgressLoader';
import SpinnerLoader from '../components/SpinnerLoader';
import collectionsConfig from '../configs/collectionsConfig';
import systemConfig from '../configs/systemConfig';
import { AppContext } from '../contexts/AppContext';
import UserContext from '../contexts/UserContext';
import { saveImportCustomContract } from '../services/metaService';
import { resolvePromise } from '../utils/helper';
import { getTokensExternal } from '../utils/importNFTInteractor';
import {
  getCollectionName,
  getCollectionSymbol,
  getMaxSupply,
  getTotalSupply,
} from '../utils/mysteriosBoxNFTInteractor';

const ImportNFT = (props) => {
  const { t } = useTranslation(['page.import-nft', 'common', 'component']);
  const history = useHistory();
  const { account, web3 } = useContext(AppContext);
  const userContext = useContext(UserContext);
  const { handleStart, state } = userContext;
  const { user } = state;
  const [metadata, setMetadata] = useState<Nft.NftMeta[]>([]);
  const [nftAddress, setNftAddress] = useState('');
  const [isLoading, setIsLoading] = useState({
    loading: false,
    type: 'page',
    message: '',
  });
  const [isCardLoading, setIsCardLoading] = useState(false);

  useEffect(() => {
    // init();
  }, [account]);

  const [collectionData, setCollectionData] = useState({
    name: '',
    description: '',
    contractaddress: '',
    imageURL: '',
    totalSupply: 0,
    maxSupply: 0,
    owner: '',
    tokenPayment: '',
    priceType: '0',
    costPerNFT: '0',
    totalVolume: '0',
  });

  const handleLoadNFT = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
      return;
    }
    if (!nftAddress) {
      //TODO, UPDATE correct message
      toast.error(t('component:input-contract-address'));
      return;
    }
    try {
      setIsCardLoading(true);
      await getCollectionData();
      //this is client method to load the NFT. It looks faster than calling getTokensERC721ByAddress
      const metaData = await getTokensExternal(web3, nftAddress, account);
      if (metaData) {
        setMetadata(metaData || []);
      }
      // const metaData = await getTokensERC721ByAddress(web3, nftAddress, account)
      // setMetadata(metaData || []);
      return;
    } catch (error) {
      toast.error(t('common:toast.something-went-wrong'));
    } finally {
      setIsCardLoading(false);
    }
  };

  const getCollectionData = async () => {
    const promiseData = await Promise.all([
      getCollectionName(web3, nftAddress),
      getCollectionSymbol(web3, nftAddress),
      getTotalSupply(web3, nftAddress),
      getMaxSupply(web3, nftAddress),
    ]);
    const name = promiseData[0]; //await getCollectionName(web3, nftAddress)
    const symbol = promiseData[1]; //await getCollectionSymbol(web3, nftAddress)
    const totalSupply = promiseData[2]; //await getTotalSupply(web3, nftAddress)
    const maxSupply = promiseData[3]; //await getMaxSupply(web3, nftAddress)
    setCollectionData({
      name: symbol?.toString(),
      description: name?.toString(),
      contractaddress: nftAddress,
      imageURL: '',
      totalSupply: totalSupply,
      maxSupply: maxSupply,
      owner: '',
      tokenPayment: '',
      priceType: '',
      costPerNFT: '',
      totalVolume: '',
    });
  };

  const handleSaveNFT = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
    }
    if (!nftAddress) {
      //TODO, UPDATE correct message
      toast.error(t('component:input-contract-address'));
      return;
    }
    try {
      setIsLoading({
        loading: true,
        type: 'page',
        message: t('component:import-nft'),
      });

      const collection = collectionsConfig.collection.find(
        (collection) =>
          systemConfig.chainId === collection.chainId && collection.contractaddress === nftAddress,
      );
      const body: Nft.ImportCustomContract = {
        chainId: systemConfig.chainId.toString(),
        contractAddress: nftAddress,
        category: collection?.symbol || '',
      };

      const [importCustomContract, importCustomContractError] = await resolvePromise(
        saveImportCustomContract(body),
      );
      if (importCustomContract) {
        toast.success(t('component:import-inprogress'));
      }

      if (importCustomContractError) {
        console.log('import error', importCustomContractError);
        toast.error(t('common:toast.something-went-wrong'));
      }

      return;
    } catch (error) {
      toast.error(t('common:toast.something-went-wrong'));
    } finally {
      setIsLoading({ loading: false, type: 'page', message: '' });
    }
  };

  const handleChangeText = (e) => {
    setNftAddress(e.target.value);
  };

  return (
    <>
      <ProgressLoader
        loading={isLoading.loading}
        type={isLoading.type}
        message={isLoading.message}
      />
      <div className="spacer-20"></div>
      <section className="container">
        <div className="d-flex mt-4  w-100 justify-content-center align-content-center">
          <div className="mr-4 w-10">
            <h3>{t('ERC721 address')}</h3>
          </div>
          <div className="mr-4 w-75">
            <input
              name="nftAddress"
              onChange={handleChangeText}
              value={nftAddress}
              className="form-control"
            />
          </div>
        </div>
        <div className="d-flex mt-4  w-100 justify-content-center align-content-center">
          <div className="mr-4 w-20">
            <button className="btn-main" onClick={handleLoadNFT}>
              {t('component:import-form.button.load')}
            </button>
          </div>
          <div className="">
            <button className="btn-main" onClick={handleSaveNFT}>
              {t('component:import-form.button.import')}
            </button>
          </div>
        </div>

        <Accordion
          alwaysOpen
          className="mt-4 "
          icon="fa fa-file-image-o"
          title={isCardLoading ? 'Loading...' : metadata.length == 0 ? t('No NFT') : t('My NFTs')}
        >
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 offset-xl-0 offset-lg-0 offset-md-0">
              <div>
                <div className="easy-box">
                  <div className="row">
                    <SpinnerLoader loading={isCardLoading} />
                  </div>
                  <div className="row">
                    {account &&
                      metadata?.map((card) => <NftCardTemp nft={card} key={card.hashedKey} />)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Accordion>
        <div className="spacer-40"></div>

        <ImportNFTFAQ />
      </section>
    </>
  );
};

export default ImportNFT;
