import React from 'react';

type State = {
  openDownloadMetaMask: boolean;
  openSignUp: boolean;
  openSignIn: boolean;
  user?: User.Information | null;
};

type Context = {
  changeState: (state: any) => void;
  handleStart: () => void;
  state: State;
};

export default React.createContext<Context>({
  changeState: (state: any) => {},
  handleStart: () => {},
  state: {
    openDownloadMetaMask: false,
    openSignUp: false,
    openSignIn: false,
    user: null,
  },
});
