import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  deadline: Date;
};

const Countdown = (props: Props) => {
  const { t } = useTranslation(['component']);
  const [record, setRecord] = useState({
    days: 0,
    minutes: 0,
    hours: 0,
    seconds: 0,
    time_up: '',
  });
  const [deadline, setDeadline] = useState(props.deadline);
  let timer: NodeJS.Timer | null = null;

  const updateTimer = () => {
    const now = new Date().getTime();
    const t = (deadline as unknown as number) - now;
    const days = Math.floor(t / (1000 * 60 * 60 * 24));
    const hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((t % (1000 * 60)) / 1000);

    setRecord({ ...record, days, minutes, hours, seconds });

    if (t < 0) {
      clearInterval(timer as unknown as number);
      setRecord({
        days: 0,
        minutes: 0,
        hours: 0,
        seconds: 0,
        time_up: 'TIME IS UP',
      });
    }
  };

  useEffect(() => {
    timer = setInterval(updateTimer, 1000);
  }, [props.deadline]);

  return (
    <div className="py-2">
      <div className="browse-time-left">
        <ul className="clearfix text-center p-0">
          <li>
            <span>{record.days}</span> {t('countdown.label.days')}
          </li>
          <li>
            <span>{record.hours}</span> {t('countdown.label.hours')}
          </li>
          <li>
            <span>{record.minutes}</span> {t('countdown.label.minutes')}
          </li>
          <li>
            <span>{record.seconds}</span> {t('countdown.label.seconds')}
          </li>
        </ul>
      </div>
      {record.days === 0 && record.hours === 0 && record.minutes === 0 && record.seconds === 0 ? (
        <div className="text-center mt-4">
          <h4>{t('countdown.message.timeup')}</h4>
        </div>
      ) : record.days === 0 && record.hours === 0 ? (
        <div className="text-center mt-4">
          <h4>{t('countdown.message.timerunningout')}</h4>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Countdown;
