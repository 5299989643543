import Web3 from 'web3';
import { loadToppyRedeemContract } from './blockchainInteractor';
import { getGasPrice } from 'src/utils/helper';

export async function redeemNFT(
  web3: Web3,
  account: string,
  contractaddress: string,
  tokenId: string,
) {
  const contract = loadToppyRedeemContract(web3);

  try {
    const latestGasPrice:any = await getGasPrice(web3);
    const txn = await contract.methods.redeemNFT(contractaddress, tokenId).send({
      from: account,
      value: 0,
      ...latestGasPrice
    });

    return txn;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
