import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import DefaultBanner from '../assets/images/defaultBanner.png';
import xWinLogo from '../assets/images/xWinLogo.png';
import { convertIPFSToHTTPS, getAddressShort, goToBSCScan } from '../utils/helper';
import { updateUser } from '../services/userService';
import { uploadFileV2 } from '../services/utilService';
import { UploadEntity } from '../constant';
import UserContext from '../contexts/UserContext';
import SocialMedia from './SocialMedia';

interface Props {
  user: User.Information;
  editable: boolean;
}

const UserProfileHeader = (props: Props) => {
  const { user, editable } = props;

  const { t } = useTranslation(['page.profile', 'page.marketplace', 'common']);

  const { state, changeState } = useContext(UserContext);

  const [information, setInformation] = useState<User.Information>({
    profileImage: '',
    bannerImage: '',
    username: '',
    email: '',
    address: '',
    bio: '',
  });

  useEffect(() => {
    setInformation({
      profileImage: user?.profileImage || '',
      bannerImage: user?.bannerImage || '',
      username: user?.username || '',
      email: user?.email || '',
      address: user?.address || '',
      bio: user?.bio || '',
      facebook: user?.facebook || '',
      twitter: user?.twitter || '',
      telegram: user?.telegram || '',
      language: user?.language || '',
    });
  }, [user]);

  const saveUserInfo = async (userInfo) => {
    try {
      const { data: returnedUser } = await updateUser(userInfo);

      if (returnedUser) {
        const updatedUser = {
          ...state.user,
          ...returnedUser,
        } as User.Information;

        localStorage.setItem('user', JSON.stringify(updatedUser));
        changeState({ user: updatedUser });
        toast.success(t('component:profile-updated'));
      }
    } catch (e) {
      toast.error(t('common:toast.something-went-wrong'));
    }
  };

  const setFileChange = async (entity: UploadEntity, files) => {
    try {
      const formData = new FormData();

      formData.append('file', files[0]);
      const { data } = await uploadFileV2(entity, formData);
      const url = data.Location;

      if (url) {
        let selectedKey = 'bannerImage';
        if (entity === UploadEntity.USER_PROFILE_IMAGE) {
          selectedKey = 'profileImage';
        }
        const updatedUserInfo = { ...information, [selectedKey]: url };
        setInformation(updatedUserInfo);
        await saveUserInfo(updatedUserInfo);
      }
    } catch (e) {
      toast.error(t('common:toast.something-went-wrong'));
    }
  };

  return (
    <>
      <section className="jumbotron breadcumb no-bg">
        <img src={user?.bannerImage || DefaultBanner} className="bannar-image" />
      </section>
      <section className="container d_coll no-top no-bottom">
        <div className="profile_avatar">
          <div className="d_profile_img">
            <img src={convertIPFSToHTTPS(user?.profileImage || xWinLogo) || xWinLogo} alt="" />
            {editable && (
              <label role="button" htmlFor="profile-pic-upload" style={{ display: 'contents' }}>
                <i className="fa fa-camera" />
              </label>
            )}
            <input
              id="profile-pic-upload"
              type="file"
              className="d-none"
              name="profilePic"
              onChange={({ currentTarget }) => {
                setFileChange(UploadEntity.USER_PROFILE_IMAGE, currentTarget.files);
                currentTarget.value = null as unknown as string;
              }}
              accept="image/*"
            />
          </div>
        </div>
        {editable && (
          <div className="banner">
            <div className="setting-change-profile text-center">
              <div>
                <label role="button" htmlFor="banner-pic-upload">
                  <FontAwesomeIcon icon={faCamera} className="mr-2" />
                </label>
                <input
                  id="banner-pic-upload"
                  type="file"
                  className="d-none"
                  name="bannerPic"
                  onChange={({ currentTarget }) => {
                    setFileChange(UploadEntity.USER_BANNER_IMAGE, currentTarget.files);
                    currentTarget.value = null as unknown as string;
                  }}
                  accept="image/*"
                />
                {t('label.change-banner-image')}
              </div>
            </div>
          </div>
        )}
        <div className="mt-2">
          <h3 className="mb-2 text-center">{user?.username}</h3>
          <SocialMedia user={user} />
          <div className="de_tab">
            <ul className="de_nav">
              <li id="Mainbtn1" className="active">
                <span onClick={() => goToBSCScan(user?.address || '')}>
                  <i className="fa fa-user"></i> &nbsp;
                  {getAddressShort(user?.address)}
                </span>
              </li>
            </ul>
          </div>
          <p className="bio mb-4 text-center">{user?.bio}</p>
        </div>
      </section>
    </>
  );
};
export default UserProfileHeader;
