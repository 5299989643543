import axios from 'axios';
import _ from 'lodash';
import systemConfig from '../configs/systemConfig';
import { bnbaddress, EventHistoryType } from '../constant';
const moralisHost = 'https://api.toppy.market/api/moralis';

// returns an array of json, token_id and token_uri can be retrieved from this json array
// however, token_uri may not be the latest for mysterious NFTs
export const moralisGetNFT = async (userAddress, contractAddress) => {
  const { data } = await axios.get(`${moralisHost}/moralis/get-nft-owner`, {
    params: {
      address: contractAddress,
      userAddress: userAddress,
      chain: getMoralisChain(),
    },
  });

  return data;
};

// export const moralisGetTotalSales = async (userAddress, contractAddress) => {
//   const options = { address: userAddress, token_address: contractAddress, chain: 'bsc testnet' };
//   const nftOwners = await Moralis.Web3API.account.getNFTsForContract(options);
//   return nftOwners.result;
// }
export const moralisGetXWINPrice = async () => {
  return getTokenPrice('0xd88ca08d8eec1E9E09562213Ae83A7853ebB5d28');
};

export const moralisGetMaticPrice = async () => {
  return getTokenPrice('0xCC42724C6683B7E57334c4E856f4c9965ED682bD');
};

export const moralisGetPrice = async (tokenaddress) => {
  return getTokenPrice(tokenaddress);
};

export const getTokenPrice = async (tokenaddress: string) => {
  try {
    const { data } = await axios.get(`${moralisHost}/moralis/get-token-price`, {
      params: {
        address: tokenaddress,
        chain: getMoralisChain(),
      },
    });

    return data;
  } catch (error) {
    console.log(error);

    return 0;
  }
};

export const moralisGetMainEvents = async (hashedKey: string): Promise<Nft.EventHistory[]> => {
  console.log('hashedKey', hashedKey);
  const promiseData = await Promise.all([
    _getBidEvents(hashedKey),
    _getMintEvents(hashedKey),
    _getListingEvents(hashedKey),
    _getEnglishOfferEvents(hashedKey),
    _getToppyRedeemEvents(hashedKey),
  ]);

  // const bidResults = await _getBidEvents(hashedKey)
  // const mintResults = await _getMintEvents(hashedKey)
  // const listingResults = await _getListingEvents(hashedKey)
  // const offerResults = await _getEnglishOfferEvents(hashedKey)

  const finalResults = promiseData[0].concat(
    promiseData[1],
    promiseData[2],
    promiseData[3],
    promiseData[4],
  );
  return _.sortBy(finalResults, (a) => a.createDt);
};

async function _getBidEvents(hashedKey: string) {
  const { data: bidEvents } = await axios.get(
    `${moralisHost}/moralis-functions/get-toppy-bid-event/${hashedKey}`,
  );

  const results: Nft.EventHistory[] = [];
  bidEvents?.forEach((attributes) => {
    const { block_timestamp, totalPrice, buyer, owner, tokenPayment } = attributes;
    results.push({
      eventType: EventHistoryType.Bid,
      createDt: block_timestamp,
      from: owner,
      to: buyer,
      price: totalPrice,
      tokenPayment,
    });
  });

  const { data: mbidEvents } = await axios.get(
    `${moralisHost}/moralis-functions/get-toppy-mystery-buy-event/${hashedKey}`,
  );

  mbidEvents?.forEach((attributes) => {
    const { block_timestamp, totalPrice, buyer, owner, tokenPayment } = attributes;
    results.push({
      eventType: EventHistoryType.Bid,
      createDt: block_timestamp,
      from: owner,
      to: buyer,
      price: totalPrice,
      tokenPayment,
    });
  });
  return results;
}

async function _getEnglishOfferEvents(hashedKey: string) {
  const { data: bidEvents } = await axios.get(
    `${moralisHost}/moralis-functions/get-toppy-auction-offer-event/${hashedKey}`,
  );

  const results: Nft.EventHistory[] = [];
  bidEvents?.forEach((attributes) => {
    const { block_timestamp, totalPrice, owner, offeror, tokenPayment } = attributes;
    results.push({
      eventType: EventHistoryType.Offer,
      createDt: block_timestamp,
      from: offeror,
      to: owner,
      price: totalPrice,
      tokenPayment,
    });
  });
  return results;
}

async function _getMintEvents(hashedKey: string) {
  const { data: events } = await axios.get(
    `${moralisHost}/moralis-functions/get-toppy-mint-event/${hashedKey}`,
  );

  const results: Nft.EventHistory[] = [];
  events?.forEach((attributes) => {
    const {
      block_timestamp,
      //'0': price,
      from,
      //nftContract: from
    } = attributes;
    results.push({
      eventType: EventHistoryType.Mint,
      createDt: block_timestamp,
      from: bnbaddress, //: tokenConfig.default.address,
      to: from,
      price: '0',
    });
  });
  return results;
}

async function _getToppyRedeemEvents(hashedKey: string) {
  const { data: events } = await axios.get(
    `${moralisHost}/moralis-functions/get-toppy-redeem-event/${hashedKey}`,
  );

  const results: Nft.EventHistory[] = [];
  events?.forEach((attributes) => {
    const { block_timestamp, owner, to } = attributes;
    results.push({
      eventType: EventHistoryType.Redeem,
      createDt: block_timestamp,
      from: owner,
      to,
      price: '0',
    });
  });
  return results;
}

async function _getListingEvents(hashedKey: string) {
  const { data: events } = await axios.get(
    `${moralisHost}/moralis-functions/get-toppy-create-listing-event/${hashedKey}`,
  );
  const results: Nft.EventHistory[] = [];

  events?.forEach((attributes) => {
    const { block_timestamp, startingPrice, from, tokenPayment } = attributes;
    results.push({
      eventType: EventHistoryType.CreateAuction,
      createDt: block_timestamp,
      from, //: tokenConfig.default.address,
      to: bnbaddress,
      price: startingPrice,
      tokenPayment,
    });
  });
  return results;
}

async function _getCancelEvents(hashedKey: string) {
  const { data: events } = await axios.get(
    `${moralisHost}/moralis-functions/get-toppy-cancel-auction-event/${hashedKey}`,
  );

  const results: Nft.EventHistory[] = [];
  events?.forEach(({ attributes }) => {
    const {
      block_timestamp: createDt,
      startingPrice: price,
      //nftContract: to,
      from: from,
    } = attributes;
    results.push({
      eventType: EventHistoryType.CancelAuction,
      createDt,
      from, //: tokenConfig.default.address,
      to: bnbaddress,
      price: '0',
    });
  });
  return results;
}

export const moralisGetTotalVolume = async () => {
  try {
    const results: Nft.SalesHistory[] = [];

    const { data } = await axios.post(`${moralisHost}/moralis-functions/getTotalSales`, {
      table: 'ToppyBidEvent',
    });

    data.forEach(({ objectId, totalSalesBalance }) => {
      const { nftContract: nftContract, tokenPayment: tokenPayment } = objectId;
      results.push({
        nftContract: nftContract,
        tokenPayment: tokenPayment,
        totalSalesBalance: totalSalesBalance,
      });
    });

    const { data: salesMys } = await axios.post(`${moralisHost}/moralis-functions/getTotalSales`, {
      table: 'ToppyMysteryBuyEvent',
    });

    salesMys.forEach(({ objectId, totalSalesBalance }) => {
      const { nftContract: nftContract, tokenPayment: tokenPayment } = objectId;
      results.push({
        nftContract: nftContract,
        tokenPayment: tokenPayment,
        totalSalesBalance: totalSalesBalance,
      });
    });

    return results;
  } catch (error) {
    console.log(error);
  }
};

export const moralisGetTotalVolumeByCollection = async () => {
  try {
    const results: Nft.SalesHistoryByCollection[] = [];

    const { data } = await axios.post(
      `${moralisHost}/moralis-functions/getTotalSalesByCollection`,
      {
        table: 'toppyBidTransaction',
      },
    );

    data?.result?.forEach(({ objectId, totalSalesBalance }) => {
      const { userCollectionId: userCollectionId, tokenPayment: tokenPayment } = objectId;
      results.push({
        userCollectionId: userCollectionId,
        tokenPayment: tokenPayment,
        totalSalesBalance: totalSalesBalance,
      });
    });

    return results;
  } catch (error) {
    console.log(error);
  }
};

export const moralisGetFloorPrice = async () => {
  try {
    const { data } = await axios.post(`${moralisHost}/moralis-functions/getFloorPrice`);
    const results: Nft.AuctionHistory[] = [];
    data.forEach(({ objectId, minPrice }) => {
      const { nftContract: nftContract, tokenPayment: tokenPayment } = objectId;
      results.push({
        nftContract: nftContract,
        tokenPayment: tokenPayment,
        minPrice: minPrice,
      });
    });
    return results;
  } catch (error) {
    console.log(error);
  }
};

export const moralisGetFloorPriceByCollection = async () => {
  try {
    const { data } = await axios.post(`${moralisHost}/moralis-functions/getFloorPriceByCollection`);

    const results: Nft.AuctionHistoryByCollection[] = [];
    data?.result?.forEach(({ objectId, minPrice }) => {
      const { userCollectionId: userCollectionId, tokenPayment: tokenPayment } = objectId;
      results.push({
        userCollectionId: userCollectionId,
        tokenPayment: tokenPayment,
        minPrice: minPrice,
      });
    });
    return results;
  } catch (error) {
    console.log(error);
  }
};

export const moralisGetTotalItemByAddress = async (account: string) => {
  try {
    const { data } = await axios.post(
      `${moralisHost}/moralis-functions/getFloorPriceByCollection`,
      { account: account.toLowerCase() },
    );

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const moralisGetTotalItemByUserAddress = async (account: string) => {
  try {
    const { data } = await axios.post(
      `${moralisHost}/moralis-functions/getTotalItemByUserAddress`,
      { account: account.toLowerCase() },
    );

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const moralisGetTotalListingByAddress = async (account: string) => {
  try {
    const { data } = await axios.post(`${moralisHost}/moralis-functions/getTotalListingByAddress`, {
      account: account.toLowerCase(),
    });

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const moralisGetUniqueUserCountByAddress = async (nftaddress: string) => {
  try {
    const { data } = await axios.get(
      `${moralisHost}/moralis/get-transfer-unique-user-count-by-address`,
      {
        params: {
          address: nftaddress,
          chain: getMoralisChain(),
        },
      },
    );

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllSalesByUser = async (account: string) => {
  const today = new Date();
  const fromDate = new Date(today.setMonth(today.getMonth() - 3));

  const { data: bidEvents } = await axios.get(
    `${moralisHost}/moralis-functions/get-all-sales-by-user`,
    {
      params: {
        owner: account.toLowerCase(),
        startAt: new Date(
          fromDate.getFullYear(),
          fromDate.getMonth(),
          fromDate.getDay(),
        ).toISOString(),
      },
    },
  );

  const results: Nft.EventHistory[] = [];
  bidEvents?.forEach(({ attributes }) => {
    const {
      block_timestamp: createDt,
      totalPrice: price,
      buyer: to,
      owner: from,
      tokenPayment: tokenPayment,
    } = attributes;
    results.push({
      eventType: EventHistoryType.Bid,
      createDt,
      from,
      to,
      price,
      tokenPayment,
    });
  });

  return results;
};

const getMoralisChain = ():
  | 'eth'
  | '0x1'
  | 'ropsten'
  | '0x3'
  | 'rinkeby'
  | '0x4'
  | 'goerli'
  | '0x5'
  | 'kovan'
  | '0x2a'
  | 'polygon'
  | '0x89'
  | 'mumbai'
  | '0x13881'
  | 'bsc'
  | '0x38'
  | 'bsc testnet'
  | '0x61'
  | 'avalanche'
  | '0xa86a'
  | 'avalanche testnet'
  | '0xa869'
  | 'fantom'
  | '0xfa' => {
  if (systemConfig.chainId === 80001) {
    return '0x89';
  }

  // if (systemConfig.chainId === 137) {
  //   return '0x89';
  // }

  // if (systemConfig.chainId === 97) {
  // return '0x61';
  // }

  // default to bsc
  return '0x38';
};
