import _ from 'lodash';
import pinataSDK, { PinataPinOptions } from '@pinata/sdk';
import { NFTStorage, File } from 'nft.storage/dist/bundle.esm.min.js';
import systemConfig from '../configs/systemConfig';
import httpService from './httpService';

const pinata = pinataSDK(
  process.env.REACT_APP_PINATA_KEY as string,
  process.env.REACT_APP_PINATA_SECRET as string,
);

export const getERC721Metadata = async (tokenUri: string) => {
  const url = `${systemConfig.apiUrl}/api/toppy/proxy-get/${encodeURIComponent(tokenUri)}`;

  return (await httpService.get(url)).data;
};

export const uploadFileV2 = (entity: string, formData: FormData) => {
  return httpService.post(`${systemConfig.apiUrl}/api/toppy/upload/${entity}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export function paginateV1(items, pageNumber: number, pageSize: number) {
  const startIndex = (pageNumber - 1) * pageSize;

  return _(items).slice(startIndex).take(pageSize).value();
}

function getClientIPFS() {
  const api_key = process.env.REACT_APP_NFTSTORAGE_API_KEY;
  return new NFTStorage({ token: api_key });
}

export const uploadIPFS = async (values, _file: File) => {
  const client = getClientIPFS();
  const metadata = await client.store({
    name: values.title,
    description: values.description,
    category: values.category,
    externalLink: values.externallink,
    contentType: values.contentType,
    image: _file,
    attributes: values.attributes,
  });
  return metadata;
};

// After a user has mint an NFT pin the image and metadata cid to pinata
export const pinAtPinata = async (cid: string) => {
  const options = {
    pinataMetadata: {
      name: 'User Minted NFT',
    },
  };
  pinata.pinByHash(cid, options);
};

export const uploadIPFSPinata = async (values, _file) => {
  try {
    const options = {
      pinataMetadata: {
        name: values.title,
        // TODO fix typescript error as PinataPinOptions does not support key value pair
        keyvalues: {
          customKey: values.title,
          customKey2: 'toppyedition',
        },
      },
      pinataOptions: {
        cidVersion: 0,
      },
    };

    var myBlob = new Blob(_file);
    var myReadableStream = myBlob.stream();
    return pinata
      .pinFileToIPFS(myReadableStream, options as unknown as PinataPinOptions)
      .then((result) => {
        //send result hash CID to create Metadata.
        var data = JSON.stringify({
          name: values.title,
          description: values.description,
          category: values.category,
          external_url: values.externallink,
          image: result.IpfsHash,
          attributes: [values.attributes],
        });

        // data.image = result.IpfsHash.toString();
        //this is the meta data that points to the PNG, pinning JSON to pinata.
        const body = data;
        const options = {
          pinataMetadata: {
            name: 'metadata.json', //this will always be named metadata.json
            // TODO fix typescript error as PinataPinOptions does not support key value pair
            keyvalues: {
              customKey: values.title + 'MetaData',
            },
          },
          pinataOptions: {
            cidVersion: 0,
          },
        };
        return pinata
          .pinJSONToIPFS(body, options as unknown as PinataPinOptions)
          .then((result) => {
            //handle results here Create NFT here.
            // return IpfsHash for URI to mint NFT.
            return result.IpfsHash;
          })
          .catch((err) => {
            //handle error here
            console.log(err);
          });
      })
      .catch((err) => {
        //handle error here
        console.log(err);
      });
  } catch (error) {
    console.log(error);
  }
};
