import moment from 'moment';
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import CustomTooltip from './CustomTooltip';

type Props = {
  data: any;
  height: number;
  width: number;
  dataKey: {
    x: string;
    y: string;
  };
};

const TimeSeriesLineChart = (props: Props) => {
  return (
    <LineChart
      data={props.data}
      width={props.width}
      height={props.height}
      margin={{
        right: 20,
        top: 10,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        dataKey={props.dataKey.x}
        domain={[
          props.data[0][props.dataKey.x],
          props.data[props.data.length - 1][props.dataKey.x],
        ]}
        type="number"
        tick={{ fontSize: 12 }}
        tickFormatter={(date) => moment(date).format('DD/MM/YYYY')}
      />
      <YAxis dataKey={props.dataKey.y} tick={{ fontSize: 12 }} />
      <Tooltip content={<CustomTooltip dataKey={props.dataKey} />} />
      <Line type="monotone" dataKey={props.dataKey.y} stroke="#85c324" activeDot={{ r: 4 }} />
    </LineChart>
  );
};

export default TimeSeriesLineChart;
