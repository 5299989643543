import Web3 from 'web3';
import { loadMintContract, convertToWei } from './blockchainInteractor';
import { getGasPrice } from 'src/utils/helper';

export async function mintNFT(
  account: string,
  web3: Web3,
  nftContract: string,
  cid: string,
  mintFee: string,
) {

  try {
    const contract = loadMintContract(web3);
    const latestGasPrice:any = await getGasPrice(web3);
    
    const txn = await contract.methods.mintNative(nftContract, cid).send({
      from: account,
      value: mintFee, // web3.utils.toWei(mintFee, 'ether'),
      ...latestGasPrice
    });
    return txn; 
  } catch (error) {
    throw error
  }
}

export async function getOriginalOwner(web3: Web3, hashedKey: string) {
  const contract = loadMintContract(web3);

  const origAddress = await contract.methods.creators(hashedKey).call();

  return origAddress;
}

export async function mintMysteriousBoxNFT(
  account: string,
  web3: Web3,
  nftContract: string,
  totalCost: string,
  totalCopy: Number,
  priceType: string,
) {

  try {
    const contract = loadMintContract(web3);
    const totalCostInWei = convertToWei(totalCost.toString());
    const latestGasPrice:any = await getGasPrice(web3);
    
    const txn = await contract.methods.mintMysteryBox(nftContract, account, totalCopy).send({
      from: account,
      value: priceType === '0' ? totalCostInWei : 0,
      ...latestGasPrice
    });
    return txn;  
  } catch (error) {
    throw(error)
  }
  
}
