import Web3 from 'web3';
import { convertFromWei, loadStakingContract } from './blockchainInteractor';
import { getGasPrice } from 'src/utils/helper';

export async function stakeNFT(account: string, web3: Web3, pid: string, tokenId: string) {
  const contract = loadStakingContract(web3);

  try {
    const latestGasPrice:any = await getGasPrice(web3);
    const txn = await contract.methods.stake(pid, tokenId).send({
      from: account,
      value: 0,
      ...latestGasPrice
    });

    return txn;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function harvest(account: string, web3: Web3, pid: string, tokenId: string) {
  const contract = loadStakingContract(web3);

  try {

    const latestGasPrice:any = await getGasPrice(web3);
    const txn = await contract.methods.harvest(pid, tokenId).send({
      from: account,
      value: 0,
      ...latestGasPrice
    });
  
    return txn;
    
  } catch (error) {
    throw error
  }

}

export async function getPendingRewards(web3: Web3, pid: string, tokenId: string) {
  const contract = loadStakingContract(web3);
  const txn = await contract.methods.pendingRewards(pid, tokenId).call();
  return Number(convertFromWei(txn)).toFixed(4);
}

export async function getIsStaked(web3: Web3, pid: string, hashedKey: string) {
  const contract = loadStakingContract(web3);
  const userInfo = await contract.methods.userInfo(pid, hashedKey).call();
  return Number(userInfo.amount) > 0;
}

export async function getNFTAPR(
  web3: Web3,
  nftScore: string,
  pid: string,
  nftPrice: string,
  xwinPrice: string,
) {
  if (Number(nftPrice) === 0) {
    return '0';
  }
  const contract = loadStakingContract(web3);
  let xwinPerBlock = await contract.methods.xwinPerBlock().call();
  xwinPerBlock = convertFromWei(xwinPerBlock);
  const totalAllocPoint = await contract.methods.totalAllocPoint().call();
  const pool = await contract.methods.poolInfo(pid).call();
  if (Number(pool.totalStakedBalance) <= 0) {
    return '0';
  }
  const xwinReward = (xwinPerBlock * pool.allocPoint) / totalAllocPoint;
  const accCakePerShare = (Number(nftScore) * xwinReward * 1e18) / Number(pool.totalStakedBalance);
  const proceeds = accCakePerShare * 28800 * 365 * Number(xwinPrice);
  return Number((proceeds / Number(nftPrice)) * 100).toFixed(2);
}

export async function getNFTScores(nftContract: string, web3: Web3, tokenId: string) {
  const contract = loadStakingContract(web3);
  const txn = await contract.methods.getNFTScores(nftContract, tokenId).call();
  return Number(Number(convertFromWei(txn))).toFixed(2);
}
