import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UserContext from '../contexts/UserContext';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../contexts/AppContext';
import Accordion from '../components/Accordion';
import Select from 'react-select';
import {
  CommonRoutes,
  moralisXWinAddress,
  wbnbaddress,
  xwinaddress,
  bnbaddress,
  usdtaddress,
} from '../constant';
import { getAllSalesByUser, moralisGetPrice, moralisGetXWINPrice, moralisGetMaticPrice } from '../utils/moralisInteractor';
import ReactApexChart from 'react-apexcharts';
import { convertFromWei } from 'src/utils/blockchainInteractor';
import tokenConfig from 'src/configs/tokenConfig';
import { getPaymentTokenDecimalsByAddress } from 'src/utils/helper';
import systemConfig from 'src/configs/systemConfig';

const SalesDashboard = (props) => {
  const { t } = useTranslation(['page.import-nft', 'common']);
  const history = useHistory();
  const { account, web3 } = useContext(AppContext);
  const userContext = useContext(UserContext);
  const { handleStart, state } = userContext;
  const { user } = state;
  const [allSalesHistory, setAllSalesHistory] = useState<Nft.EventHistory[]>();
  const [totalValue, setTotalValue] = useState<number>(0);
  const [bnbValues, setBnbValues] = useState<number[]>([]);
  const [xwinValues, setXwinValues] = useState<number[]>([]);
  const [usdtValues, setUSDTValues] = useState<number[]>([]);
  const [chartLabels, setChartLabels] = useState<string[]>();
  const [prices, setPrices] = useState({
    xwin: 1,
    bnb: 1,
    usdt: 1,
  });
  const [selectedEvent, setSelectedEvent] = useState(tokenConfig.default.token.toLowerCase());

  const customStyles = {
    option: (base, state) => ({
      ...base,
      background: 'var(--background)',
      color: 'var(--text)',
      borderRadius: state.isFocused ? '0' : 0,
      '&:hover': {
        background: 'var(--text)',
        color: 'var(--background)',
      },
    }),
    menu: (base) => ({
      ...base,
      background: '#212428 !important',
      borderRadius: 0,
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
    control: (base, state) => ({
      ...base,
      padding: 2,
    }),
  };

  useEffect(() => {
    init();
  }, [account]);

  const init = async () => {
    if (!account && !user) {
      history.replace(CommonRoutes.MARKETPLACE);
      return;
    }
    const xwinPrice = await moralisGetXWINPrice();
    const bnbPrice = (systemConfig.chainId==137 || systemConfig.chainId==80001) ? await moralisGetMaticPrice() : await moralisGetPrice(wbnbaddress) ;
    if (xwinPrice && bnbPrice) {
      setPrices({
        xwin: xwinPrice,
        bnb: bnbPrice,
        usdt: 1,
      });
    }
    const res = await getAllSalesByUser(account);
    setAllSalesHistory(res || []);
    updateChart(tokenConfig.default.token.toLowerCase(), res);
  };

  const handleChangeEvent = (selected: any) => {
    const value = selected.value;
    setSelectedEvent(value);
    updateChart(value, allSalesHistory || []);
  };

  const updateChart = (value, res: Nft.EventHistory[]) => {
    setSelectedEvent(value);
    let results = [] as Nft.EventHistory[];
    let myBalDataBNB = [] as number[];
    let myBalDataXWIN = [] as number[];
    let myBalDataUSDT = [] as number[];
    let myDateLabel = [] as string[];
    let decimals = 18;
    if (value === tokenConfig.default.token.toLowerCase()) {
      results =
        res?.filter((x) => x.tokenPayment?.toLowerCase() === bnbaddress.toLowerCase()) || [];
    } else if (value === 'xwin') {
      results =
        res?.filter((x) => x.tokenPayment?.toLowerCase() === xwinaddress.toLowerCase()) || [];
    } else if (value === 'usdt') {
      results =
        res?.filter((x) => x.tokenPayment?.toLowerCase() === usdtaddress.toLowerCase()) || [];

      decimals = getPaymentTokenDecimalsByAddress(usdtaddress);

    } else {
      results = res || [];
    }
    results.forEach((s) => {
      let priceiInUSD = 0;
      if (s.tokenPayment?.toLowerCase() === bnbaddress.toLowerCase()) {
        priceiInUSD = Number(convertFromWei(s.price, decimals)) * prices.bnb;
        myBalDataBNB.push(Number(priceiInUSD.toFixed(2)));
      }
      if (s.tokenPayment?.toLowerCase() === xwinaddress.toLowerCase()) {
        priceiInUSD = Number(convertFromWei(s.price, decimals)) * prices.xwin;
        myBalDataXWIN.push(Number(priceiInUSD.toFixed(2)));
      }
      if (s.tokenPayment?.toLowerCase() === usdtaddress.toLowerCase()) {
        priceiInUSD = Number(convertFromWei(s.price, decimals)) * 1;
        myBalDataUSDT.push(Number(priceiInUSD.toFixed(2)));
      }
      myDateLabel.push(new Date(s.createDt).toLocaleDateString('en-US'));
    });
    setBnbValues(myBalDataBNB);
    setXwinValues(myBalDataXWIN);
    setUSDTValues(myBalDataUSDT);
    setChartLabels(myDateLabel);

    let sum = myBalDataBNB.reduce(function (prev, current) {
      return Number(prev) + Number(current);
    }, 0);

    let sumXWIN = myBalDataXWIN.reduce(function (prev, current) {
      return Number(prev) + Number(current);
    }, 0);

    let sumUSDT = myBalDataUSDT.reduce(function (prev, current) {
      return Number(prev) + Number(current);
    }, 0);

    setTotalValue(Number(sum) + Number(sumXWIN) + Number(sumUSDT));
  };

  const options = {
    optionsMixedChart: {
      chart: {
        id: 'basic-bar',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '75%',
        },
      },
      stroke: {
        width: [4, 0, 0],
      },
      xaxis: {
        categories: chartLabels || [],
      },
      markers: {
        size: 6,
        strokeWidth: 3,
        fillOpacity: 0,
        strokeOpacity: 0,
        hover: {
          size: 8,
        },
      },
      // yaxis: {
      //   tickAmount: 5,
      //   // min: 0,
      //   // max: 100
      // }
    },
    seriesMixedChart: [
      {
        name: tokenConfig.default.token,
        type: 'column',
        data: bnbValues || [],
      },
      {
        name: 'XWIN',
        type: 'column',
        data: xwinValues || [],
      },
      {
        name: 'USDT',
        type: 'column',
        data: xwinValues || [],
      },
    ],
    optionsRadial: {
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: '70%',
            background: '#fff',
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: 'front',
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: '#fff',
            strokeWidth: '67%',
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },

          dataLabels: {
            showOn: 'always',
            name: {
              offsetY: -20,
              show: true,
              color: '#888',
              fontSize: '13px',
            },
            value: {
              formatter: function (val) {
                return Number(val).toFixed(2);
              },
              color: '#111',
              fontSize: '30px',
              show: true,
            },
          },
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: ['#ABE5A1'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [90, 0, 100],
        },
      },
      stroke: {
        lineCap: 'round',
      },
      labels: ['Percent'],
    },
    seriesRadial: [76],
    optionsBar: {
      chart: {
        stacked: true,
        stackType: '100%',
        toolbar: {
          show: true,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        dropShadow: {
          enabled: true,
        },
      },
      stroke: {
        width: 0,
      },
      xaxis: {
        categories: ['Fav Color'],
        labels: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
      },
      fill: {
        opacity: 1,
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'vertical',
          shadeIntensity: 0.35,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [3, 0, 5],
        },
      },

      legend: {
        position: 'bottom',
        horizontalAlign: 'right',
      },
    },
  };

  return (
    <>
      <div className="spacer-20"></div>
      <section className="container">
        <div className="mr-4">
          <h5>{t('Payment Token')}</h5>
        </div>
        <div className="mr-4">
          <Select
            className="select1"
            styles={customStyles}
            isSearchable={false}
            onChange={handleChangeEvent}
            defaultValue={{
              value: tokenConfig.default.token.toLowerCase(),
              label: tokenConfig.default.token,
            }}
            options={[
              { value: tokenConfig.default.token.toLowerCase(), label: tokenConfig.default.token },
              { value: 'xwin', label: 'XWIN' },
              { value: 'usdt', label: 'USDT' },
              { value: 'all', label: 'All' },
            ]}
          />
        </div>

        <Accordion
          alwaysOpen
          className="mt-4 "
          icon="fa fa-align-left"
          title={t('component:total-sales-title', {
            totalValue: Number(totalValue).toFixed(2),
          })}
        >
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 offset-xl-0 offset-lg-0 offset-md-0">
              <div>
                <div className="easy-box">
                  <div className="row"></div>
                  <div className="row">
                    <ReactApexChart
                      options={options.optionsMixedChart}
                      series={options.seriesMixedChart}
                      type="bar"
                      height="350"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Accordion>
      </section>
    </>
  );
};

export default SalesDashboard;
