import confetti from 'canvas-confetti';
import classNames from 'classnames';
import { toInteger } from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { convertFromWei } from 'src/utils/blockchainInteractor';
import ProgressLoader from '../components/ProgressLoader';
import collectionsConfig from '../configs/collectionsConfig';
import poolConfig from '../configs/poolConfig';
import { ListingType, NftType, bnbaddress } from '../constant';
import {
  displayMessage,
  getPaymentTokenDecimalsByAddress,
  getPriceInUSD,
  resolvePromise,
  truncateTitle,
} from '../utils/helper';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';
import ReactInterval from 'react-interval';
import ReactPlayer from 'react-player';
import { AppContext } from 'src/contexts/AppContext';
import { convertIPFSToHTTPS, getNftType, getTokenSymbol } from 'src/utils/helper';
import styled from 'styled-components';
import systemConfig from '../configs/systemConfig';
import { getCurrentPriceByKey, getListingInfo } from '../utils/newMarketPlaceInteractor';
import {
  getIsStaked,
  getNFTAPR,
  getNFTScores,
  getPendingRewards,
  harvest,
  stakeNFT,
} from '../utils/stakingNFTInteractor';
import SpinnerLoader from './SpinnerLoader';

type Props = {
  nft: Nft.Information;
  isMyNft?: boolean;
  nftCategories?: Nft.NftCategory[];
  height;
  onImgLoad;
  prices;
  showBulkCheckbox?: boolean;
  eligibleTokenIds: EligibleTokens[];
  setEligibleTokens?: any;
};

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  max-height: 180px;
`;

const NftCard = (props: Props) => {
  const { account, web3 } = useContext(AppContext);
  const { t } = useTranslation(['component', 'common']);
  const history = useHistory();
  const [nftDetails, setNftDetails] = useState<Nft.Information>();
  const [isSpinning, setSpinner] = useState(true);
  const [auctionDetails, setAuctionDetails] = useState<{
    currentPrice: string;
    timeRemaining: Auction.Timer;
  }>({
    currentPrice: '0',
    timeRemaining: {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      totalSeconds: 0,
    },
  });

  const [poolData, setPoolData] = useState<any>();
  const [configData, setConfigData] = useState<any>();
  const [isLoadingStakingInfo, setIsLoadingStakingInfo] = useState(false);
  const [isLoading, setIsLoading] = useState({
    loading: false,
    type: 'page',
    message: '',
  });

  const [stakeInfo, setStakeInfo] = useState({
    score: '0',
    pendingEarn: '0',
    apr: '0.0',
    isStaked: false,
    isEligible: false,
    isPool: false,
  });

  const nftTitle = props.nft.title;

  useEffect(() => {
    setAuctionDetailsForNFT(props.nft.hashedKey);
    setSpinner(false);
  }, [props.nft]);

  const init = async (currentPrice) => {
    if (props.nft === undefined) return;
    if (!props.nft?.contractAddress) return;
    if (!props.nft.tokenID) return;
    const pool = getPoolData(props.nft?.contractAddress || '');
    if (!pool) return;
    setPoolData(pool);
    const conf = getCollectionData(props.nft?.contractAddress || '');
    setConfigData(conf);
    setIsLoadingStakingInfo(true);
    let _tokenPayment =
      props.nft.listingType == ListingType.NOT_LISTED ? conf?.tokenPayment : props.nft.tokenPayment;
    const decimals = getPaymentTokenDecimalsByAddress(_tokenPayment || bnbaddress);
    const costPerNFT = convertFromWei(conf == undefined ? '0' : conf.costPerNFT, decimals);
    let _price = props.nft.listingType == ListingType.NOT_LISTED ? costPerNFT : currentPrice;
    const nftCostInUSD = getPriceInUSD(props.prices, _price, _tokenPayment || bnbaddress); //convertIntoUSD(_price, _tokenPayment)
    const promiseData = await Promise.all([
      getNFTScores(props.nft?.contractAddress, web3, props.nft.tokenID),
      getPendingRewards(web3, pool.pid, props.nft.tokenID),
      getIsStaked(web3, pool.pid, props.nft.hashedKey),
    ]);
    const nftScore = promiseData[0];
    const earn = promiseData[1];
    const isStaked = promiseData[2];
    const apr = await getNFTAPR(web3, nftScore, pool.pid, nftCostInUSD, props.prices.xwin);
    setStakeInfo({
      score: nftScore,
      pendingEarn: earn,
      apr: apr, // isStaked ? apr : "0",
      isStaked: isStaked,
      isEligible: Number(nftScore) > 0,
      isPool: pool.pid != '',
    });
    setIsLoadingStakingInfo(false);
  };

  const refreshStaking = async () => {
    await init(auctionDetails.currentPrice);
  };

  const refreshPrice = async (hashedKey) => {
    let cPrice = await getCurrentPriceByKey(web3, hashedKey);
    setAuctionDetails({
      currentPrice: Number(cPrice).toFixed(3),
      timeRemaining: getTimeRemaining(nftDetails),
    });
    await init(cPrice);
  };

  const getAPRToolTip = () => {
    if (stakeInfo.apr == 'N/A') {
      return t('component:no-apr-tooltip');
    } else {
      return t('component:apr-tooltip', {
        currentPrice: auctionDetails.currentPrice,
        tokenPayment: getTokenSymbol(nftDetails?.tokenPayment),
      });
    }
  };

  const getStakeOrNotToolTip = () => {
    if (stakeInfo.apr == 'N/A') {
      return '';
    } else {
      if (!stakeInfo.isStaked) return t('not-stake-yet-tooltip');
      else return t('already-stake-tooltip');
    }
  };

  const setAuctionDetailsForNFT = async (hashedKey) => {
    if (props.nft.listingType === ListingType.NOT_LISTED) {
      init('');
      return;
    }
    const res = await getListingInfo(web3, hashedKey);
    if (res == null) return;

    const decimals = getPaymentTokenDecimalsByAddress(props.nft?.tokenPayment || bnbaddress);
    let cPrice = await getCurrentPriceByKey(web3, hashedKey, decimals);
    const currPrice = Number(cPrice).toFixed(3);
    if (res !== null) {
      setAuctionDetails({
        currentPrice: currPrice,
        timeRemaining: getTimeRemaining(res),
      });
    }
    setNftDetails({ ...res });
    init(currPrice);
  };

  const getCollectionData = (nftAddress: string) => {
    if (nftAddress == '') return null;
    const coll = collectionsConfig.collection.find(
      (x) =>
        systemConfig.chainId === x.chainId &&
        x.contractaddress.toLowerCase() === nftAddress.toLowerCase(),
    );
    return coll;
  };

  const getPoolData = (nftAddress: string) => {
    if (nftAddress == '') return null;
    const pool = poolConfig.pool.find(
      (x) => x.contractaddress.toLowerCase() === nftAddress.toLowerCase(),
    );
    return pool;
  };

  const getTimeObj = (totalSeconds: number): Auction.Timer => {
    let secondsRemaining = totalSeconds;
    const days = Math.floor(secondsRemaining / (60 * 60 * 24));

    secondsRemaining -= days * 24 * 60 * 60;
    const hours = Math.floor(secondsRemaining / (60 * 60));

    secondsRemaining -= hours * 60 * 60;
    const minutes = Math.floor(secondsRemaining / 60);

    secondsRemaining -= minutes * 60;
    const seconds = Math.floor(secondsRemaining);

    return {
      days,
      hours,
      minutes,
      seconds,
      totalSeconds,
    };
  };

  const getTimeRemaining = (res): Auction.Timer => {
    if (res?.startedAt == '0') {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        totalSeconds: 0,
      };
    }
    const today = new Date();
    const endDate = new Date((toInteger(res?.startedAt) + toInteger(res?.duration)) * 1000);

    if (endDate <= today) {
      return getTimeObj(0);
    }

    const secondsRemaining = Math.floor(
      Math.abs((endDate as unknown as number) - (today as unknown as number)) / 1000,
    );
    return getTimeObj(secondsRemaining);
  };

  const isEnded = (nft) => {
    const today = new Date();
    const endDate = new Date((toInteger(nft?.startedAt) + toInteger(nft?.duration)) * 1000);

    if (endDate <= today) {
      return true;
    }
  };

  const displayPrice = () => {
    if (
      props.nft.listingType === ListingType.AUCTION ||
      props.nft.listingType === ListingType.ENGLISH_AUCTION
    ) {
      return (
        <>{`${Number(auctionDetails?.currentPrice).toFixed(2)}  ${getTokenSymbol(
          nftDetails?.tokenPayment,
        )}`}</>
      );
    }
    if (props.nft.listingType === ListingType.MARKETPLACE) {
      if (!props?.nft?.price) {
        return <></>;
      }
      return <>{`${props.nft.price as string} ${getTokenSymbol(nftDetails?.tokenPayment)}`}</>;
    }
    if (props.nft.listingType === ListingType.NOT_LISTED) {
      return <>Not Listed</>;
    }
    return '';
  };

  const handleStake = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
    }
    try {
      if (!props.nft.tokenID) return;
      setIsLoading({
        loading: true,
        type: 'page',
        message: t('component:staking-nft'),
      });
      const [, unlistError] = await resolvePromise(
        stakeNFT(account, web3, poolData.pid, props.nft.tokenID),
      );
      if (!unlistError) {
        confetti();
        toast.success(t('common:toast.success'));
        refreshStaking();
      } else {
        displayMessage(unlistError, t);
      }
      return;
    } catch (error) {
      toast.error(t('toast.error'));
    } finally {
      setIsLoading({ loading: false, type: 'page', message: '' });
    }
  };

  const handleHarvest = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
    }
    try {
      if (!props.nft.tokenID) return;
      setIsLoading({
        loading: true,
        type: 'page',
        message: t('component:harvesting-nft'),
      });
      const [, unlistError] = await resolvePromise(
        harvest(account, web3, poolData.pid, props.nft.tokenID),
      );
      if (!unlistError) {
        confetti();
        toast.success(t('common:toast.success'));
        refreshStaking();
      } else {
        displayMessage(unlistError, t);
      }
      return;
    } catch (error) {
      toast.error(t('toast.error'));
    } finally {
      setIsLoading({ loading: false, type: 'page', message: '' });
    }
  };

  return (
    <div className="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4">
      <ProgressLoader
        loading={isLoading.loading}
        type={isLoading.type}
        message={isLoading.message}
      />

      <ReactInterval
        timeout={20000}
        enabled={true}
        callback={() => refreshPrice(props.nft.hashedKey)}
      />
      <div
        className={classNames({
          'nft__item nft__item__height m-0 p-3': true,
        })}
      >
        <span className="store-label">{props.nft?.category?.toUpperCase() || 'N/A'}</span>
        <span className="d-flex justify-content-end">{truncateTitle(props.nft.title)}</span>
        <div
          onClick={() => history.push(`/nft/${props.nft.hashedKey}`)}
          className="nft__item_wrap mt-1 m-0"
          style={{ height: 220 }}
        >
          {getNftType(props.nft?.contentType as string) === NftType.Audio ||
          getNftType(props.nft?.contentType as string) === NftType.Video ? (
            <Outer>
              <span>
                <ReactPlayer
                  className="nft-card-player"
                  width="100%"
                  height={150}
                  url={convertIPFSToHTTPS(props.nft?.NFTImage || '')}
                />
              </span>
            </Outer>
          ) : (
            <Outer>
              <span>
                {isSpinning ? (
                  <SpinnerLoader loading={isSpinning} />
                ) : (
                  <img
                    onLoad={props.onImgLoad}
                    src={
                      convertIPFSToHTTPS(props.nft?.NFTImage || '') ||
                      '/assets/images/preloader.png'
                    }
                    alt={props.nft?.title}
                  />
                )}
              </span>
            </Outer>
          )}
        </div>
        <div className="nft__item_info">
          <div className="d-flex justify-content-end">
            <span>{displayPrice()}</span>
          </div>

          <div className="nft__item_action">
            {(props.nft.listingType === ListingType.AUCTION ||
              props.nft.listingType === ListingType.ENGLISH_AUCTION) && (
              <>
                {isEnded(nftDetails)
                  ? t('nft-card.label.ended')
                  : `${t('nft-card.label.end')} ${moment(toInteger(nftDetails?.startedAt) * 1000)
                      .add(Math.round(toInteger(nftDetails?.duration) / 3600), 'hours')
                      .fromNow()}`}
              </>
            )}
            &nbsp;
          </div>
          {props.showBulkCheckbox && props.nft.listingType == ListingType.NOT_LISTED && (
            <div className="de_form">
              <div className="de_checkbox" key={props.nft.hashedKey}>
                <input
                  id={props.nft.hashedKey}
                  type="checkbox"
                  defaultChecked
                  onChange={(e) =>
                    props.setEligibleTokens(
                      e,
                      props.nft.hashedKey,
                      props.nft.tokenID,
                      props.nft.contractAddress,
                    )
                  }
                />
                <label htmlFor={props.nft.hashedKey}>{'Sell NFT'}</label>
              </div>
            </div>
          )}
          <div className="spacer-10"></div>
        </div>

        {stakeInfo.isPool && (
          <div className="nft__item_price">
            {/* <Accordion icon="fa fa-clipboard-list" title={">>"}> */}

            <div className="d-flex text-primary justify-content-between">
              <span className="text-primary">
                {t('APR')}&nbsp;
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="tooltip-1">{getAPRToolTip()}</Tooltip>}
                >
                  <i className="fa fa-question-circle"></i>
                </OverlayTrigger>
              </span>
              <span className="text-secondary">{stakeInfo.apr}%</span>
            </div>
            <div className="d-flex text-primary justify-content-between">
              <span className="text-primary">{t('Scores')}</span>
              <span className="text-secondary">{stakeInfo.score}</span>
            </div>
            {props.isMyNft && (
              <>
                <div className="d-flex text-primary justify-content-between">
                  <span className="text-primary">
                    {t('Staked?')}&nbsp;
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="tooltip-1">{getStakeOrNotToolTip()}</Tooltip>}
                    >
                      <i className="fa fa-question-circle"></i>
                    </OverlayTrigger>
                  </span>
                  <span className={stakeInfo.isStaked ? 'text-success' : 'text-warning'}>
                    {stakeInfo.isStaked ? 'Yes' : 'No'}
                  </span>
                </div>
                <div className="d-flex text-primary justify-content-between">
                  <span className="text-primary">
                    {t('Earned')}&nbsp;
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="tooltip-1">{'Earn in XWIN Token'}</Tooltip>}
                    >
                      <i className="fa fa-question-circle"></i>
                    </OverlayTrigger>
                  </span>
                  <span className="text-secondary">{stakeInfo.pendingEarn}</span>
                </div>
                <div className="spacer-10"></div>
                <div className="d-flex justify-content-end">
                  <button
                    className="btn-small-primary"
                    onClick={handleHarvest}
                    disabled={!stakeInfo.isEligible}
                  >
                    {t('Harvest')}
                  </button>
                  {!stakeInfo.isStaked && (
                    <button
                      className="btn-small-secondary"
                      onClick={handleStake}
                      disabled={stakeInfo.isStaked || !stakeInfo.isEligible}
                    >
                      {t('Stake')}
                    </button>
                  )}
                </div>
              </>
            )}
            {/* </Accordion> */}
          </div>
        )}
        {/* {stakeInfo.isPool && (
          <div className="nft__item_price">
            <Accordion icon="fa fa-clipboard-list" title={">>"}>

                <div className="d-flex text-primary justify-content-between">
                  <span className="text-primary">
                    {t("APR")}&nbsp;
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="tooltip-1">{getAPRToolTip()}</Tooltip>}
                    >
                    <i className="fa fa-question-circle"></i>
                    </OverlayTrigger>
                  </span>
                  <span>{stakeInfo.apr}%</span>
                </div>
              <div className="d-flex text-primary justify-content-between">
                <span className="text-primary">{t("Scores")}</span>
                <span>{stakeInfo.score}</span>
              </div>


                  <div className="d-flex text-primary justify-content-between">
                    <span className="text-primary">{t("Staked?")}&nbsp;
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="tooltip-1">{getStakeOrNotToolTip()}</Tooltip>}
                    >
                    <i className="fa fa-question-circle"></i>
                    </OverlayTrigger>
                    </span>
                    <span className={stakeInfo.isStaked ? "text-success" : "text-warning"}>{stakeInfo.isStaked ? "Yes" : "No"}</span>
                  </div>

              {props.isMyNft && (
                <>
              <div className="d-flex text-primary justify-content-between">
                <span className="text-primary">{t("Earned")}&nbsp;
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="tooltip-1">{"Earn in XWIN Token"}</Tooltip>}
                    >
                    <i className="fa fa-question-circle"></i>
                    </OverlayTrigger>
                </span>
                <span>{stakeInfo.pendingEarn}</span>
              </div>
                <div className="spacer-10"></div>
                <div className="d-flex justify-content-end">
                  <button
                    className="btn-small-primary"
                    onClick={handleHarvest}
                    disabled={!stakeInfo.isEligible}
                  >
                    {t("Harvest")}
                  </button>
                  {!stakeInfo.isStaked && (
                      <button
                        className="btn-small-secondary"
                        onClick={handleStake}
                        disabled={stakeInfo.isStaked || !stakeInfo.isEligible}
                        >
                        {t("Stake")}
                    </button>
                  )}
                </div>
                </>
                )}
            </Accordion>
          </div>
        )} */}
        {/* {!stakeInfo.isPool && (
          <div className="nft__item_price">
            <Accordion icon="fa fa-clipboard-list" title={t("No Staking")}>
            </Accordion>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default NftCard;
