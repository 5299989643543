import React, { useEffect, SetStateAction, Dispatch } from 'react';
import { THEMES } from './Theme.config';
import { ThemeType, Theme } from './Theme.model';

interface ThemeContextProps {
  themeType: ThemeType;
  theme: Theme;
  setCurrentTheme: Dispatch<SetStateAction<ThemeType>>;
}

const ThemeContext = React.createContext<ThemeContextProps>({
  themeType: 'dark',
  theme: THEMES['dark'],
} as ThemeContextProps);

export const ThemeProvider: React.FC = ({ children }) => {
  const [currentTheme, setCurrentTheme] = React.useState<ThemeType>('dark');

  useEffect(() => {
    if (localStorage.getItem('mytheme') == 'light') {
      setCurrentTheme('light');
    } else {
      setCurrentTheme('dark');
    }
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        themeType: currentTheme,
        theme: THEMES[currentTheme],
        setCurrentTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => React.useContext(ThemeContext);
