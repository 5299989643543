import dotenv from 'dotenv';
dotenv.config();

export const CommonRoutes = {
  MARKETPLACE: '/marketplace',
  MY_NFT: '/my-nfts',
  CREATE_NFT: '/create-nft',
  HOME: '/',
  MYSTERY_BOX: '/mystery-box',
  MY_COLLECTIONS: '/collections',
  ALL_COLLECTIONS: '/allcollections',
  COLLECTION: '/collection',
};

export enum ContractType {
  Dutch = 'dutch',
  English = 'english',
  Fixed = 'fixed',
}

export enum EventHistoryType {
  AcceptOffer = 'acceptOffer',
  CancelAuction = 'cancelAuction',
  CreateAuction = 'createAuction',
  List = 'list',
  Mint = 'mint',
  Offer = 'offer',
  Sales = 'sales',
  Sale = 'sale',
  Bid = 'bid',
  Redeem = 'redeem',
  RejectOffer = 'rejectOffer',
  Unlist = 'unlist',
}

export enum Chains {
  BSC = 'bsc',
  Polygon = 'polygon',
}

export enum Language {
  English = 'en-US',
  Japanese = 'ja-JP',
}

export enum ListingType {
  AUCTION = 'AUCTION',
  MARKETPLACE = 'MARKETPLACE',
  ENGLISH_AUCTION = 'ENGLISH_AUCTION',
  NOT_LISTED = 'NOT_LISTED',
  NOTVALID = 'NOTVALID',
}

export enum LandingPageType {
  TOP = 'TOP',
  TRENDING = 'TRENDING',
  FEATURED_ARTISTS = 'FEATURED_ARTISTS',
}

export enum NftType {
  Audio = 'Audio',
  Image = 'Image',
  Video = 'Video',
}

export enum MintType {
  Category = 'Category',
  Collection = 'Collection',
}

export enum MarketPlacePageType {
  COLLECTION = 'COLLECTION',
  CATEGORY = 'CATEGORY',
  USER = 'USER',
  OWN = 'OWN',
  MARKETPLACE = 'MARKETPLACE',
}

export enum SortDirection {
  Ascending = 'Ascending',
  Descending = 'Descending',
}

export enum UploadEntity {
  USER_PROFILE_IMAGE = 'USER_PROFILE_IMAGE',
  USER_BANNER_IMAGE = 'USER_BANNER_IMAGE',
  COLLECTIONS_LOGO = 'COLLECTIONS_LOGO',
  COLLECTIONS_BANNER = 'COLLECTIONS_BANNER',
  NFT_META = 'NFT_META',
}

export const IMAGE_HTTP = 'https://ipfs.io/ipfs';
export const IPFS_HTTP = 'https://ipfs.io/';

export const PAGE_SIZE = 16;
export const DefaultPaginationData = {
  showing: 0,
  currentPage: 1,
  nextPage: null,
  pageSize: PAGE_SIZE,
  pageCount: 1,
  totalCount: 0,
};

export const toppyRedeem = process.env.REACT_APP_TOPPY_REDEEM_ADDRESS as string;
export const toppyMarketPlace = process.env.REACT_APP_TOPPY_MARKETPLACE_ADDRESS as string;
export const toppyMint = process.env.REACT_APP_TOPPY_MINT_ADDRESS as string;
export const toppyStandardNFT = process.env.REACT_APP_TOPPY_STANDARD_NFT_ADDRESS as string;

export const toppyStaking = process.env.REACT_APP_TOPPY_STAKING_ADDRESS as string;

export const toppyEventHistory = process.env.REACT_APP_TOPPY_EVENT_HISTORY_ADDRESS as string;

export const toppyMaster = process.env.REACT_APP_TOPPY_MASTER_ADDRESS as string;
export const moralisXWinAddress = process.env.REACT_APP_MORALIS_XWIN_ADDRESS as string;
export const xwinaddress = process.env.REACT_APP_XWIN_ADDRESS as string;
export const bnbaddress = process.env.REACT_APP_BNB_ADDRESS as string;
export const wbnbaddress = process.env.REACT_APP_WBNB_ADDRESS as string;

export const usdtaddress = process.env.REACT_APP_USDT_ADDRESS as string;
