import { ColorRGB } from 'react-countdown-circle-timer/lib/types';

export type ThemeType = 'light' | 'dark';

export interface Theme {
  '--background': Color;
  '--primary': Color;
  '--button': Color;
  '--button2': Color;
  '--text': Color;
  '--text2': Color;
  '--border': Color;
  '--nft-attr': Color;
  '--nft-attr-text': Color;
  '--inputs': Color;
  '--footer': Color;
  '--footer-text': Color;
  '--footer-bg': Color;
  '--navbar': Color;
  '--navbar-grey': Color;
  '--card': Color;
  '--cardBorder': Color;
  '--checkbox': Color;
  '--navbar_back': Color;
  '--navbar_market': Color;
  '--canvas_bg': Url;
}

export enum Color {
  WHITE = '#FFF',
  BLACK = '#212428',
  PURPLE = '#8364E2',
  BORDER_GREY = 'RGBA(255,255,255,1)',
  BORDER_BLACK = 'RGBA(0,0,0,1)',
  GREEN = '#85c324',
  BOX_GREY = 'rgba(64, 63, 131, 0.15)',
  TEXT_RED = '#f00',
  LIGHTPURPLE = '#FF66FF',
  FOOTER = 'rgba(255, 255, 255, 0.025)',
  NAVBAR_BLACK = 'rgba(0,0,0,.15)',
  NAVBAR_GREY = '#D0D0D0',
  CARD = 'hsla(0,0%,100%,.025)',
  CARD_BORDER = 'hsla(0,0%,100%,.075)',
  CHECKBOX_FILTER = 'hsla(0,0%,100%,.2)',
  NONE = 'none',
  // MARKET_NAVBAR= "rgb(33, 36, 40)",
  MARKET_NAVBAR = 'rgb(33, 36, 40)',
  CANVAS_1 = 'src/assets/8.jpg',
}

export enum Url {
  LIGHT = 'https://toppy.mypinata.cloud/ipfs/bafybeig5naqlkhzhdhkiozlrk3sbmbuenv6mlieexfvh2mn2m72qewl4ny/Jolly_Walker.jpg',
}
