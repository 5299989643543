import React, { useEffect, useContext, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Language, Chains } from 'src/constant';
import { CommonRoutes } from '../../constant';
import xWinLogo from '../../assets/images/xWinLogo.png';
import UserContext from '../../contexts/UserContext';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'src/contexts/AppContext';
import { toast } from 'react-toastify';
import { convertIPFSToHTTPS, getAddressShort, getTokenSymbol } from '../../utils/helper';
import { useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Breakpoint, BreakpointProvider, setDefaultBreakpoints } from 'react-socks';
import useOnclickOutside from 'react-cool-onclickoutside';
import ThemeSwitcher from '../ThemeSwitcher';
import { ThemeProvider, useTheme } from 'src/contexts/ThemeContext';
import BinanceColorLogo from '../../assets/images/Blockchains/binanceColor.png';
import PolygonColorLogo from '../../assets/images/Blockchains/polygonColor.png';
import systemConfig from 'src/configs/systemConfig';

setDefaultBreakpoints([{ xs: 0 }, { l: 1199 }, { xl: 1200 }]);

type Props = {
  onStart: () => void;
  logout: () => void;
};

const Header = ({ onStart, logout }: Props) => {
  const { setCurrentTheme, themeType } = useTheme();
  const [darkTheme, setDarkTheme] = useState(themeType == 'dark');
  const { i18n } = useTranslation(['enum']);
  const { t } = useTranslation(['component']);

  const chainOptions = {
    chains: Object.keys(Chains).map((chain) => ({
      label: chain,
      value: Chains[chain],
    })),
  };

  const options = {
    languages: Object.keys(Language).map((language) => ({
      label: t(`enum:Language.${language}`),
      value: Language[language],
    })),
  };
  const initial = options.languages
    .filter((language) => language.value === (i18n.language ?? Language.English))
    .pop();
  const [value, setValue] = React.useState(initial?.value);
  const events = {
    onChangeLanguage: (language) => {
      setValue(language);
      i18n.changeLanguage(language);
    },
    onChangeNetwork: (chain) => {
      window.open(chain === 'bsc' ? systemConfig.binanceUrl : systemConfig.polygonUrl, '_self');
    },
  };

  const { state } = useContext(UserContext);
  const { user } = state;
  const { account, isConnected, baseBalance } = useContext(AppContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const history = useHistory();
  const { pathname } = history.location;

  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [openMenu2, setOpenMenu2] = React.useState(false);
  const [openMenu3, setOpenMenu3] = React.useState(false);
  const [openMenu4, setOpenMenu4] = React.useState(false);
  const handleBtnClick1 = () => {
    setOpenMenu1(!openMenu1);
  };
  const handleBtnClick2 = () => {
    setOpenMenu2(!openMenu1);
  };
  const handleBtnClick3 = () => {
    setOpenMenu3(!openMenu3);
  };
  const handleBtnClick4 = () => {
    setOpenMenu4(!openMenu4);
  };
  const closeMenu1 = () => {
    setOpenMenu1(false);
  };
  const closeMenu2 = () => {
    setOpenMenu2(false);
  };
  const closeMenu3 = () => {
    setOpenMenu3(false);
  };
  const closeMenu4 = () => {
    setOpenMenu4(false);
  };
  const ref1 = useOnclickOutside(() => {
    closeMenu1();
  });
  const ref2 = useOnclickOutside(() => {
    closeMenu2();
  });
  const ref3 = useOnclickOutside(() => {
    closeMenu3();
  });
  const ref4 = useOnclickOutside(() => {
    closeMenu4();
  });
  const [showmenu, btn_icon] = useState(false);
  const [showpop, btn_icon_pop] = useState(false);
  const [shownot, btn_icon_not] = useState(false);
  const closePop = () => {
    btn_icon_pop(false);
  };
  const closeNot = () => {
    btn_icon_not(false);
  };
  const refpop = useOnclickOutside(() => {
    closePop();
  });
  const refpopnot = useOnclickOutside(() => {
    closeNot();
  });

  const saveTheme = (mytheme) => {
    localStorage.setItem('mytheme', mytheme);
    setCurrentTheme(mytheme);
  };

  useEffect(() => {
    const header = document.getElementById('myHeader');
    const totop = document.getElementById('scroll-to-top');
    const sticky = header?.offsetTop as number;
    const scrollCallBack = window.addEventListener('scroll', () => {
      btn_icon(false);
      if (window.pageYOffset > sticky) {
        header?.classList.add('sticky');
        totop?.classList.add('show');
      } else {
        header?.classList.remove('sticky');
        totop?.classList.remove('show');
      }
      if (window.pageYOffset > sticky) {
        closeMenu1();
      }
    }) as any;
    return () => {
      window.removeEventListener('scroll', scrollCallBack);
    };
  }, []);

  const onThemeClick = (isDark) => {
    setDarkTheme(isDark);
    saveTheme(isDark ? 'dark' : 'light');
  };

  const getChainLogo = () => {
    if (systemConfig.chainId.toString() == '97' || systemConfig.chainId.toString() == '56') {
      return <img src={BinanceColorLogo} alt="BSC" title="BSC" />;
    } else {
      return <img src={PolygonColorLogo} alt="Polygon" title="Polygon" />;
    }
  };

  return (
    <header id="myHeader" className="navbar white">
      <div className="container">
        <div className="row w-100-nav">
          <div className="logo px-0">
            <div className="navbar-title navbar-item">
              <NavLink to="/">
                <img src="/assets/images/logo.svg" className="img-fluid d-block" alt="#" />
                <img src="/assets/images/logo.svg" className="img-fluid d-3" alt="#" />
                <img src="/assets/images/logo.svg" className="img-fluid d-4" alt="#" />
                <img src="/assets/images/logo.svg" className="img-fluid d-none" alt="#" />
              </NavLink>
            </div>
          </div>

          <BreakpointProvider>
            <Breakpoint l down>
              {showmenu && (
                <div className="menu">
                  <div className="navbar-item">{getChainLogo()}</div>
                  <div className="navbar-item">
                    <div ref={ref4}>
                      <div
                        className="dropdown-custom dropdown-toggle btn"
                        onClick={handleBtnClick4}
                      >
                        {t('Explore')}
                      </div>
                      {openMenu4 && (
                        <div className="item-dropdown">
                          <div className="dropdown" onClick={closeMenu4}>
                            <NavLink to={CommonRoutes.MARKETPLACE}>
                              <i className="fa fa-cart-plus"></i>&nbsp; {t('Categories')}
                            </NavLink>
                            <NavLink to={CommonRoutes.ALL_COLLECTIONS}>
                              <i className="fa fa-align-left"></i>&nbsp; {t('Collections')}
                            </NavLink>
                            <NavLink to={CommonRoutes.MYSTERY_BOX}>
                              <i className="fa fa-archive"></i>&nbsp; {t('Mystery Box')}
                            </NavLink>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="navbar-item">
                    <NavLink to={CommonRoutes.CREATE_NFT} onClick={() => btn_icon(!showmenu)}>
                      {t('dashboard-header.navigation.create-nft')}
                    </NavLink>
                  </div>
                  {user && isConnected && (
                    <div className="navbar-item">
                      <div ref={ref1}>
                        <div
                          className="dropdown-custom dropdown-toggle btn"
                          onClick={handleBtnClick1}
                        >
                          {t('My Menu')}
                        </div>
                        {openMenu1 && (
                          <div className="item-dropdown">
                            <div className="dropdown" onClick={closeMenu1}>
                              <NavLink to="/my-nfts">
                                <i className="fa fa-file-image-o"></i>&nbsp;{' '}
                                {t('dashboard-header.navigation.my-nfts')}
                              </NavLink>
                              <NavLink to={CommonRoutes.MY_COLLECTIONS}>
                                <i className="fa fa-align-left"></i>
                                &nbsp; {t('dashboard-header.navigation.my-collection')}
                              </NavLink>
                              <NavLink to="/profile">
                                <i className="fa fa-user"></i> &nbsp;
                                {t('dashboard-header.navigation.profile')}
                              </NavLink>
                              <NavLink to="/withdrawal">
                                <i className="fa fa-credit-card"></i> &nbsp;
                                {t('dashboard-header.navigation.refund')}
                              </NavLink>
                              <NavLink to="/mysales">
                                <i className="fa fa-area-chart"></i>&nbsp;{' '}
                                {t('dashboard-header.navigation.mysales')}
                              </NavLink>
                              {/* <NavLink to="/import">
                                <i className="fa fa-upload"></i>&nbsp;{" "}
                                {t("dashboard-header.navigation.import")}
                              </NavLink> */}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="navbar-item">
                    <div ref={ref2}>
                      <div
                        className="dropdown-custom dropdown-toggle btn"
                        onClick={handleBtnClick2}
                      >
                        Language
                      </div>
                      {openMenu2 && (
                        <div className="item-dropdown">
                          <div className="dropdown" onClick={closeMenu2}>
                            {options.languages.map((language) => (
                              <NavLink
                                key={language.label}
                                to="#"
                                onClick={(event) =>
                                  events.onChangeLanguage(language.value as string)
                                }
                              >
                                {language.label}
                              </NavLink>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="navbar-item">
                    <div ref={ref2}>
                      <div
                        className="dropdown-custom dropdown-toggle btn"
                        onClick={handleBtnClick3}
                      >
                        Network
                      </div>
                      {openMenu3 && (
                        <div className="item-dropdown">
                          <div className="dropdown" onClick={closeMenu3}>
                            {chainOptions.chains.map((chain) => (
                              <NavLink
                                to="#"
                                onClick={(event) => events.onChangeNetwork(chain.value as string)}
                              >
                                {chain.label}
                              </NavLink>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="navbar-item">
                    <NavLink to="#" onClick={() => onThemeClick(!darkTheme)}>
                      <i className={darkTheme ? 'fa fa-sun-o' : 'fa fa-moon-o'}></i>
                      &nbsp;
                      {darkTheme ? t('Light') : t('Dark')}
                      <span className="lines"></span>
                    </NavLink>
                  </div>
                  <div></div>
                </div>
              )}
            </Breakpoint>

            <Breakpoint xl>
              <div className="menu">
                {/* <div className="navbar-item">
                  <NavLink to={CommonRoutes.MYSTERY_BOX}>
                    {t("Mystery Box")}
                    <span className="lines"></span>
                  </NavLink>
                </div> */}
                {/* <div className="navbar-item">
                  <NavLink to={CommonRoutes.MARKETPLACE}>
                    {t("dashboard-header.navigation.marketplace")}
                    <span className="lines"></span>
                  </NavLink>
                </div> */}
                <div className="navbar-item">
                  <div ref={ref4}>
                    <div
                      className="dropdown-custom dropdown-toggle btn"
                      onMouseEnter={handleBtnClick4}
                      onMouseLeave={closeMenu4}
                    >
                      Explore
                      <span className="lines"></span>
                      {openMenu4 && (
                        <div className="item-dropdown">
                          <div className="dropdown" onClick={closeMenu4}>
                            <NavLink to={CommonRoutes.MARKETPLACE}>
                              <i className="fa fa-cart-plus"></i>&nbsp; {t('Categories')}
                            </NavLink>
                            <NavLink to={CommonRoutes.ALL_COLLECTIONS}>
                              <i className="fa fa-align-left"></i>&nbsp; {t('Collections')}
                            </NavLink>
                            <NavLink to={CommonRoutes.MYSTERY_BOX}>
                              <i className="fa fa-archive"></i>&nbsp; {t('Mystery Box')}
                            </NavLink>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="navbar-item">
                  <NavLink to={CommonRoutes.CREATE_NFT}>
                    {t('dashboard-header.navigation.create-nft')}
                    <span className="lines"></span>
                  </NavLink>
                </div>
                {user && isConnected && (
                  <div className="navbar-item">
                    <div ref={ref1}>
                      <div
                        className="dropdown-custom dropdown-toggle btn"
                        onMouseEnter={handleBtnClick1}
                        onMouseLeave={closeMenu1}
                      >
                        {t('My Menu')}
                        <span className="lines"></span>
                        {openMenu1 && (
                          <div className="item-dropdown">
                            <div className="dropdown" onClick={closeMenu1}>
                              <NavLink to="/my-nfts">
                                <i className="fa fa-file-image-o"></i>&nbsp;{' '}
                                {t('dashboard-header.navigation.my-nfts')}
                              </NavLink>
                              <NavLink to={CommonRoutes.MY_COLLECTIONS}>
                                <i className="fa fa-align-left"></i>&nbsp;{' '}
                                {t('dashboard-header.navigation.my-collection')}
                              </NavLink>
                              <NavLink to="/profile">
                                <i className="fa fa-user"></i> &nbsp;
                                {t('dashboard-header.navigation.profile')}
                              </NavLink>
                              <NavLink to="/withdrawal">
                                <i className="fa fa-credit-card"></i> &nbsp;
                                {t('dashboard-header.navigation.refund')}
                              </NavLink>
                              <NavLink to="/mysales">
                                <i className="fa fa-area-chart"></i>&nbsp;{' '}
                                {t('dashboard-header.navigation.mysales')}
                              </NavLink>
                              {/* <NavLink to="/import">
                                <i className="fa fa-upload"></i>&nbsp;{" "}
                                {t("dashboard-header.navigation.import")}
                              </NavLink> */}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="navbar-item">
                  <div ref={ref2}>
                    <div
                      className="dropdown-custom dropdown-toggle btn"
                      onMouseEnter={handleBtnClick2}
                      onMouseLeave={closeMenu2}
                    >
                      Language
                      <span className="lines"></span>
                      {openMenu2 && (
                        <div className="item-dropdown">
                          <div className="dropdown" onClick={closeMenu2}>
                            {options.languages.map((language) => (
                              <NavLink
                                key={language.label}
                                to="#"
                                onClick={(event) =>
                                  events.onChangeLanguage(language.value as string)
                                }
                              >
                                {language.label}
                              </NavLink>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="navbar-item">
                  <NavLink to="#" onClick={() => onThemeClick(!darkTheme)}>
                    <i
                      className={
                        darkTheme ? 'fa fa-sun-o font-weight-bold' : 'fa fa-moon-o font-weight-bold'
                      }
                    ></i>
                    &nbsp;
                    {darkTheme ? t('Light') : t('Dark')}
                    <span className="lines"></span>
                  </NavLink>
                </div>
                <div className="navbar-item">
                  <div ref={ref2}>
                    {/* {getChainLogo()}
                  &nbsp; */}
                    <div
                      className="dropdown-custom dropdown-toggle btn"
                      onMouseEnter={handleBtnClick3}
                      onMouseLeave={closeMenu3}
                    >
                      {getChainLogo()}
                      {/* <span className="lines"></span> */}
                      {openMenu3 && (
                        <div className="item-dropdown">
                          <div className="dropdown" onClick={closeMenu3}>
                            {chainOptions.chains.map((chain) => (
                              <NavLink
                                to="#"
                                onClick={(event) => events.onChangeNetwork(chain.value as string)}
                              >
                                {chain.label}
                              </NavLink>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Breakpoint>
          </BreakpointProvider>

          <div className="mainside">
            {(isConnected == undefined || !isConnected || !user) && (
              <div className="connect-wal">
                <a>
                  <span onClick={onStart}>{t('dashboard-header.button.start')}</span>
                </a>
              </div>
            )}

            {user && isConnected && (
              <div
                id="de-click-menu-profile"
                className="de-menu-profile"
                onClick={() => btn_icon_pop(!showpop)}
                ref={refpop}
              >
                <img src={convertIPFSToHTTPS(user?.profileImage || '') || xWinLogo} alt="" />
                {showpop && (
                  <div className="popshow">
                    <div className="d-name">
                      <h4>{user?.username}</h4>
                    </div>
                    <div className="d-balance">
                      <h4>Balance</h4>
                      {`${baseBalance} ${getTokenSymbol()}`}
                    </div>
                    <div className="d-wallet">
                      <h4>My Wallet</h4>
                      <span id="wallet" className="d-wallet-address">
                        {getAddressShort(account)}
                      </span>
                      <CopyToClipboard
                        onCopy={() =>
                          toast('🦄 Copied!', {
                            position: 'bottom-center',
                            autoClose: 1000,
                          })
                        }
                        text={account}
                      >
                        <button id="btn_copy" title="Copy Text">
                          Copy
                        </button>
                      </CopyToClipboard>
                    </div>
                    <div className="d-line"></div>
                    <ul className="de-submenu-profile">
                      <li>
                        <span onClick={() => logout()}>
                          <i className="fa fa-sign-out"></i>{' '}
                          {t('dashboard-header.navigation.logout')}
                        </span>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
          <div className="menu-line white"></div>
          <div className="menu-line1 white"></div>
          <div className="menu-line2 white"></div>
        </button>
      </div>
    </header>
  );
};

export default Header;
