import { AxiosResponse } from 'axios';
import systemConfig from '../configs/systemConfig';
import http from './httpService';
import { Meta } from '../typings/api';
import { ListingType, PAGE_SIZE } from '../constant';

const nftCategoriesEndpoint = `${systemConfig.apiUrl}/api/toppy/nft-categories`;
const nftMetaEndpoint = `${systemConfig.apiUrl}/api/toppy/nft-meta`;
const importCustomContract = `${systemConfig.apiUrl}/api/toppy/imported-contract`;
const importSingleNFT = `${systemConfig.apiUrl}/api/toppy/nft-meta/import-single-nft`;
const createReportFakeNFTEndpoint = `${systemConfig.apiUrl}/api/toppy/report-fake-nft`;

export const getMetaByTokenId = (id: string): Promise<Meta.Response> => {
  return http.get(`${nftMetaEndpoint}/${id}`);
};

export const getMetaByHashedKey = (hashedKey: string): Promise<Meta.Response> => {
  return http.get(`${nftMetaEndpoint}/hashed-key/${hashedKey}`);
};

export const createMeta = (meta) => {
  return http.post(nftMetaEndpoint, meta);
};

export const createReportFakeNft = (details) => {
  return http.post(createReportFakeNFTEndpoint, details);
};

export const updateMeta = (
  hashedKey: string,
  meta: {
    paymentType?: string | null;
    listingType: ListingType;
    price?: string | null;
    priceType?: string | null;
    tokenPayment?: string | null;
    duration?: string | null;
    reservePrice?: string | null;
    auctionId?: string | null;
    startedAt?: string | null;
    endingPrice?: string | null;
    startingPrice?: string | null;
    ownerAddress?: string | null;
    userCollectionId?: string | null;
  },
) => {
  return http.patch(`${nftMetaEndpoint}/${hashedKey}`, meta);
};

export const getPaginatedNftMeta = (
  tokenIds: string[] = [],
  categories: string[] = [],
  paymentTypes: string[] = [],
  listingType: ListingType[] = [],
  ownerAddress?: string | null,
  page = 1,
  perPage = PAGE_SIZE,
  keyWord?: string | null,
  collectioName?: string | null,
): Promise<AxiosResponse> => {
  return http.get(nftMetaEndpoint, {
    params: {
      page,
      perPage,
      tokenIds,
      categories,
      listingType,
      paymentTypes,
      ownerAddress,
      keyWord,
      collection: collectioName,
      chainId: systemConfig.chainId.toString(),
    },
  });
};

export const getNftCategoriesList = (page = 1, perPage = 100) => {
  return http.get(nftCategoriesEndpoint, {
    params: {
      chainId: systemConfig.chainId.toString(),
      page,
      perPage,
      active: true,
    },
  });
};

export const saveImportCustomContract = (body: Nft.ImportCustomContract) => {
  return http.post(importCustomContract, body);
};

export const saveImportSingleNFT = (body: Nft.ImportCustomSingleNFT) => {
  return http.post(importSingleNFT, body);
};

export async function getRarityJson(rarityURL: string) {
  const response = await fetch(rarityURL);
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  const data = await response.json();
  return data;
}

export const countNftByOwner = (ownerAddress: string) => {
  return http.get(`${nftMetaEndpoint}/nft-count/${systemConfig.chainId}/${ownerAddress}`);
};
