import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import xWinLogo from '../assets/images/xWinLogo.png';
import DefaultBanner from '../assets/images/defaultBanner.png';
import { useTranslation } from 'react-i18next';
import Avatar from 'react-avatar';
import { NavLink } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { CommonRoutes } from 'src/constant';

const GlobalStyles = createGlobalStyle`
.greyscheme .nft__item img[class="token_icon"] {
  height: 1.75rem !important;
  width: 1.75rem !important;
}
`;

type Props = {
  collection: Collection.MyCollection;
};

const MyCollection = (prop: Props) => {
  const { t } = useTranslation(['collection', 'page.marketplace']);
  const { collection } = prop;
  const history = useHistory();

  return (
    <div className="d-item col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
      <GlobalStyles />
      <div
        className={classNames({
          'nft__item p-3': true,
        })}
      >
        <section className="jumbotron breadcumb no-bg">
          <img src={collection?.banner || DefaultBanner} className="bannar-image-small" />
        </section>
        <section className="container d_coll no-bottom no-top">
          <div
            role="button"
            onClick={() => history.push(`${CommonRoutes.COLLECTION}/${collection.name}`)}
            className="profile_avatar"
          >
            <div className="d_profile_img">
              {collection?.logo !== '' && (
                <img src={collection?.logo || xWinLogo} alt="" className="bannar-image-small" />
              )}
            </div>
          </div>
        </section>

        {/* <div className="spacer-single"></div> */}
        <div className="d-flex flex-column align-items-center">
          <h3>
            <span className="store-label">{collection?.name}</span>
          </h3>
          <div>
            {`${t('owned-by')}: `}
            {collection?.user?.username}
          </div>

          <div className="item_filter_group">
            <div className="row text-center text-nowrap">
              <div className="col">
                {t('Items')}
                <h4>{collection?.nftCount || 0}</h4>
              </div>

              <div className="col">
                {t('Owners')}
                <h4>{collection?.ownerCount || 0}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyCollection;
