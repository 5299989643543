import { Color, ThemeType, Theme, Url } from './Theme.model';

export const THEMES: Record<ThemeType, Theme> = {
  light: {
    '--primary': Color.WHITE,
    '--background': Color.WHITE,
    '--button': Color.PURPLE,
    '--button2': Color.LIGHTPURPLE,
    '--text': Color.BLACK,
    '--text2': Color.WHITE,
    '--border': Color.BORDER_BLACK,
    '--nft-attr': Color.BOX_GREY,
    '--nft-attr-text': Color.PURPLE,
    '--inputs': Color.BORDER_GREY,
    '--footer': Color.WHITE,
    '--footer-text': Color.BLACK,
    '--footer-bg': Color.NAVBAR_GREY,
    '--navbar': Color.WHITE,
    '--navbar-grey': Color.NAVBAR_GREY,
    '--card': Color.WHITE,
    '--cardBorder': Color.BORDER_BLACK,
    '--checkbox': Color.BLACK,
    '--navbar_back': Color.WHITE,
    '--navbar_market': Color.NAVBAR_GREY,
    '--canvas_bg': Url.LIGHT,
  },
  dark: {
    '--primary': Color.BLACK,
    '--background': Color.BLACK,
    '--button': Color.GREEN,
    '--button2': Color.TEXT_RED,
    '--text': Color.WHITE,
    '--text2': Color.WHITE,
    '--border': Color.BORDER_GREY,
    '--nft-attr': Color.BLACK,
    '--nft-attr-text': Color.TEXT_RED,
    '--inputs': Color.BORDER_BLACK,
    '--footer': Color.FOOTER,
    '--footer-text': Color.NAVBAR_GREY,
    '--footer-bg': Color.FOOTER,
    '--navbar': Color.NAVBAR_BLACK,
    '--navbar-grey': Color.BLACK,
    '--card': Color.CARD,
    '--cardBorder': Color.CARD_BORDER,
    '--checkbox': Color.CHECKBOX_FILTER,
    '--navbar_back': Color.NONE,
    '--navbar_market': Color.MARKET_NAVBAR,
    '--canvas_bg': Url.LIGHT,
  },
};
