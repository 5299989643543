import Web3 from 'web3';
import { loadDynamicERC721 } from './blockchainInteractor';
import { toppyMarketPlace, toppyRedeem } from '../constant';
import { getGasPrice } from 'src/utils/helper';

export async function getTotalItems(web3: Web3, nftaddress: string) {
  const contract = loadDynamicERC721(web3, nftaddress);
  try {
    return await contract.methods.totalSupply().call();
  } catch (e) {
    return null;
  }
}

export async function setApprovalForAll(account: string, web3: Web3, nftaddress: string) {
  const contract = loadDynamicERC721(web3, nftaddress);
  try {
    const latestGasPrice:any = await getGasPrice(web3);
    await contract.methods.setApprovalForAll(toppyMarketPlace, true).send({
      from: account,
      value: 0,
      ...latestGasPrice
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function approveToken(account: string, web3: Web3, id: string, nftaddress: string) {
  const contract = loadDynamicERC721(web3, nftaddress);

  try {
    const latestGasPrice:any = await getGasPrice(web3);
    await contract.methods.approve(toppyMarketPlace, id).send({
      from: account,
      value: 0,
      ...latestGasPrice
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function approveNFTForRedeem(
  account: string,
  web3: Web3,
  id: string,
  nftaddress: string,
) {
  const contract = loadDynamicERC721(web3, nftaddress);

  try {
    const latestGasPrice:any = await getGasPrice(web3);
    await contract.methods.approve(toppyRedeem, id).send({
      from: account,
      value: 0,
      ...latestGasPrice
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function checkIfApproved(web3: Web3, tokenId, nftaddress: string) {
  const contract = loadDynamicERC721(web3, nftaddress);
  const address = await contract.methods.getApproved(tokenId).call();
  return address === toppyMarketPlace;
}

export async function checkIfApprovedRedeem(web3: Web3, tokenId, nftaddress: string) {
  const contract = loadDynamicERC721(web3, nftaddress);
  const address = await contract.methods.getApproved(tokenId).call();
  return address === toppyRedeem;
}

export async function isApprovedForAll(web3: Web3, account: string, nftaddress: string) {
  const contract = loadDynamicERC721(web3, nftaddress);
  return await contract.methods.isApprovedForAll(account, toppyMarketPlace).call();
}
