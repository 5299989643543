import React from 'react';
import classnames from 'classnames';
import { useAsyncDebounce, useFlexLayout, useSortBy, useTable } from 'react-table';
import { useTranslation } from 'react-i18next';

// import Loader from './Loader';
// import Pagination from './Pagination';
import BlankCanvas from './illustrations/BlankCanvas';
import { useDidUpdateEffect } from '../utils/hooks';
import { SortDirection } from 'src/constant';

// eslint-disable-next-line @typescript-eslint/ban-types
type Column<Data extends object = {}> = {
  key: string;
  accessor?: (row: Data, index: number) => void | string;
  className?: string;
  cell?: ({ cell, row }) => JSX.Element;
  flex?: {
    align?:
      | 'center'
      | 'start'
      | 'end'
      | 'flex-start'
      | 'flex-end'
      | 'left'
      | 'right'
      | 'normal'
      | 'space-between'
      | 'space-around'
      | 'space-evenly'
      | 'stretch';
    basis?: string;
    grow?: number;
    justify?:
      | 'center'
      | 'start'
      | 'end'
      | 'flex-start'
      | 'flex-end'
      | 'left'
      | 'right'
      | 'normal'
      | 'space-between'
      | 'space-around'
      | 'space-evenly'
      | 'stretch';
    shrink?: number;
  };
  header?: string;
  sortable?: boolean;
  sort?: string; // sorting key if differ from the `key` value
  width?: {
    max?: number;
    min?: number;
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Datum = { [key: string]: any };

// eslint-disable-next-line @typescript-eslint/ban-types
type Props<Data extends object = {}> = {
  busy?: boolean;
  columns: Column<Data>[];
  data: readonly Datum[] | Datum[];
  debounce?: number;
  empty?: {
    cta?: React.ReactElement;
    message?: string;
    subject?: string;
  };
  onPageChange?: (page: number, offset: number, cursor: string) => void;
  onRowClick?: (index: number, row: Data) => void;
  onSort?: (sort: string, direction: SortDirection) => void;
  sorted?: {
    by: string;
    direction: SortDirection;
  };
};

const Table: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation('component');

  const defaults = {
    columns: React.useMemo(
      () => ({
        maxWidth: 200,
        minWidth: 0,
      }),
      [],
    ),
  };

  const sortable = !!props.columns.find((column) => column.sortable !== false);

  const columns = React.useMemo(
    () =>
      props.columns.map((column) => ({
        ...(column.cell ? { Cell: column.cell } : {}),
        ...(column.header ? { Header: column.header } : {}),
        ...(column.width?.max ? { maxWidth: column.width.max } : {}),
        ...(column.width?.min ? { minWidth: column.width.min } : {}),
        accessor: column.accessor ?? column.key,
        className: column.className,
        defaultCanSort: column.sortable,
        disableSortBy: column.sortable === false,
        disableSortRemove: true,
        flex: column.flex,
        id: column.key,
      })),
    [props.columns],
  );

  const data = React.useMemo(() => props.data, [props.data]);
  const table = useTable(
    {
      columns,
      data: data as Datum[],
      initialState: {
        ...(props.sorted
          ? {
              sortBy: [
                {
                  id: props.sorted.by,
                  desc: props.sorted.direction === SortDirection.Descending,
                },
              ],
            }
          : {}),
      },
      autoResetSortBy: false,
      defaultColumn: defaults.columns,
      disableSortRemove: true,
      manualSortBy: true,
    },
    useFlexLayout,
    useSortBy,
  );

  const styles = {
    // eslint-disable-next-line @typescript-eslint/ban-types
    cell: (column): object => ({
      style: {
        display: 'flex',
        flex: `${column.flex?.grow ?? 1} ${column.flex?.shrink ?? 0} ${
          column.flex?.basis ?? 'auto'
        }`,
        width: '100px',
      },
    }),
  };

  const events = {
    onPageChange: useAsyncDebounce((page: number, offset: number, cursor: string): void => {
      if (props.onPageChange) {
        props.onPageChange(page, offset, cursor);
      }
    }, props.debounce),
    onRowClick: (row): void => {
      if (props.onRowClick) {
        props.onRowClick(row.index, row.original);
      }
    },
    onSort: useAsyncDebounce((by: string, direction: SortDirection): void => {
      if (props.onSort) {
        props.onSort(by, direction);
      }
    }, props.debounce),
  };

  useDidUpdateEffect(() => {
    if (sortable && table.state.sortBy.length > 0 && props.onSort) {
      const sort = table.state.sortBy[0];

      events.onSort(sort.id, sort.desc ? SortDirection.Descending : SortDirection.Ascending);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortable, table.state.sortBy]);

  return (
    <div className="is-a-table">
      <div
        {...table.getTableProps()}
        className={classnames('table', { sortable, hover: !!props.onRowClick })}
      >
        <header>
          {table.headerGroups.map((header) => (
            <div className="row no-gutters" {...header.getHeaderGroupProps()}>
              {header.headers.map((column) => (
                <div
                  className={classnames('column', column.className, {
                    sorted: column.isSorted,
                  })}
                  {...column.getHeaderProps((properties, options) => [
                    properties,
                    column.getSortByToggleProps(),
                    styles.cell(options.column),
                  ])}
                >
                  <div className={classnames('cell', column.className)}>
                    {column.render('Header')}
                  </div>

                  {column.isSorted && (
                    <span className={classnames('triangle', column.isSortedDesc ? 'down' : 'up')} />
                  )}
                </div>
              ))}
            </div>
          ))}
        </header>

        <div
          className={classnames('body', { hover: !!props.onRowClick })}
          {...table.getTableBodyProps()}
        >
          {table.rows.map((row) => {
            table.prepareRow(row);

            return (
              <div
                className="row no-gutters"
                {...row.getRowProps()}
                {...(props.onRowClick ? { onClick: (): void => events.onRowClick(row) } : {})}
              >
                {row.cells.map((cell) => (
                  <div
                    className={classnames('column', cell.column.className)}
                    {...cell.getCellProps((properties, options) => [
                      properties,
                      styles.cell(options.cell.column),
                    ])}
                  >
                    <div className={classnames('cell', cell.column.className)}>
                      {cell.render('Cell')}
                    </div>
                  </div>
                ))}
              </div>
            );
          })}

          {table.rows.length < 1 && (
            <div className="row no-gutters empty">
              <div className="col-12">
                <div className="text-center my-5">
                  <BlankCanvas />

                  <div className="text">
                    <h3>
                      {props.empty?.message ??
                        t('table.empty', {
                          subject: props.empty?.subject || t('common:subject.record'),
                        })}
                    </h3>
                    {props.empty?.cta && props.empty.cta}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Table.defaultProps = {
  busy: false,
  debounce: 500,
};

export default Table;
