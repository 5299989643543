import { getTokenIcon } from 'src/utils/helper';
import React, { useContext, useEffect, useState } from 'react';
import { convertFromWei } from 'src/utils/blockchainInteractor';
import {
  moralisGetTotalListingByAddress,
  moralisGetTotalItemByAddress,
  moralisGetUniqueUserCountByAddress,
} from 'src/utils/moralisInteractor';

import _ from 'lodash';
import { AppContext } from 'src/contexts/AppContext';
import { useTranslation } from 'react-i18next';
import { xwinaddress, bnbaddress, usdtaddress } from 'src/constant';
import { displayBalance, getPriceInUSD, getPaymentTokenDecimalsByAddress } from 'src/utils/helper';
import Accordion from '../components/Accordion';

const CollectionStats = ({ catFilters, nftCategories, allSalesHistory, allMinPrices, prices }) => {
  const { t } = useTranslation(['page.marketplace']);
  const { account, web3 } = useContext(AppContext);
  const [isloading, setIsLoading] = React.useState(true);

  const [stats, setStats] = useState({
    totalListing: 0,
    totalItems: 0,
    totalVolume: 0,
    ownerCount: 0,
    totalSaleValue: 0,
    totalSaleValueXWIN: 0,
    totalSaleValueUSDT: 0,
    floorPrice: 0,
    floorPriceXWIN: 0,
    floorPriceUSDT: 0,
  });

  useEffect(() => {
    init();
  }, [catFilters]);

  const init = async () => {
    if (catFilters.length == 0) {
      setStats({
        totalListing: 0,
        totalItems: 0,
        totalVolume: 0,
        ownerCount: 0,
        totalSaleValue: 0,
        totalSaleValueXWIN: 0,
        totalSaleValueUSDT: 0,
        floorPrice: 0,
        floorPriceXWIN: 0,
        floorPriceUSDT: 0,
      });
      return;
    }
    setIsLoading(true);
    const promiseData = await Promise.all([
      _getTotalListedItem(),
      _getTotalItem(),
      _getTotalUniqueOwners(),
    ]);

    const bnbDecimals = getPaymentTokenDecimalsByAddress(bnbaddress);
    const xwinDecimals = getPaymentTokenDecimalsByAddress(xwinaddress);
    const usdtDecimals = getPaymentTokenDecimalsByAddress(usdtaddress);
    
    const totalItemListed = promiseData[0];
    const totalItems = promiseData[1];
    const ownerCount = promiseData[2];
    const totalValueSold = getSalesAmount(bnbaddress);
    const totalValueSoldXWIN = getSalesAmount(xwinaddress);
    const totalValueSoldUSDT = getSalesAmount(usdtaddress);
    const floorPriceBNB = getFloorPrice(bnbaddress);
    const floorPriceXWIN = getFloorPrice(xwinaddress);
    const floorPriceUSDT = getFloorPrice(usdtaddress);

    setStats({
      totalListing: Number(totalItemListed),
      totalItems: Number(totalItems),
      totalVolume: 0,
      ownerCount: Number(ownerCount),
      totalSaleValue: Number(convertFromWei(totalValueSold.toString(), bnbDecimals)),
      totalSaleValueXWIN: Number(convertFromWei(totalValueSoldXWIN.toString(), xwinDecimals)),
      totalSaleValueUSDT: Number(convertFromWei(totalValueSoldUSDT.toString(), usdtDecimals)),
      floorPrice: Number(convertFromWei(floorPriceBNB.toString(), bnbDecimals)),
      floorPriceXWIN: Number(convertFromWei(floorPriceXWIN.toString(), xwinDecimals)),
      floorPriceUSDT: Number(convertFromWei(floorPriceUSDT.toString(), usdtDecimals)),
    });
    setIsLoading(false);
  };

  const _getTotalUniqueOwners = async () => {
    const promises = catFilters.map(async (c) => {
      const cataddress = nftCategories.find((x) => x.category.toLowerCase() === c.toLowerCase()).contractaddress;
      const totalunique = await moralisGetUniqueUserCountByAddress(cataddress);
      return totalunique;
    });
    const statsdata = await Promise.all(promises);
    const sum = statsdata.reduce((sum, stat) => {
      return Number(sum) + Number(stat);
    }, 0);
    return sum;
  };

  const _getTotalListedItem = async () => {
    const promises = catFilters.map(async (c) => {
      const cataddress = nftCategories.find((x) => x.category.toLowerCase() === c.toLowerCase()).contractaddress;
      const totalItemListed = await moralisGetTotalListingByAddress(cataddress);
      return parseFloat(totalItemListed);
    });
    const statsdata = await Promise.all(promises);
    const sum = statsdata.reduce((sum, stat) => {
      return Number(sum) + Number(stat);
    }, 0);
    return sum;
  };

  const _getTotalItem = async () => {
    const promises = catFilters.map(async (c) => {
      const cataddress = nftCategories.find((x) => x.category.toLowerCase() === c.toLowerCase()).contractaddress;
      const totalItems = await moralisGetTotalItemByAddress(cataddress);
      return parseFloat(totalItems);
    });
    const statsdata = await Promise.all(promises);
    const sum = statsdata.reduce((sum, stat) => {
      return Number(sum) + Number(stat);
    }, 0);
    return sum;
  };

  const getSalesAmount = (tokenPayment: string) => {
    let total = 0;
    catFilters.forEach((c) => {
      const cataddress = nftCategories.find((x) => x.category.toLowerCase() === c.toLowerCase()).contractaddress;
      const selected = allSalesHistory.find(
        (x) =>
          x.tokenPayment === tokenPayment.toLowerCase() &&
          x.nftContract.toLowerCase() === cataddress.toLowerCase(),
      );
      if (selected) {
        total = total + selected?.totalSalesBalance;
      }
    });
    return total;
  };

  const getFloorPrice = (tokenPayment: string) => {
    let smaller = 0;
    let count = 0;
    catFilters.forEach((c) => {
      const cataddress = nftCategories.find((x) => x.category.toLowerCase() === c.toLowerCase()).contractaddress;
      const selected = allMinPrices.find(
        (x) =>
          x.tokenPayment === tokenPayment.toLowerCase() &&
          x.nftContract.toLowerCase() === cataddress.toLowerCase(),
      );
      if (selected) {
        if (count == 0) {
          smaller = selected?.minPrice;
        } else {
          if (smaller > Number(selected?.minPrice)) {
            smaller = selected?.minPrice;
          }
        }
        count++;
      }
    });
    return smaller;
  };

  const displayInUSD = (currentPrice, tokenPayment) => {
    return getPriceInUSD(prices, currentPrice, tokenPayment);
  };

  return (
    <>
      <div className="row text-center">
        <div className="col">
          {t('Items')}
          <h4>{displayBalance(isloading, stats.totalItems.toFixed(0))}</h4>
        </div>
        <div className="col">
          {t('Listed')}
          <h4>{displayBalance(isloading, stats.totalListing.toFixed(0))}</h4>
        </div>
        <div className="col">
          {t('Owners')}
          <h4>{displayBalance(isloading, stats.ownerCount.toFixed(0))}</h4>
        </div>
      </div>

      <Accordion defaultOpen className="mt-4 " icon="fa fa-align-left" title={t('Volume Traded')}>
        <div className="row text-center">
          <div className="col">
            <img
              src={getTokenIcon(bnbaddress)}
              className="my-3 pr-3"
              style={{ height: '1.45rem' }}
            />
            <h4>{displayBalance(isloading, stats.totalSaleValue.toFixed(3))}</h4>
            ~${displayInUSD(stats.totalSaleValue, bnbaddress)}
          </div>
          <div className="col">
            <img
              src={getTokenIcon(xwinaddress)}
              className="my-3 pr-3"
              style={{ height: '1.45rem' }}
            />
            <h4>{displayBalance(isloading, stats.totalSaleValueXWIN.toFixed(3))}</h4>
            ~${displayInUSD(stats.totalSaleValueXWIN, xwinaddress)}
          </div>
          <div className="col">
            <img
              src={getTokenIcon(usdtaddress)}
              className="my-3 pr-3"
              style={{ height: '1.45rem' }}
            />
            <h4>{displayBalance(isloading, stats.totalSaleValueUSDT.toFixed(3))}</h4>
            ~${displayInUSD(stats.totalSaleValueUSDT, usdtaddress)}
          </div>
        </div>
      </Accordion>
      <Accordion defaultOpen className="mt-4 " icon="fa fa-align-left" title={t('Floor Price')}>
        <div className="row text-center">
          <div className="col">
            <img
              src={getTokenIcon(bnbaddress)}
              className="my-3 pr-3"
              style={{ height: '1.45rem' }}
            />
            <h4>{displayBalance(isloading, stats.floorPrice.toFixed(3))}</h4>
            ~${displayInUSD(stats.floorPrice, bnbaddress)}
          </div>
          <div className="col">
            <img
              src={getTokenIcon(xwinaddress)}
              className="my-3 pr-3"
              style={{ height: '1.45rem' }}
            />
            <h4>{displayBalance(isloading, stats.floorPriceXWIN.toFixed(3))}</h4>
            ~${displayInUSD(stats.floorPriceXWIN, xwinaddress)}
          </div>
          <div className="col">
            <img
              src={getTokenIcon(usdtaddress)}
              className="my-3 pr-3"
              style={{ height: '1.45rem' }}
            />
            <h4>{displayBalance(isloading, stats.floorPriceUSDT.toFixed(3))}</h4>
            ~${displayInUSD(stats.floorPriceUSDT, usdtaddress)}
          </div>
        </div>
      </Accordion>
    </>
  );
};
export default CollectionStats;
