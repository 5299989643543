import systemConfig from '../configs/systemConfig';
import httpService from './httpService';

const userEndpoint = `${systemConfig.apiUrl}/api/toppy/users`;
const authEdpoint = `${systemConfig.apiUrl}/api/toppy/auth`;

export function getUserByAddress(address: string) {
  return httpService.get(`${userEndpoint}/get-by-address/${address}`);
}

export function getUserByUsername(username: string) {
  return httpService.get(`${userEndpoint}/get-by-username/${username}`);
}

export function getByUserTokenAddressUsername(params: Record<string, unknown>) {
  return httpService.get(`${userEndpoint}/find-users`, {
    params: { ...params },
  });
}

export function updateUser(user: User.Information) {
  return httpService.patch(`${userEndpoint}`, user);
}

export function signinUser(body) {
  return httpService.post(`${authEdpoint}/signin`, body);
}

export function signupUser(body) {
  return httpService.post(`${authEdpoint}/signup`, body);
}

export function countTotalUsers() {
  return httpService.get(`${userEndpoint}/total-count`);
}
