import Accordion from '../Accordion';
import { useTranslation } from 'react-i18next';

const MysteriosBoxFAQ = () => {
  const { t } = useTranslation(['faq-mbox']);

  return (
    <>
      <Accordion alwaysOpen className="mt-4 " icon="fa fa-question-circle" title={t('FAQ')}>
        <Accordion defaultOpen={false} className="mt-4" title={t('question.one')}>
          <div className="row " dangerouslySetInnerHTML={{ __html: t('desc.one') }}></div>
        </Accordion>
        <Accordion defaultOpen={false} className="mt-4" title={t('question.two')}>
          <div className="row " dangerouslySetInnerHTML={{ __html: t('desc.two') }}></div>
        </Accordion>
        <Accordion defaultOpen={false} className="mt-4" title={t('question.three')}>
          <div className="row " dangerouslySetInnerHTML={{ __html: t('desc.three') }}></div>
        </Accordion>
        <Accordion defaultOpen={false} className="mt-4" title={t('question.four')}>
          <div className="row " dangerouslySetInnerHTML={{ __html: t('desc.four') }}></div>
        </Accordion>
        <Accordion defaultOpen={false} className="mt-4" title={t('question.five')}>
          <div className="row" dangerouslySetInnerHTML={{ __html: t('desc.five') }}></div>
        </Accordion>
        <Accordion defaultOpen={false} className="mt-4" title={t('question.six')}>
          <div className="row" dangerouslySetInnerHTML={{ __html: t('desc.six') }}></div>
        </Accordion>
        <Accordion defaultOpen={false} className="mt-4" title={t('question.seven')}>
          <div className="row" dangerouslySetInnerHTML={{ __html: t('desc.seven') }}></div>
        </Accordion>
      </Accordion>
    </>
  );
};

export default MysteriosBoxFAQ;
