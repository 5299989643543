import { createContext } from 'react';
import tokenConfig from 'src/configs/tokenConfig';
import { useNetwork } from 'src/utils/blockchainInteractor';
import Web3 from 'web3';
import { getNetworkById } from 'src/utils/helper';
import systemConfig from '../configs/systemConfig';

export const AppContext = createContext<{
  account: string;
  isConnected: boolean;
  baseBalance: string | undefined;
  web3: Web3;
  network: number;
  connectWallet: () => Promise<string | undefined>;
  disconnectWallet: () => Promise<void>;
  getCurrentProvider: () => void;
  getCurrentWalletBalance: () => Promise<string>;
  setAccount: (account: string) => void;
  setIsConnected: (isConnected: boolean) => void;
  connectCached: () => Promise<string | undefined>;
}>({
  account: '',
  isConnected: false,
  baseBalance: '0',
  network: 0,
  web3: new Web3(getNetworkById(systemConfig.chainId || 56).rpcUrls[0]),
  connectWallet: async () => undefined,
  disconnectWallet: async () => {},
  getCurrentProvider: async () => {},
  getCurrentWalletBalance: async () => '',
  setAccount: async () => {},
  setIsConnected: async () => {},
  connectCached: async () => undefined,
});

const AppProvider = ({ children }) => {
  const {
    account,
    isConnected = false,
    baseBalance = '0',
    web3,
    network = 0,
    connectWallet,
    disconnectWallet,
    getCurrentProvider,
    getCurrentWalletBalance,
    setAccount,
    setIsConnected,
    connectCached,
  } = useNetwork();

  const value = {
    account,
    isConnected,
    baseBalance,
    web3,
    network,
    connectWallet,
    disconnectWallet,
    getCurrentProvider,
    getCurrentWalletBalance,
    setAccount,
    setIsConnected,
    connectCached,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;
