import confetti from 'canvas-confetti';
import classNames from 'classnames';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import ProgressLoader from '../components/ProgressLoader';
import collectionsConfig from '../configs/collectionsConfig';
import systemConfig from '../configs/systemConfig';
import { NftType } from '../constant';
import { AppContext } from '../contexts/AppContext';
import { saveImportSingleNFT } from '../services/metaService';
import { getHashKey } from '../utils/blockchainInteractor';
import { convertIPFSToHTTPS, getAddressShort, getNftType, resolvePromise } from '../utils/helper';
import { revealNFT } from '../utils/mysteriosBoxNFTInteractor';
import SpinnerLoader from './SpinnerLoader';

const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  max-height: 220px;
`;
const NftCardExternal = ({ card, nftContract, contentType, refreshMeta }) => {
  const { t } = useTranslation(['component']);
  const history = useHistory();
  const { account, web3 } = useContext(AppContext);
  const [images, setImages] = useState();
  const [isSpinning, setSpinner] = useState(false);
  const [isLoading, setIsLoading] = useState({
    loading: false,
    type: 'page',
    message: '',
  });

  const handleReveal = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
    }
    try {
      setIsLoading({ loading: true, type: 'page', message: '' });
      const [, unlistError] = await resolvePromise(
        revealNFT(account, web3, nftContract, card.tokenID),
      );
      if (!unlistError) {
        const collection = collectionsConfig.collection.find(
          (collection) =>
            systemConfig.chainId === collection.chainId &&
            collection.contractaddress === nftContract,
        );

        const body: Nft.ImportCustomSingleNFT = {
          tokenId: card.tokenID,
          chainId: systemConfig.chainId.toString(),
          contractAddress: nftContract,
          category: collection?.symbol || '',
        };
        const [importCustomContract, importCustomContractError] = await resolvePromise(
          saveImportSingleNFT(body),
        );
        if (importCustomContract) {
          confetti();
          toast.success(t('common:toast.success'));
          refreshMeta();
        }
      }
      return;
    } catch (error) {
      toast.error(t('toast.error-buy-nft'));
    } finally {
      setIsLoading({ loading: false, type: 'page', message: '' });
    }
  };

  const handleGoDetail = async () => {
    const hashedKey = getHashKey(nftContract, card.tokenID, web3);
    history.push(`/nft/${hashedKey}`);
  };

  return (
    <div className="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12 mb-4">
      <ProgressLoader
        loading={isLoading.loading}
        type={isLoading.type}
        message={isLoading.message}
      />
      <div
        onClick={handleGoDetail}
        className={classNames({
          'nft__item m-0 p-3': true,
        })}
      >
        <div className="nft__item_wrap mt-1">
          {getNftType(contentType as string) === NftType.Audio ||
          getNftType(contentType as string) === NftType.Video ? (
            <Outer>
              <span>
                <ReactPlayer
                  className="nft-card-player"
                  width="100%"
                  url={convertIPFSToHTTPS(card?.NFTImage || '')}
                />
              </span>
            </Outer>
          ) : (
            <Outer>
              <span>
                {isSpinning ? (
                  <SpinnerLoader loading={isSpinning} />
                ) : (
                  <img
                    // onLoad={props.onImgLoad}
                    src={convertIPFSToHTTPS(card?.NFTImage || '') || '/assets/images/preloader.png'}
                    alt={card?.title}
                  />
                )}
              </span>
            </Outer>
          )}
          {/* {isSpinning ? (
            <SpinnerLoader loading={isSpinning} />
          ) : (
            <img src={convertIPFSToHTTPS(card?.NFTImage || "/assets/images/preloader.png")} alt={card?.title} />
          )} */}
        </div>
        <div className="nft__item_info">
          <h4>{card?.title}</h4>

          <div className="nft__item_price mb-3">{getAddressShort(card?.ownerAddress)}</div>

          <div className="d-flex justify-content-center align-content-center mb-3">
            {card.isReveal ? (
              <button onClick={handleGoDetail} className="btn-main pt-1 pb-1">
                {t('Detail')}
              </button>
            ) : (
              <button onClick={handleReveal} className="btn-sub1 pt-1 pb-1">
                {t('Reveal')}
              </button>
            )}
          </div>
          <div className="spacer-10"></div>
        </div>
      </div>
    </div>
  );
};

export default NftCardExternal;
