import { signinUser, signupUser } from '../services/userService';
import { Authentication } from '../typings/api';
import Web3 from 'web3';

export const signIn = async (
  address: string,
  web3 : Web3
  ): Promise<Authentication.SignIn.Response> => {
  const msg = `I would like to sign in for user with address: ${address}`;
  const JSONBody: {
    address: string;
    signature?: string;
  } = {
    address,
  };

  const cachedUser = localStorage.getItem('user');
  if (cachedUser) {
    // console.log("cached: ", cachedUser);
    return {
      user: JSON.parse(cachedUser),
      success: true,
    };
  }
  await web3.eth.personal.sign(msg, address, null as unknown as string, (_, signature: string) => {
    JSONBody.signature = signature;
  });

  try {
    const response = await signinUser(JSONBody);

    localStorage.setItem('token', response.data.token);
    localStorage.setItem('user', JSON.stringify(response.data.user));
    return {
      user: response.data.user,
      success: true,
    };
  } catch (error) {
    console.log(error);
    return {
      user: null,
      success: false,
    };
  }
};

export const signUp = async (
  address: string,
  formData: FormData,
  web3 : Web3
): Promise<Authentication.SignUp.Response> => {
  const msg = `I would like to sign up for user with address: ${address}`;

  let JSONBody: Record<string, unknown> = {
    address,
    ...formData,
  };

  await web3.eth.personal.sign(msg, address, null as unknown as string, (_, signature: string) => {
    JSONBody.signature = signature;
  });

  try {
    const response = await signupUser(JSONBody);

    localStorage.setItem('token', response.data.token);
    localStorage.setItem('user', JSON.stringify(response.data.user));
    return {
      user: response.data.user,
      success: true,
    };
  } catch (error) {
    return {
      user: null,
      success: false,
    };
  }
};
