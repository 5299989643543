import { useTranslation } from 'react-i18next';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MyCollection from 'src/components/MyCollectionCard';

type Props = {
  collections: Collection.MyCollection[];
};

const ExploreCollections = (props: Props) => {
  const { t } = useTranslation(['component']);
  const { collections } = props;
 
  return (
    <div className="row">
        {collections.map((c) => {
          return (
          <MyCollection 
            key={c._id} 
            collection={c}
          />
          )
        })}
    </div>
  );
};

export default ExploreCollections;
