import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal } from 'reactstrap';
import { getNftCategoriesList } from '../services/metaService';
import { getPaymentTypesList } from '../services/paymentTypesService';
import { getUserByUsername } from '../services/userService';
import {
  CommonRoutes,
  DefaultPaginationData,
  ListingType,
  moralisXWinAddress,
  wbnbaddress,
  PAGE_SIZE,
  MarketPlacePageType,
} from '../constant';
import UserContext from '../contexts/UserContext';
import { getPaginatedNFTs } from '../utils/commonInteractor';
import { resolvePromise, getCheckedSumAddress, displayMessage } from '../utils/helper';
import MarketplaceFilter from '../components/MarketplaceFilter';
import NftCard from '../components/NftCard';
import UserProfileHeader from '../components/UserProfileHeader';
import UserCollectionHeader from '../components/userCollectionHeader';
import MarketPlaceFAQ from '../components/FAQ/MarketPlaceFAQ';
import ListFormBulk from '../components/ListFormBulk';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'src/contexts/AppContext';
import tokenConfig from 'src/configs/tokenConfig';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import confetti from 'canvas-confetti';
import { useTheme } from 'src/contexts/ThemeContext';
import systemConfig from 'src/configs/systemConfig';

import {
  moralisGetMaticPrice,
  moralisGetPrice,
  moralisGetXWINPrice,
  moralisGetTotalVolume,
  moralisGetFloorPrice,
  moralisGetFloorPriceByCollection,
  moralisGetTotalVolumeByCollection,
} from '../utils/moralisInteractor';
import { isAuctionExpired, extendBulkListing } from 'src/utils/newMarketPlaceInteractor';
import { getCollectionByName } from 'src/services/collectionService';

const Marketplace = ({ match }) => {
  const { t } = useTranslation(['page.marketplace']);
  const search = useLocation().search;
  const history = useHistory();
  const { themeType } = useTheme();
  const [darkTheme] = useState(themeType == 'dark');
  const { state } = useContext(UserContext);
  const { account, web3 } = useContext(AppContext);
  const [metadata, setMetadata] = useState<Nft.NftMeta[]>([]);
  const [marketplacePageType, setMarketPlacePageType] = useState<MarketPlacePageType>(
  );
  const [pagination, setPagination] = useState<Common.PaginatedMetadata>(DefaultPaginationData);
  const [nftCategories, setNftCategories] = useState<Nft.NftCategory[]>([]);
  const [nftPaymentTypes, setNftPaymentTypes] = useState<Nft.NftPaymentType[]>([]);
  const [openListModal, setOpenListModal] = useState<boolean>(false);
  const [showBulk, setShowBulk] = useState<boolean>(false);
  const [showBulkExtend, setShowBulkExtend] = useState<boolean>(false);
  const [showBulkCheckbox, setShowBulkCheckbox] = useState<boolean>(false);
  const [showBulkExtendCheckbox, setShowBulkExtendCheckbox] = useState<boolean>(false);
  const [eligibleTokenIds, setEligibleTokenIds] = useState<EligibleTokens[]>([]);

  const [currPage, setCurrPage] = useState<number>(1);
  const [listingFilters, setListingFilters] = useState<ListingType[]>([]);
  const [catFilters, setCatFilters] = useState<string[]>([]);
  const [payFilters, setPayFilters] = useState<string[]>([]);
  const [userProfileData, setUserProfileData] = useState<User.Information>();
  const [collection, setCollection] = useState<Collection.MyCollection>();
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [allSalesHistory, setAllSalesHistory] = useState<Nft.SalesHistory[]>();
  const [allSalesHistoryByCollection, setAllSalesHistoryByCollection] = useState<Nft.SalesHistory[]>();
  const [allMinPrices, setAllMinPrices] = useState<Nft.AuctionHistory[]>();
  const [allMinPricesByCollection, setAllMinPricesByCollection] = useState<Nft.AuctionHistoryByCollection[]>();
  const [keyWord, setKeyWord] = useState<string>('');
  const [collectionId, setCollectionId] = useState<string>('0');

  //for Calvin to pull

  const [prices, setPrices] = useState({
    xwin: 1,
    bnb: 1,
    usdt: 1,
  });

  useEffect(() => {
    init();
  }, [match.path]);

  useEffect(() => {
    getNFTsMeta();
  }, [catFilters, listingFilters, currPage, keyWord]);

  const init = async () => {
    const xwinPrice = await moralisGetXWINPrice();
    const bnbPrice = (systemConfig.chainId==137 || systemConfig.chainId==80001) ? await moralisGetMaticPrice() : await moralisGetPrice(wbnbaddress) ;
    if (xwinPrice && bnbPrice) {
      setPrices({
        xwin: xwinPrice,
        bnb: bnbPrice,
        usdt: 1,
      });
    }

    if (match?.path.includes(CommonRoutes.MARKETPLACE)) {
      setMarketPlacePageType(MarketPlacePageType.MARKETPLACE);
    }

    if (match?.params?.username) {
      const [userProfileResponse, getUserProfileError] = await resolvePromise(
        getUserByUsername(match?.params?.username || ''),
      );
      if (getUserProfileError) {
        toast.error('User not found');
        //await delayExecution();
        history.replace(CommonRoutes.MARKETPLACE);
        return;
      }
      setUserProfileData(userProfileResponse?.data);
      setMarketPlacePageType(MarketPlacePageType.USER);
    }
    if (match?.path.includes(CommonRoutes.MY_NFT)) {
      if (!account && !state?.user) {
        history.replace(CommonRoutes.MARKETPLACE);
        return;
      }
      const [userProfileResponse, getUserProfileError] = await resolvePromise(
        getUserByUsername(state?.user?.username || ''),
      );
      setUserProfileData(userProfileResponse?.data);
      setMarketPlacePageType(MarketPlacePageType.OWN);
    }

    const [nftCategoryResponse, getNftCategoryError] = await resolvePromise(getNftCategoriesList());
    if (!getNftCategoryError) {
      setNftCategories((nftCategoryResponse?.data?.data || []).filter((d) => d.priority !== 0));
    }

    const [nftPaymentTypesResponse, getPaymentTypesError] = await resolvePromise(
      getPaymentTypesList(),
    );
    if (!getPaymentTypesError) {
      setNftPaymentTypes(
        (nftPaymentTypesResponse?.data?.data || []).filter((d) => d.priority !== 0),
      );
    }

    if (!match?.params?.collection) {
      const result = await moralisGetTotalVolume();
      setAllSalesHistory(result);
      const minPriceResult = await moralisGetFloorPrice();
      setAllMinPrices(minPriceResult);
    }

    if (match?.params?.category) {
      const nftAdd =
        nftCategoryResponse?.data?.data.find((x) => x.category == match?.params?.category)
          ?.contractaddress || '';
      setSelectedCategory(nftAdd);
      setCatFilters([match?.params?.category]);
      setMarketPlacePageType(MarketPlacePageType.CATEGORY);

      return;
    }

    if (match?.params?.collection) {
      const [collectionResponse, getCollectionError] = await resolvePromise(
        getCollectionByName(match?.params?.collection || ''),
      );
      if (getCollectionError) {
        toast.error('Collection not found');
        history.replace(CommonRoutes.MARKETPLACE);
        return;
      }
      if (collectionResponse) {
        setCollection(collectionResponse);
      }

      setMarketPlacePageType(MarketPlacePageType.COLLECTION);

      const minPriceResultByCollection = await moralisGetFloorPriceByCollection();
      setAllMinPricesByCollection(minPriceResultByCollection);
      const resultByColl = await moralisGetTotalVolumeByCollection();
      setAllSalesHistoryByCollection(resultByColl);
    }

    await getNFTsMeta();
  };

  const handleClearListingFilter = () => {
    setListingFilters([]);
    setCurrPage(1);
  };

  const handleChangeBulk = (e) => {
    setShowBulk(e.target.checked);
    if (e.target.checked) {
      setShowBulkCheckbox(true);
      setShowBulkExtend(false);
      handleClearListingFilter();
      handleChange(ListingType.NOT_LISTED);
    } else {
      setShowBulkCheckbox(false);
      handleClearListingFilter();
    }
  };

  const handleChangeBulkExtend = (e) => {
    setShowBulkExtend(e.target.checked);
    if (e.target.checked) {
      setShowBulkExtendCheckbox(true);
      setShowBulk(false);
      setShowBulkCheckbox(false);
      handleClearListingFilter();
    } else {
      setShowBulkExtendCheckbox(false);
      handleClearListingFilter();
    }
  };

  const handleChange = (filterType: ListingType) => {
    if (!showBulkCheckbox) {
      const idx = listingFilters.indexOf(filterType);
      if (idx > -1) {
        listingFilters.splice(idx, 1);
        setListingFilters([...listingFilters]);
      } else {
        setListingFilters([...listingFilters, filterType]);
      }
      setCurrPage(1);
    }
  };

  const handleChangeCategory = (filterType: string) => {
    const idx = catFilters.indexOf(filterType);
    if (idx > -1) {
      catFilters.splice(idx, 1);
      setCatFilters([...catFilters]);
    } else {
      setCatFilters([...catFilters, filterType]);
    }

    setCurrPage(1);
  };

  const handleBulkExtend = async () => {
    const promises3 = metadata.map(async (meta) => {
      const isExpired = await isAuctionExpired(web3, meta.hashedKey);
      if (isExpired) {
        return meta?.hashedKey;
      } else {
        return '';
      }
    });
    const keys = await Promise.all(promises3);
    const filteredKeys = keys.filter(Boolean);
    if (filteredKeys.length > 0) {
      const [listingData, listError] = await resolvePromise(
        extendBulkListing(account, web3, filteredKeys),
      );
      if (!listError) {
        toast.success(t('component:bulklist-success'));
        confetti();
      }else{
        displayMessage(listError, t)
      }
    } else {
      toast.error(t('component:no-expiredlisting'));
    }
  };

  const handleChangePayment = (filterType: string) => {
    const idx = payFilters.indexOf(filterType);
    if (idx > -1) {
      payFilters.splice(idx, 1);
      setPayFilters([...payFilters]);
    } else {
      setPayFilters([...payFilters, filterType]);
    }
    setCurrPage(1);
  };

  const getNFTsMeta = async () => {
    setPagination(DefaultPaginationData);
    setMetadata([]);

    let ownerAddress: string | null = null;

    if (match.path.includes(CommonRoutes.MY_NFT)) {
      ownerAddress = getCheckedSumAddress(state?.user?.address, web3) || null;
    } else if (match?.params?.username) {
      if (!userProfileData) {
        const [userProfileResponse] = await resolvePromise(
          getUserByUsername(match?.params?.username),
        );
        if (userProfileResponse) {
          ownerAddress = getCheckedSumAddress(userProfileResponse.data.address, web3);
        }
      } else {
        ownerAddress = getCheckedSumAddress(userProfileData.address, web3);
      }
    } else {
      ownerAddress = null;
    }
    let listFilter: ListingType[] = [];
    if (listingFilters.length === 0) {
      if (match.path.includes(CommonRoutes.MY_NFT) && ownerAddress !== null) {
        if (showBulkCheckbox) {
          listFilter = [ListingType.NOT_LISTED];
        } else if (showBulkExtendCheckbox) {
          listFilter = [ListingType.AUCTION, ListingType.ENGLISH_AUCTION, ListingType.NOT_LISTED];
        } else {
          listFilter = [
            ListingType.AUCTION,
            ListingType.ENGLISH_AUCTION,
            ListingType.MARKETPLACE,
            ListingType.NOT_LISTED,
          ];
        }
      } else if 
        ((!match.path.includes(CommonRoutes.MY_NFT) && ownerAddress !== null) 
        || (match?.params?.collection)) 
      {
        listFilter = [
          ListingType.AUCTION,
          ListingType.ENGLISH_AUCTION,
          ListingType.MARKETPLACE,
          ListingType.NOT_LISTED,
        ];
      } else {
        listFilter = [ListingType.AUCTION, ListingType.ENGLISH_AUCTION, ListingType.MARKETPLACE];
      }
    } else {
      listFilter = listingFilters;
    }

    const [response] = await resolvePromise(
      getPaginatedNFTs({
        categories: catFilters,
        paymentTypes: payFilters,
        listingType: listFilter,
        ownerAddress,
        page: currPage,
        perPage: PAGE_SIZE,
        keyWord: keyWord,
        collectioName: match?.params?.collection,
      }),
    );

    if (response) {
      setMetadata(response?.data || []);
      setPagination(response?.metadata || DefaultPaginationData);
    }
    if (showBulk) {
      const results = [] as EligibleTokens[];
      response?.data.forEach((m) => {
        if (m.tokenID) {
          results.push({
            key: m.hashedKey,
            nftContract: m.contractAddress,
            tokenId: m.tokenID,
          });
        }
      });
      setEligibleTokenIds(results);
    }
  };

  const [height, setHeight] = useState(0);

  const onImgLoad = ({ target: img }) => {
    let currentHeight = height;
    if (currentHeight < img.offsetHeight) {
      setHeight(img.offsetHeight);
    }
  };

  const renderUserProfileHeader = () => {
    if (userProfileData) {
      if (match?.path.includes(CommonRoutes.MY_NFT) || match?.params?.username) {
        return <UserProfileHeader user={userProfileData} editable={false} />;
      }
    }
  };

  const renderCollectionHeader = () => {
    if (collection && match?.path.includes(CommonRoutes.COLLECTION)) {
      return <UserCollectionHeader
        userCollection={collection}
        editable={false} />;
    }
  };

  const updateEligibleTokens = (e, hashedKey: string, tokenId: string, nftContract: string) => {
    if (!e.target.checked && eligibleTokenIds) {
      const filteredItems = eligibleTokenIds.filter((t) => t.key !== hashedKey);
      setEligibleTokenIds(filteredItems);
      return;
    }

    const result = {
      key: hashedKey,
      nftContract: nftContract,
      tokenId: tokenId,
    };

    const filteredItems = eligibleTokenIds;
    filteredItems?.push(result);
    setEligibleTokenIds(filteredItems);
  };

  const handleChangeText = (e) => {
    setCurrPage(1);
    setKeyWord(e.target.value);
  };

  return (
    <>
      {renderUserProfileHeader()}
      {renderCollectionHeader()}
      <section className="container">
        <div className="row">
          <div className="spacer-20"></div>

          <div className="d-item col-lg-8 col-md-8 col-sm-12 col-xs-12 mb-4">
            <div className="d-flex pb-2">
              <div className="m-1">
                <FontAwesomeIcon icon={faSearch} className="icon-link" />
              </div>
              <input
                type="text"
                name={'search'}
                value={keyWord}
                className="form-control"
                placeholder="Search"
                onChange={handleChangeText}
              />
            </div>
          </div>
          <div className="d-item col-lg-4 col-md-4 col-sm-12 col-xs-12 mb-4">
            <div className="pagination-box p-2 text-right">
              <ul className="clearfix">
                {pagination.currentPage !== 1 && (
                  <li
                    onClick={() => setCurrPage(pagination.currentPage - 1)}
                    style={{ cursor: 'pointer' }}
                  >
                    <a>
                      <i className="fas fa-long-arrow-alt-left" />
                    </a>
                    {t('button.previous')}
                  </li>
                )}
                <li className="current">
                  <span>{pagination.currentPage}</span>
                </li>
                {pagination.nextPage && (
                  <li
                    onClick={() => setCurrPage(pagination.currentPage + 1)}
                    style={{ cursor: 'pointer' }}
                  >
                    <a className=".greyscheme .pagination li .a">
                      {t('button.next')}
                      <i className="fas fa-long-arrow-alt-right" />
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>

          <div className="col-md-3">
            <MarketplaceFilter
              showCategoryFilter={!match?.path.includes(CommonRoutes.COLLECTION) || false}
              showOtherListingType={
                match?.path.includes(CommonRoutes.MY_NFT) ||
                match?.path.includes(CommonRoutes.COLLECTION) ||
                false
              }
              showMultipleListing={match?.path.includes(CommonRoutes.MY_NFT) || false}
              showStats={
                (match?.path.includes(CommonRoutes.MARKETPLACE)) || false
              }
              showBulk={showBulk}
              showBulkExtend={showBulkExtend}
              listingTypeOnChange={(listingType) => handleChange(listingType)}
              categoryTypeOnChange={(category) => handleChangeCategory(category)}
              openListBulk={() => setOpenListModal(true)}
              performBulkExtend={() => handleBulkExtend()}
              clearListingFilter={() => handleClearListingFilter()}
              changeBulk={(e) => handleChangeBulk(e)}
              changeBulkExtend={(e) => handleChangeBulkExtend(e)}
              nftCategories={nftCategories}
              allSalesHistory={allSalesHistory == undefined ? [] : allSalesHistory}
              allMinPrices={allMinPrices == undefined ? [] : allMinPrices}
              prices={prices}
              catFilters={catFilters}
              payFilters={payFilters}
              nftPaymentTypes={nftPaymentTypes}
              paymentTypeOnChange={(name) => handleChangePayment(name)}
              nftaddress={selectedCategory}
              allMinPricesByCollection={allMinPricesByCollection == undefined ? [] : allMinPricesByCollection}
              allSalesHistoryByCollection={allSalesHistoryByCollection == undefined ? []: allSalesHistoryByCollection}
              collection={collection}
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              {metadata?.map((card) => (
                <NftCard
                  nft={card}
                  key={card.hashedKey}
                  nftCategories={nftCategories}
                  onImgLoad={onImgLoad}
                  height={height}
                  prices={prices}
                  eligibleTokenIds={eligibleTokenIds || []}
                  setEligibleTokens={(e, hashedKey, tokenId, nftContract) =>
                    updateEligibleTokens(e, hashedKey, tokenId, nftContract)
                  }
                  isMyNft={match?.path.includes(CommonRoutes.MY_NFT) || false}
                  showBulkCheckbox={showBulkCheckbox}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="pagination-box p-2 text-right">
          <ul className="clearfix">
            {pagination.currentPage !== 1 && (
              <li
                onClick={() => setCurrPage(pagination.currentPage - 1)}
                style={{ cursor: 'pointer' }}
              >
                <a>
                  <i className="fas fa-long-arrow-alt-left" />
                </a>
                {t('button.previous')}
              </li>
            )}
            <li className="current">
              <span>{pagination.currentPage}</span>
            </li>
            {pagination.nextPage && (
              <li
                onClick={() => setCurrPage(pagination.currentPage + 1)}
                style={{ cursor: 'pointer' }}
              >
                <a className=".greyscheme .pagination li .a">
                  {t('button.next')}
                  <i className="fas fa-long-arrow-alt-right" />
                </a>
              </li>
            )}
          </ul>
        </div>
        <div className="spacer-40"></div>
        <MarketPlaceFAQ />
        <Modal
          isOpen={openListModal}
          toggle={() => setOpenListModal(!openListModal)}
          keyboard={false}
          size="lg"
        >
          <div className="checkout">
            <div className="maincheckout">
              <button
                className={darkTheme ? 'btn-close green' : 'btn-close purple'}
                onClick={() => setOpenListModal(!openListModal)}
              >
                x
              </button>
              <ListFormBulk
                reset={() => {
                  setOpenListModal(false);
                }}
                eligibleTokenIds={eligibleTokenIds}
              />
            </div>
          </div>
        </Modal>
      </section>
    </>
  );
};

export default Marketplace;
