import * as Yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import ProgressLoader from '../components/ProgressLoader';
import UserContext from '../contexts/UserContext';
import { resolvePromise, getNftType, getERC721ContractAddressByCategory } from '../utils/helper';
import { getNftCategoriesList } from '../services/metaService';
import { CommonRoutes, NftType, UploadEntity } from 'src/constant';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'src/contexts/AppContext';
import systemConfig from 'src/configs/systemConfig';
import { uploadFileV2 } from 'src/services/utilService';
import { createCollection, getCollectionByName } from 'src/services/collectionService';

type Props = {
  closeDetailModal: () => void;
  reloadPage: () => void;
};

const CollectionDetail = (props: Props) => {
  const { t } = useTranslation(['common', 'collection']);
  const history = useHistory();
  const { account } = useContext(AppContext);
  const userContext = useContext(UserContext);
  const { themeType } = useTheme();
  const [darkTheme, setDarkTheme] = useState(themeType == 'dark');
  const { state } = userContext;
  const { user } = state;
  const [nftCategories, setNftCategories] = useState<Nft.NftCategory[]>([]);
  const [isImageLoading, setIsImageLoading] = useState<boolean>(false);
  const [logoFile, setLogoFile] = useState(null);
  const [bannerFile, setBannerFile] = useState(null);
  const [nameErrorMessage, setNameErrorMessage] = useState<string>('');
  const [logoImageFileMemoryError, setLogoImageFileMemoryError] = useState<boolean>(false);
  const [bannerImageFileMemoryError, setBannerImageFileMemoryError] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState({
    loading: false,
    type: 'page',
    message: '',
  });

  const COLLECTION_VALIDATION_SCHEMA = Yup.object().shape({
    name: Yup.string()
      .max(120, t('collection:validation-message.max.name'))
      .required(t('collection:validation-message.required.name')),
    description: Yup.string().required(t('collection:validation-message.required.description')),
    logo: Yup.string().required(t('collection:validation-message.required.logo')),
    category: Yup.string().required(t('collection:validation-message.required.category')),
  });

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const [nftCategoryResponse, getNftCategoryError] = await resolvePromise(getNftCategoriesList());
    if (!getNftCategoryError) {
      setNftCategories(
        (nftCategoryResponse?.data?.data || []).filter(
          (d) => d.priority !== 0 && d.systemCategory === true,
        ),
      );
    }
  };

  const collection = {
    name: '',
    description: '',
    logo: '',
    banner: '',
    category: '',
  };

  const onSubmit = async (values, { resetForm, setSubmitting }) => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
      return;
    }
    try {
      const [collectionExists] = await resolvePromise(getCollectionByName(values.name));
      if (collectionExists) {
        setNameErrorMessage(
          t('collection:validation-message.duplicated.name', {
            name: values.name,
          }),
        );

        return;
      }

      const nftContract = getERC721ContractAddressByCategory(values.category, nftCategories);

      if (!nftContract) {
        toast.error(t('Address not supported!'));
        return;
      }
      setIsLoading({
        loading: true,
        type: 'page',
        message: t('component:uploading-image'),
      });
      if (logoFile) {
        values.logo = await setFileChange(UploadEntity.COLLECTIONS_LOGO, logoFile);
      }
      if (bannerFile) {
        values.banner = await setFileChange(UploadEntity.COLLECTIONS_BANNER, bannerFile);
      }
      values.ownerAddress = user?.address;
      values.chainId = String(systemConfig.chainId);
      values.href = `${CommonRoutes.COLLECTION}/${encodeURI(values.name.trim())}`;
      values.name = values.name.trim();

      const [, error] = await resolvePromise(createCollection(values));

      if (error) {
        toast.error(t('common:toast.something-went-wrong'));
        return;
      }

      toast.success(t('collection:toast.create-success'));
      props.closeDetailModal();
      props.reloadPage();
    } catch (err) {
      console.log(err);
      toast.error(t('common:toast.something-went-wrong'));
    } finally {
      setIsLoading({ loading: false, type: 'page', message: '' });
    }

    setSubmitting(false);
  };

  const setSelectedFileName = async (files, setFieldValue, field: 'logo' | 'banner') => {
    const imageURL = URL.createObjectURL(files[0]);

    const fileType = files[0].type;
    // Calculating bytes to kilobytes (bytes divided by 1024)
    const fileMemory = Math.round(files[0].size / 1024);
    if (fileMemory >= 10240 && getNftType(fileType) == NftType.Image) {
      if (field === 'logo') {
        setLogoImageFileMemoryError(true);
      }
      if (field === 'banner') {
        setBannerImageFileMemoryError(true);
      }
      return;
    }

    if (field === 'logo') {
      setLogoFile(files[0]);
    }
    if (field === 'banner') {
      setBannerFile(files[0]);
    }
    setLogoImageFileMemoryError(false);
    setBannerImageFileMemoryError(false);
    setFieldValue(field, imageURL);
  };

  const setFileChange = async (entity: UploadEntity, files: any): Promise<string> => {
    try {
      const formData = new FormData();
      formData.append('file', files);
      const { data } = await uploadFileV2(entity, formData);
      return data.Location;
    } catch (e) {
      toast.error(t('common:toast.something-went-wrong'));
      return '';
    }
  };

  return (
    <>
      <div>
        <ProgressLoader
          loading={isLoading.loading}
          type={isLoading.type}
          message={isLoading.message}
        />
        <div>
          <div>
            <div className="row">
              <div>
                <h2>{t('collection:collection')}</h2>
              </div>
            </div>
          </div>
        </div>
        <Formik
          initialValues={collection}
          validationSchema={COLLECTION_VALIDATION_SCHEMA}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, values, setFieldValue, errors, touched, handleChange }) => (
            <div className="row">
              <div>
                <Form autoComplete="random_string" className="form-border">
                  <div className="field-set">
                    <h5>{t('collection:label.name')}</h5>
                    <Field
                      type="text"
                      name="name"
                      className="form-control"
                      value={values?.name}
                      placeholder={t('collection:placeholder.name')}
                      onChange={(e) => {
                        setNameErrorMessage('');
                        handleChange(e);
                      }}
                    />
                    {errors.name && touched.name ? (
                      <div className="text-danger mt-2">{errors.name}</div>
                    ) : null}
                    {nameErrorMessage && <div className="text-danger mt-2">{nameErrorMessage}</div>}
                    <div className="spacer-10"></div>

                    <h5>{t('collection:label.description')}</h5>
                    <Field
                      component="textarea"
                      name="description"
                      className="form-control"
                      placeholder={t('collection:placeholder.description')}
                      value={values.description}
                      maxrows={4}
                      rows={4}
                      multiline={'true'}
                    />
                    {errors.description && touched.description ? (
                      <div className="text-danger mt-2">{errors.description}</div>
                    ) : null}
                    <div className="spacer-10"></div>

                    <h5>{t('collection:label.category')}</h5>
                    <Field
                      component="select"
                      name="category"
                      className="select"
                      value={values.category}
                    >
                      <option disabled value="">
                        {t('Select Category')}
                      </option>
                      {nftCategories.map((cat) => {
                        return (
                          <option key={cat._id} value={cat.category}>
                            {cat.category}
                          </option>
                        );
                      })}
                    </Field>
                    {errors.category && touched.category ? (
                      <div className="text-danger mt-2">{errors.category}</div>
                    ) : null}
                    <div className="spacer-40"></div>

                    <h5>{t('collection:label.logo')}</h5>
                    <div className="d-create-file">
                      {isImageLoading ? (
                        <div className="text-center">
                          <Spinner
                            style={{ width: '6rem', height: '6rem' }}
                            size="lg"
                            color="primary"
                          />
                        </div>
                      ) : values.logo ? (
                        <div className="upload-images-preview">
                          <div onClick={() => setFieldValue('logo', '')} className="d-flex mb-2">
                            <i className="fas fa-times ml-auto" />
                          </div>
                          <img
                            className="rounded w-100"
                            src={values?.logo || '/assets/images/preloader.png'}
                            alt=""
                          />
                        </div>
                      ) : (
                        <div className="browse">
                          <div className="browse">
                            <input
                              type="button"
                              id="get_file"
                              className={`btn-main ${darkTheme ? 'green' : 'purple'}`}
                              value={t('collection:placeholder.add').valueOf()}
                            />
                            <input
                              id="upload_file"
                              name="logo"
                              type="file"
                              accept="image/*"
                              multiple
                              onChange={({ currentTarget }) => {
                                setSelectedFileName(currentTarget.files, setFieldValue, 'logo');
                                currentTarget.value = null as unknown as string;
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {errors.logo && touched.logo ? (
                        <div className="text-danger mt-2">{errors.logo}</div>
                      ) : null}

                      {logoImageFileMemoryError ? (
                        <div className="text-danger mt-2">
                          {'Please upload image less than 10mb'}
                        </div>
                      ) : null}
                    </div>
                    <div className="spacer-40"></div>

                    <h5>{t('collection:label.banner')}</h5>
                    <div className="d-create-file">
                      {isImageLoading ? (
                        <div className="text-center">
                          <Spinner
                            style={{ width: '6rem', height: '6rem' }}
                            size="lg"
                            color="primary"
                          />
                        </div>
                      ) : values.banner ? (
                        <div className="upload-images-preview">
                          <div onClick={() => setFieldValue('banner', '')} className="d-flex mb-2">
                            <i className="fas fa-times ml-auto" />
                          </div>
                          <img
                            className="rounded w-100"
                            src={values?.banner || '/assets/images/preloader.png'}
                            alt=""
                          />
                        </div>
                      ) : (
                        <div className="browse">
                          <div className="browse">
                            <input
                              type="button"
                              id="get_file"
                              className={`btn-main ${darkTheme ? 'green' : 'purple'}`}
                              value={t('collection:placeholder.add').valueOf()}
                            />
                            <input
                              id="upload_file"
                              name="logo"
                              type="file"
                              accept="image/*"
                              multiple
                              onChange={({ currentTarget }) => {
                                setSelectedFileName(currentTarget.files, setFieldValue, 'banner');
                                currentTarget.value = null as unknown as string;
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {errors.banner && touched.banner ? (
                        <div className="text-danger mt-2">{errors.banner}</div>
                      ) : null}

                      {bannerImageFileMemoryError ? (
                        <div className="text-danger mt-2">
                          {'Please upload image less than 10mb'}
                        </div>
                      ) : null}
                    </div>
                    <div className="spacer-10"></div>

                    <div className="d-flex justify-content-center">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className={`btn-main ${darkTheme ? 'green' : 'purple'}`}
                      >
                        {t('collection:button.create')}
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </>
  );
};

export default CollectionDetail;
