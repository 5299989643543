import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { toInteger } from 'lodash';
import { useHistory } from 'react-router-dom';
import xWinLogo from '../assets/images/xWinLogo.png';
import { useTranslation } from 'react-i18next';
import {
  getTokenSymbol,
  getTokenIcon,
  convertAmountIntoUSD,
  getAddressShort,
} from 'src/utils/helper';
import { AppContext } from 'src/contexts/AppContext';
import Avatar from 'react-avatar';
import { createGlobalStyle } from 'styled-components';
import { getNFTAPR } from '../utils/stakingNFTInteractor';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ReactInterval from 'react-interval';
import { CommonRoutes } from 'src/constant';

const GlobalStyles = createGlobalStyle`
.greyscheme .nft__item img[class="token_icon"] {
  height: 1.75rem !important;
  width: 1.75rem !important;
}
`;

type Props = {
  nft: Nft.Information;
  isMyNft?: boolean;
  nftCategories?: Nft.NftCategory[];
};

const NftCollectionCard = ({ card }) => {
  const { web3 } = useContext(AppContext);
  const { t } = useTranslation(['component']);
  const history = useHistory();
  const [averageAPR, setAverageAPR] = React.useState('0');
  const [maxAPR, setMaxAPR] = React.useState('0');
  const [nftCostInUSD, setnftCostInUSD] = React.useState(0);

  useEffect(() => {
    //setAuctionDetailsForNFT(props.nft.hashedKey);
    init();
  }, [card]);

  const init = async () => {
    const costInUSD = convertAmountIntoUSD(
      card.costPerNFT,
      card.bnbPrice,
      card.xwinPrice,
      card.tokenPayment,
    );
    setnftCostInUSD(costInUSD);
    const maxapr = await getNFTAPR(
      web3,
      card.maxScore,
      card.pid,
      costInUSD.toFixed(3),
      card.xwinPrice,
    );

    const averageapr = await getNFTAPR(
      web3,
      card.averageScore,
      card.pid,
      costInUSD.toFixed(3),
      card.xwinPrice,
    );
    setAverageAPR(averageapr);
    setMaxAPR(maxapr);
    setnftCostInUSD(costInUSD);
  };

  const getAPRToolTip = () => {
    if (averageAPR == 'N/A') {
      return t('component:no-apr-tooltip');
    } else {
      return t('component:apr-tooltip', {
        currentPrice: card.costPerNFT,
        tokenPayment: getTokenSymbol(card.tokenPayment),
      });
    }
  };

  return (
    <div key={card.contractaddress} className="d-item col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
      <ReactInterval timeout={20000} enabled={true} callback={() => init()} />

      <GlobalStyles />
      <div
        className={classNames({
          'nft__item m-0 p-3': true,
        })}
      >
        <div className="hhFSEG">
          <a href={'https://www.bscscan.com/block/countdown/' + card?.poolendblock} target="_blank">
            {t('component:staking-duration', {
              poolendblock: card.poolendblock,
            })}
          </a>
        </div>
        {card.status === 'new' && <div className="hhFSEG-newmark">{t('component:new-short')}</div>}
        {card.status === 'soldout' && (
          <div className="hhFSEG-soldout">{t('component:sold-out-short')}</div>
        )}

        <div className="spacer-double"></div>
        <div className="d-flex flex-column align-items-center">
          <div
            role="button"
            onClick={() => history.push(`${CommonRoutes.MYSTERY_BOX}/${card.contractaddress}`)}
            className="profile-image"
          >
            <Avatar round src={card?.imageURL || xWinLogo} size="120" />
          </div>
          <div className="spacer-single"></div>
          <h3>
            <span className="store-label">{card?.description}</span>
          </h3>
          <span className="text-success">
            <h3>
              <img src={getTokenIcon(card?.tokenPayment)} className="token_icon" />
              &nbsp;
              {`${Number(card?.costPerNFT).toFixed(3)}`}
            </h3>
          </span>
        </div>
        <div className="d-flex text-primary justify-content-between  ml-4 mr-4">
          <span className="store-label">
            {t('average-apr')}&nbsp;
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="tooltip-1">{getAPRToolTip()}</Tooltip>}
            >
              <i className="fa fa-question-circle"></i>
            </OverlayTrigger>
          </span>
          <span className="text-warning">{averageAPR}%</span>
        </div>
        <div className="d-flex text-primary justify-content-between  ml-4 mr-4">
          <span className="store-label">
            {t('max-apr')}&nbsp;
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="tooltip-1">{getAPRToolTip()}</Tooltip>}
            >
              <i className="fa fa-question-circle"></i>
            </OverlayTrigger>
          </span>
          <span className="text-warning">{maxAPR}%</span>
        </div>

        <div className="d-flex text-primary justify-content-between  ml-4 mr-4">
          <span className="store-label">{t('Max Supply')}</span>
          <span className="text-success">{card?.maxSupply}</span>
        </div>
        <div className="d-flex text-primary justify-content-between  ml-4 mr-4">
          <span className="store-label">{t('Address')}</span>
          <span className="text-success">
            <a href={'https://www.bscscan.com/address/' + card?.contractaddress} target="_blank">
              {getAddressShort(card?.contractaddress)}
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default NftCollectionCard;
