import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { toInteger } from 'lodash';
import { useHistory } from 'react-router-dom';

import { ListingType } from '../constant';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  getTokenSymbol,
  getERC721ContractAddressByCategory,
  getAddressShort,
} from 'src/utils/helper';
import { AppContext } from 'src/contexts/AppContext';
import { getCurrentPriceByKey, getListingInfo } from '../utils/newMarketPlaceInteractor';
import { convertIPFSToHTTPS } from '../utils/helper';
import SpinnerLoader from './SpinnerLoader';

type Props = {
  nft: Nft.Information;
  isMyNft?: boolean;
  nftCategories?: Nft.NftCategory[];
};

const NftCardTemp = ({ nft, key }) => {
  const { web3 } = useContext(AppContext);
  const { t } = useTranslation(['component']);
  const history = useHistory();
  const [nftDetails, setNftDetails] = useState<Nft.Information>();
  const [images, setImages] = useState();
  const [isSpinning, setSpinner] = useState(true);

  // useEffect(() => {
  //   setTimeout(() => {
  //     fetch(nft.NFTImage)
  //       .then((res) => {
  //         setSpinner(false);
  //         return nft.NFTImage;
  //       })
  //       .then((data) => {
  //         setImages(data);
  //         setSpinner(false);
  //       });
  //   }, 2000);
  // });

  return (
    <div className="d-item col-lg-12 col-md-6 col-sm-6 col-xs-12 mb-4">
      <div
        className={classNames({
          'nft__item m-0 p-3': true,
        })}
      >
        <div className="nft__item_wrap mt-1">
          <img src={nft?.NFTImage} alt={nft?.title} />
        </div>
        <div className="nft__item_info mb-1">
          <h4>{nft?.title}</h4>
        </div>
      </div>
    </div>
  );
};

export default NftCardTemp;
