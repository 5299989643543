import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../contexts/ThemeContext';
import Select from 'react-select';
import { createReportFakeNft } from '../services/metaService';
import { toast } from 'react-toastify';

const ReportForm = ({ hashedKey, closeForm }) => {
  const { themeType } = useTheme();
  const { t } = useTranslation(['page.nft.detail', 'component', 'page.profile']);
  const [darkTheme] = useState(themeType == 'dark');
  const [reportTypes, setReportTypes] = useState('fake');
  const [detailFakeReport, setDetailFakeReport] = useState('');

  useEffect(() => {});
  const handleChange = (e) => {
    const value = e.value;
    setReportTypes(value);
  };

  const handleDetailsFake = (e) => {
    const value = e.target.value;
    setDetailFakeReport(value);
  };

  const submitReport = async () => {
    const fakeReportDetails = await createReportFakeNft({
      hashedKey,
      reason: reportTypes,
      detail: detailFakeReport,
    })
      .then(() => {
        toast.success(t('component:report-spam-success'));
        setTimeout(async function () {
          closeForm();
        }, 1500);
      })
      .catch(() => {
        toast.error(t('common:toast.something-went-wrong'));
        console.log('error');
      });
  };

  return (
    <>
      <h5 className="my-3">{t('report.title')}</h5>
      <Select
        className="select1"
        isSearchable={false}
        onChange={handleChange}
        defaultValue={{
          value: 'fake',
          label: t('report.fake'),
        }}
        options={[
          { value: 'fake', label: t('report.fake') },
          { value: 'explicit', label: t('report.explicit') },
          { value: 'other', label: t('report.others') },
        ]}
      />

      {reportTypes == 'fake' ? (
        <div className="mt-5">
          <h5>{t('report.original-col')}</h5>
          <input onChange={handleDetailsFake} className="form-control" type="text" />
        </div>
      ) : null}

      {reportTypes == 'other' ? (
        <div className="mt-5">
          <h5>{t('report.details')}</h5>
          <textarea onChange={handleDetailsFake} className="form-control" />
        </div>
      ) : null}

      <div className="spacer-10"></div>

      <div className="col d-flex justify-content-center">
        <button
          className={`btn-main1 ${darkTheme ? 'green' : 'purple'} my-3`}
          onClick={submitReport}
        >
          {t('component:sign-up-form.button.continue')}
        </button>
      </div>
    </>
  );
};
export default ReportForm;
