import { faFacebook, faTelegram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { displayTwitterLink } from '../utils/helper';

interface Props {
  user: User.Information;
}

const SocialMedia = (props: Props) => {
  return (
    <div className="text-center">
      {props.user?.facebook && (
        <a href={`${props.user?.facebook}`} className="mx-2" target="_blank">
          <FontAwesomeIcon size="lg" icon={faFacebook} />
        </a>
      )}
      {props.user?.twitter && (
        <a href={`${displayTwitterLink(props.user?.twitter)}`} className="mx-2" target="_blank">
          <FontAwesomeIcon size="lg" icon={faTwitter} />
        </a>
      )}
      {props.user?.telegram && (
        <a href={`${props.user?.telegram}`} className="mx-2" target="_blank">
          <FontAwesomeIcon size="lg" icon={faTelegram} />
        </a>
      )}
    </div>
  );
};

export default SocialMedia;
