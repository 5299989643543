import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { DefaultPaginationData } from 'src/constant';
import UserContext from 'src/contexts/UserContext';
import { resolvePromise } from 'src/utils/helper';
import { getCollections } from 'src/services/collectionService';
import MyCollection from 'src/components/MyCollectionCard';
import { useTheme } from '../contexts/ThemeContext';
import CollectionDetail from 'src/components/CollectionDetail';
import { CommonRoutes } from '../constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  moralisGetFloorPriceByCollection,
} from '../utils/moralisInteractor';

const Collection = ({ match }) => {
  const { t } = useTranslation(['collection', 'common']);
  const [myCollection, setMyCollection] = useState<Collection.MyCollection[]>([]);
  const [pagination, setPagination] = useState<Common.PaginatedMetadata>(DefaultPaginationData);
  const { themeType } = useTheme();
  const history = useHistory();
  const { state } = useContext(UserContext);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [darkTheme, setDarkTheme] = useState(themeType == 'dark');
  const [keyWord, setKeyWord] = useState('');
  const [allMinPrices, setAllMinPrices] = useState<Nft.AuctionHistoryByCollection[]>();
  
  useEffect(() => {
    init();
    setDarkTheme(themeType == 'dark');
  }, [match.path, themeType, keyWord]);

  const init = async () => {

    const minPriceResult = await moralisGetFloorPriceByCollection();
    setAllMinPrices(minPriceResult);

    if(match?.path.includes(CommonRoutes.ALL_COLLECTIONS))
    {
      const [response] = await resolvePromise(getCollections(null, true, keyWord==""? null : keyWord));
      setMyCollection(response?.data || []);
      setPagination(response?.metadata || DefaultPaginationData);        
    }else{
      const [response] = await resolvePromise(getCollections(state.user?.address, true, keyWord==""? null : keyWord));
      setMyCollection(response?.data || []);
      setPagination(response?.metadata || DefaultPaginationData);        
    }
  };

  const handleChangeText = (e) => {
    // setCurrPage(1);
    setKeyWord(e.target.value);
  };


  return (
    <>
      <section className="container">
        <div className="spacer-double" />          
        <div className="d-item col-lg-8 col-md-8 col-sm-12 col-xs-12 mb-4">
            <div className="d-flex pb-2">
              <div className="m-1">
                <FontAwesomeIcon icon={faSearch} className="icon-link" />
              </div>
              <input
                type="text"
                name={'search'}
                value={keyWord}
                className="form-control"
                placeholder="Search"
                onChange={handleChangeText}
              />
            </div>
          </div>

        <div className="row">
          {
            !match?.path.includes(CommonRoutes.ALL_COLLECTIONS) && (
              <>
                <div>
                  <h3>
                    <span className="store-label">{t('my-collection')}</span>
                  </h3>
                </div>

                <div style={{ marginBottom: '20px' }}>
                  <button className="btn-main" onClick={() => setShowDetailModal(true)}>
                    {t('create')}
                  </button>
                </div>
              </>
            )
          }
          

          {myCollection?.map((collection) => (
            <MyCollection 
              key={collection._id} 
              collection={collection}
              // allMinPrices={allMinPrices == undefined ? [] : allMinPrices}
              />
          ))}
        </div>
      </section>

      <Modal
        isOpen={showDetailModal}
        toggle={() => setShowDetailModal(!showDetailModal)}
        backdrop
        keyboard={false}
        size={'lg'}
      >
          <div className="collection">
            <div className="mainCollection">
              <button
                className={`btn-close ${darkTheme ? 'green' : 'purple'} m-1`}
                onClick={() => setShowDetailModal(!showDetailModal)}
              >
                x
              </button>
              <div>
                <CollectionDetail
                  closeDetailModal={() => setShowDetailModal(!showDetailModal)}
                  reloadPage={() => init()}
                />
              </div>
            </div>
          </div>
      </Modal>
    </>
  );
};

export default Collection;
