import Accordion from '../../components/Accordion';
import { useTranslation } from 'react-i18next';

const MintFAQ = () => {
  const { t } = useTranslation(['faq-mint']);

  return (
    <>
      <Accordion alwaysOpen className="mt-4 " icon="fa fa-question-circle" title={t('FAQ')}>
        <Accordion defaultOpen={false} className="mt-4" title={t('question.one')}>
          <div className="row " dangerouslySetInnerHTML={{ __html: t('desc.one') }}></div>
        </Accordion>
        <Accordion defaultOpen={false} className="mt-4" title={t('question.two')}>
          <div className="row " dangerouslySetInnerHTML={{ __html: t('desc.two') }}></div>
        </Accordion>

        <Accordion defaultOpen={false} className="mt-4" title={t('question.three')}>
          <div className="row " dangerouslySetInnerHTML={{ __html: t('desc.three') }}></div>
        </Accordion>

        <Accordion defaultOpen={false} className="mt-4" title={t('question.four')}>
          <div className="row " dangerouslySetInnerHTML={{ __html: t('desc.four') }}></div>
        </Accordion>
      </Accordion>
    </>
  );
};

export default MintFAQ;
