import 'react-toastify/dist/ReactToastify.css';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import ProtectedRoute from '../shared/ProtectedRoute';

import { CommonRoutes } from '../constant';
import Footer from '../components/menu/Footer';
import Marketplace from './Marketplace';
import MysteriousBox from './MysteriousBox';
import NftDetailExternal from './NftDetailExternal';
import NFTCollections from './NFTCollections';
import Header from '../components/menu/Header';
import MintPage from './MintPage';
import ImportNFT from './ImportNFT';
import NftDetail from './NftDetail';
import Profile from './Profile';
import Withdrawal from './Withdrawal';
import Privacy from './Privacy';
import TermService from './TermService';
import SalesDashboard from './SalesDashboard';
import Collection from './Collection';

import { createGlobalStyle } from 'styled-components';
import Home from './Home';
import systemConfig from 'src/configs/systemConfig';

//TODO this css should be merge to style.css
const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: var(--navbar_market);
    border-bottom: 0;
    box-shadow: rgb(10 10 10 / 80%) 0px 4px 20px 0px;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: var(--text);
  }
  header#myHeader .dropdown-toggle::after{
    color: #fff;
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: none !important;
  }
  header#myHeader .logo .d-4{
    display: block !important;
  }
  .navbar .search #quick_search{
    border-radius: 20px;
  }
  .navbar .navbar-item .lines {
    border-bottom: 2px solid var(--button);
  }
  .navbar .mainside a{
    text-align: center;
    color: #fff !important;
    background: var(--button);
    border-radius: 30px;
  }
  .navbar .mainside a:hover {
    box-shadow: 2px 2px 20px 0 var(--button);
    transition: all .3s ease;
  }
  .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
    background: #fff;
  }
  .item-dropdown{
    color: #fff !important;
    background: var(--background);
    box-shadow: 2px 2px 30px 0px rgba(20, 20, 20, 0.1);
  }
  .item-dropdown .dropdown a{
    color: var(--text) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .item-dropdown .dropdown a:hover{
    color: #fff !important;
    background: var(--button);
  }
  footer.footer-light .subfooter span img.d-1{
    display: none !important;
  }
  footer.footer-light .subfooter span img.d-4{
    display: inline-block !important;
  }
  .de_countdown{
    right: 10px;
    color: #fff;
  }
  .author_list_pp{
    margin-left:0;
  }
  footer.footer-light .subfooter{
    border-top: 1px solid rgba(255,255,255,.1);
  }
  #scroll-to-top div {
    background: var(--button);
  }
  @media only screen and (max-width: 1199px) {
    .navbar {
      background: var(--navbar-grey);
    }
  }


  /*----*/
  .slick-dots li button:before {
    opacity: 1 !important;
    color: rgba(133, 195, 36, 0.2) !important;
  }
  .slick-dots li.slick-active button:before {
    opacity: 1 !important;
    color: #85c324 !important;
  }
`;

type Props = {
  logout: () => Promise<void>;
  handleStart: () => void;
  openSignUp: boolean;
  toggleModal: (key: string) => void;
  autoConnect: () => void;
};

const Dashboard: React.FunctionComponent<Props> = (props) => {
  return (
    <>
      <div>
        <Header logout={() => props.logout()} onStart={props.handleStart} />
        <div className="greyscheme">
          <GlobalStyles />
          <ToastContainer autoClose={3000} hideProgressBar={false} closeOnClick />
          <Switch>
            <Route exact path={CommonRoutes.CREATE_NFT} component={MintPage} />
            <Route exact path="/import" component={ImportNFT} />
            <Route exact path={systemConfig.binanceUrl} component={Home} />
            <Route exact path={systemConfig.polygonUrl} component={Home} />
            <Route exact path="/nft/:hashedKey" component={NftDetail} />
            <Route exact path={CommonRoutes.MARKETPLACE} component={Marketplace} />
            <Route exact path={`${CommonRoutes.MARKETPLACE}/:category`} component={Marketplace} />
            <Route exact path={`${CommonRoutes.COLLECTION}/:collection`} component={Marketplace} />
            <Route exact path={CommonRoutes.MYSTERY_BOX} component={NFTCollections} />
            <Route exact path={CommonRoutes.ALL_COLLECTIONS} component={Collection} />
            <ProtectedRoute exact path="/profile" component={Profile} />
            <ProtectedRoute exact path="/withdrawal" component={Withdrawal} />
            <ProtectedRoute exact path={CommonRoutes.MY_COLLECTIONS} component={Collection} />
            <ProtectedRoute exact path="/mysales" component={SalesDashboard} />
            <ProtectedRoute exact path={CommonRoutes.MY_NFT} component={Marketplace} />
            <Route exact path="/user/:username" component={Marketplace} />
            <Route exact path={`${CommonRoutes.MYSTERY_BOX}/:address`} component={MysteriousBox} />
            <Route exact path="/nftdetail/:address/:id" component={NftDetailExternal} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/termservice" component={TermService} />
          </Switch>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
