// bsc mainnet
const poolConfig = {
  pool: [
    {
      symbol: 'xWIN Toppy',
      contractaddress: '0xAF6544c1Cc7b05817e4E4136d65d0b43a84B4CC8',
      pid: '1',
    },
    {
      symbol: '3D Toppy',
      contractaddress: '0x9d83a11c0FE62cF451BDEF37F27103c356BF3AA5',
      pid: '0',
    },
  ],
};

// // polygon testnet
// const poolConfig = {
//   pool: [
//     {
//       symbol: '',
//       contractaddress: "",
//       pid: '0',
//     },
//   ],
// };

export default poolConfig;
