import React from 'react';

export const useDidUpdateEffect = (fn: React.EffectCallback, dependencies: unknown[]): void => {
  const mounted = React.useRef(false);

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      return fn();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

export const useDidMountEffect = (fn: React.EffectCallback, dependencies: unknown[]): void => {
  const mounted = React.useRef(false);

  // eslint-disable-next-line consistent-return
  React.useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;

      return fn();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};
