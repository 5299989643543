import React from 'react';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

// import config from "../../../src/config";
const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID as string;
const Analytics = () => {
  const location = useLocation();
  if (TRACKING_ID) {
    ReactGA.initialize(TRACKING_ID);
  }
  React.useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location.pathname]);

  return null;
};

export default Analytics;
