import Web3 from 'web3';
import { loadMasterSettingContract } from './blockchainInteractor';
import { getGasPrice } from 'src/utils/helper';

export async function updateCreatorFee(web3: Web3, amount: string, account: string) {
  
  try {
    const contract = loadMasterSettingContract(web3);
    const amountinWei = parseFloat(amount) * 100;
    const latestGasPrice:any = await getGasPrice(web3);
      
    const txn = await contract.methods.updateMyRoyalty(amountinWei).send({
      from: account,
      value: 0,
      ...latestGasPrice
    });
    return txn;
  } catch (error) {
    throw error
  }
  
}

export async function getCreatorFee(web3: Web3, account: string) {
  const contract = loadMasterSettingContract(web3);
  const txn = await contract.methods.creatorRoyalties(account).call();
  // console.log(txn)
  return txn;
}

export async function getMintFee(web3: Web3) {
  try {
    const contract = loadMasterSettingContract(web3);
    const txn = await contract.methods.mintFee().call();
    return txn;
  } catch (error) {
    console.log(error);
  }
}
