// // bsc mainnet
// const tokenConfig = {
//   default: {
//     address: process.env.REACT_APP_BNB_ADDRESS as string,
//     icon: 'bnb.png',
//     network: 'https://bsc-dataseed1.binance.org:443',
//     token: 'BNB',
//   },
//   paymentTokens: [
//     {
//       name: 'BNB',
//       address: process.env.REACT_APP_BNB_ADDRESS as string,
//       icon: 'bnb.png',
//       priceType: 0,
//     },
//     {
//       name: 'XWIN',
//       address: process.env.REACT_APP_XWIN_ADDRESS as string,
//       icon: 'xwin.png',
//       priceType: 1,
//     },
//     {
//       name: 'USDT',
//       address: process.env.REACT_APP_USDT_ADDRESS as string,
//       icon: 'usdt.png',
//       priceType: 1,
//     },
//   ],
// };

// polygon
const tokenConfig = {
  default: {
    address: process.env.REACT_APP_BNB_ADDRESS as string,
    icon: 'matic.png',
    network: 'https://matic-mumbai.chainstacklabs.com',
    token: 'MATIC',
  },
  paymentTokens: [
    {
      name: 'MATIC',
      address: process.env.REACT_APP_BNB_ADDRESS as string,
      icon: 'matic.png',
      priceType: 0,
    },
    {
      name: 'XWIN',
      address: process.env.REACT_APP_XWIN_ADDRESS as string,
      icon: 'xwin.png',
      priceType: 1,
    },
    {
      name: 'USDT',
      address: process.env.REACT_APP_USDT_ADDRESS as string,
      icon: 'usdt.png',
      priceType: 1,
    },
  ],
};

export default tokenConfig;
