import { CommonRoutes } from '../constant';
import { Link } from 'react-router-dom';

type Props = {
  category: Nft.NftCategory;
};

const NFTCategory = (props: Props) => {
  const { category } = props;

  return (
    <Link
      to={
        category.priority === 0
          ? CommonRoutes.MARKETPLACE
          : `${CommonRoutes.MARKETPLACE}/${category?.category}`
      }
    >
      <div className="nft_pic">
        <span>
          <span className="nft_pic_info">
            <span className="nft_pic_title">{category.category}</span>
          </span>
        </span>
        <div className="nft_pic_wrap">
          <img
            src={category?.backgroundImage}
            className="lazy img-fluid"
            alt={category?.category || ''}
          />
        </div>
      </div>
    </Link>
  );
};

export default NFTCategory;
