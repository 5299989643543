import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import DefaultBanner from '../assets/images/defaultBanner.png';
import xWinLogo from '../assets/images/xWinLogo.png';
import { convertIPFSToHTTPS, getAddressShort, goToBSCScan } from '../utils/helper';

interface Props {
  userCollection: Collection.MyCollection;
  editable: boolean;
}

const UserCollectionHeader = (props: Props) => {
  const { userCollection, editable } = props;

  const { t } = useTranslation(['page.profile', 'page.marketplace', 'common']);

  return (
    <>
      <section className="jumbotron breadcumb no-bg">
        <img src={userCollection?.banner || DefaultBanner} className="bannar-image" />
      </section>
      <section className="container d_coll no-top no-bottom">
        <div className="profile_avatar">
          <div className="d_profile_img">
            <img src={convertIPFSToHTTPS(userCollection?.logo || xWinLogo) || xWinLogo} alt="" />
            {editable && (
              <label role="button" htmlFor="profile-pic-upload" style={{ display: 'contents' }}>
                <i className="fa fa-camera" />
              </label>
            )}
          </div>
        </div>
        {editable && (
          <div className="banner">
            <div className="setting-change-profile text-center">
              <div>
                <label role="button" htmlFor="banner-pic-upload">
                  <FontAwesomeIcon icon={faCamera} className="mr-2" />
                </label>
                {t('label.change-banner-image')}
              </div>
            </div>
          </div>
        )}
        <div className="mt-2">
          <h3 className="mb-2 text-center">{userCollection?.name}</h3>
          <p className="mb-2 text-center">{userCollection?.description}</p>
          {/* <SocialMedia user={userCollection.user} /> */}
          <div className="de_tab">
            <ul className="de_nav">
              <li id="Mainbtn1" className="active">
                <span onClick={() => goToBSCScan(userCollection.user?.address || '')}>
                  <i className="fa fa-user"></i> &nbsp;
                  {getAddressShort(userCollection.user?.address)}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};
export default UserCollectionHeader;
