import Web3 from 'web3';
import { getTimeStampFromBlockNumber } from 'src/utils/blockchainInteractor';
import {
  loadListingContract,
  getHashKey,
  convertFromWei,
  convertToWei,
  loadERC20Contract,
} from './blockchainInteractor';
import { getGasPrice, getPaymentTokenDecimalsByAddress } from 'src/utils/helper';
import systemConfig from 'src/configs/systemConfig';

export async function getTotalListedByAddress(web3: Web3, address: string) {
  if (address == '') {
    return '0';
  }
  const contract = loadListingContract(web3);
  try {
    const txn = await contract.methods.totalListedByOwner(address).call();
    return txn;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function getTotalListedNFT(web3: Web3) {
  const contract = loadListingContract(web3);

  try {
    const txn = await contract.methods.totalListed().call();

    return txn;
  } catch (e) {
    return null;
  }
}

export async function getTotalListedByOwner(address: string, web3: Web3) {
  const contract = loadListingContract(web3);

  try {
    const txn = await contract.methods.totalListedByOwner(address).call();

    return txn;
  } catch (e) {
    return [];
  }
}

export async function listTokenMarketplace(
  account: string,
  web3: Web3,
  id: string,
  listingType: string,
  priceETH: string,
  endingPrice: string,
  duration: number,
  priceType: Number,
  addressPayment: string,
  contractaddress: string,
  decimals: number,
) {
  const contract = loadListingContract(web3);

  const ListingParams = {
    nftContract: contractaddress,
    tokenId: id,
    listingType: listingType,
    listingPrice: convertToWei(priceETH, decimals),
    endingPrice: convertToWei(endingPrice, decimals),
    duration: duration,
    priceType: priceType,
    tokenPayment: addressPayment,
  };

  try {
    const latestGasPrice: any = await getGasPrice(web3);
    const txn = await contract.methods.createListing(ListingParams).send({
      from: account,
      value: '0',
      ...latestGasPrice,
    });

    const hashedKey = getHashKey(contractaddress, id, web3);
    const listing = await contract.methods.getListingByNFTKey(hashedKey).call();
    const startAt = listing.listingType === "0" ? "0" : await getTimeStampFromBlockNumber(web3, listing?.startedAt);
    
    return {
      duration: duration,
      endingPrice: convertToWei(endingPrice, decimals),
      key: hashedKey,
      listingPrice: convertToWei(priceETH, decimals),
      listingType: listingType,
      nftContract: contractaddress,
      priceType: priceType,
      seller: account,
      startedAt: startAt.toString(),
      tokenId: id,
      tokenPayment: addressPayment,
    } as any;
  } catch (error) {
    throw error;
  }
}

export async function listTokenMarketplaceBulk(
  account: string,
  web3: Web3,
  id: string,
  listingType: string,
  priceETH: string,
  endingPrice: string,
  duration: number,
  priceType: Number,
  addressPayment: string,
  contractaddress: string,
  tokenIds: number[],
) {
  const contract = loadListingContract(web3);
  let decimals = 18;
  if (priceType == 1) {
    const tokenContract = loadERC20Contract(web3, addressPayment);
    decimals = await tokenContract.methods.decimals().call();
  }
  const ListingParams = {
    nftContract: contractaddress,
    tokenId: 1,
    listingType: listingType,
    listingPrice: convertToWei(priceETH, decimals),
    endingPrice: convertToWei(endingPrice, decimals),
    duration: duration,
    priceType: priceType,
    tokenPayment: addressPayment,
  };

  try {
    const latestGasPrice: any = await getGasPrice(web3);
    const txn = await contract.methods.createBulkListing(ListingParams, tokenIds).send({
      from: account,
      value: '0',
      ...latestGasPrice,
    });
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function cancelListing(account: string, web3: Web3, key: string) {
  try {
    33;
    const contract = loadListingContract(web3);
    const latestGasPrice: any = await getGasPrice(web3);
    const txn = await contract.methods.cancelListingByKey(key).send({
      from: account,
      ...latestGasPrice,
    });
    return txn;
  } catch (error) {
    throw error;
  }
}

export async function makeOffer(account: string, web3: Web3, key: string, offerPrice: string) {
  const contract = loadListingContract(web3);
  const oPrice = web3.utils.toWei(offerPrice, 'ether');
  const latestGasPrice: any = await getGasPrice(web3);
  const txn = await contract.methods
    .offer(key, oPrice)
    .send({
      from: account,
      value: oPrice,
      ...latestGasPrice,
    })
    .on('confirmation', (confirmationNumber, receipt) => {
      //resolve(receipt.transactionHash);
    })
    .on('error', (error, receipt) => {
      if (receipt !== undefined) throw receipt.transactionHash;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });

  return txn;
}

export async function makeOfferERC20(
  account: string,
  web3: Web3,
  nftDetails: any,
  offerPrice: string,
) {
  const contract = loadListingContract(web3);

  try {
    const decimals = getPaymentTokenDecimalsByAddress(nftDetails.tokenPayment);
    const oPrice = convertToWei(offerPrice, decimals); //  web3.utils.toWei(offerPrice, 'ether');
    const latestGasPrice: any = await getGasPrice(web3);
    const txn = await contract.methods.offer(nftDetails.hashedKey, oPrice).send({
      from: account,
      ...latestGasPrice,
    });

    return txn;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export async function extendListing(account: string, web3: Web3, key: string) {
  const contract = loadListingContract(web3);

  const latestGasPrice: any = await getGasPrice(web3);
  const txn = await contract.methods
    .extendListing(key)
    .send({
      from: account,
      value: '0',
      ...latestGasPrice,
    })
    .on('confirmation', (confirmationNumber, receipt) => {
      //resolve(receipt.transactionHash);
    })
    .on('error', (error, receipt) => {
      if (receipt !== undefined) throw receipt.transactionHash;
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });

  try {
    const listingData = await contract.methods.getListingByNFTKey(key).call();
    return listingData;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export async function buyToken(account: string, web3: Web3, key: string, price: Number) {
  try {
    const contract = loadListingContract(web3);
    const latestGasPrice: any = await getGasPrice(web3);
    const txn = await contract.methods.bid(key).send({
      from: account,
      value: convertToWei(price.toString()),
      ...latestGasPrice,
    });
    return txn;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function buyWithERC20(account: string, web3: Web3, key: string) {
  const contract = loadListingContract(web3);
  const latestGasPrice: any = await getGasPrice(web3);
  try {
    const txn = await contract.methods.bid(key).send({
      from: account,
      value: 0,
      ...latestGasPrice,
    });
    return txn;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export async function getListingInfo(web3: Web3, key: string) {
  const contract = loadListingContract(web3);
  try {
    const listing = await contract.methods.getListingByNFTKey(key).call();
    const startAt = listing.listingType === "0" ? "0" : await getTimeStampFromBlockNumber(web3, listing?.startedAt);
    const txn = {
      duration: listing.duration,
      endingPrice: listing.endingPrice,
      id: listing.id,
      key: listing.key,
      listingPrice: listing.listingPrice,
      listingType: listing.listingType,
      nftContract: listing.nftContract,
      priceType: listing.priceType,
      seller: listing.seller,
      startedAt: startAt.toString(),
      tokenId: listing.tokenId,
      tokenPayment: listing.tokenPayment,
    } as any;
    return txn;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function getListingByListingId(web3: Web3, auctionId: string) {
  const contract = loadListingContract(web3);

  try {
    const txn = await contract.methods.getListingByListingId(auctionId).call();
    return txn;
  } catch (e) {
    return null;
  }
}

export async function getCurrentPriceByKey(web3: Web3, key: string, decimals: number = 18) {
  const contract = loadListingContract(web3);

  try {
    const txn = await contract.methods.getCurrentPriceByKey(key).call();
    if (txn) {
      return convertFromWei(txn, decimals);
    }
  } catch (e) {
    return null;
  }
}

export async function isAuctionExpired(web3: Web3, key: string) {
  const contract = loadListingContract(web3);

  try {
    const txn = await contract.methods.isAuctionExpired(key).call();
    return txn;
  } catch (e) {
    return null;
  }
}

export async function getHighestOffer(web3: Web3, key: string, decimals: number) {
  const contract = loadListingContract(web3);
  try {
    const txn = await contract.methods.highestOffer(key).call();
    const oPrice = convertFromWei(txn.offerPrice, decimals);
    const buyer = txn.buyer;
    return {
      oPrice,
      buyer,
    };
  } catch (e) {
    return null;
  }
}

export async function getOffersHistories(web3: Web3, key: string): Promise<Nft.OfferHistory[]> {
  const contract = loadListingContract(web3);

  try {
    const txn = await contract.methods.getOffersHistories(key).call();

    return txn;
  } catch (error) {
    console.log(error);
    //return null;
    throw error;
  }
}

export async function getWithdrawals(web3: Web3, account: string): Promise<Nft.OfferWithdrawal[]> {
  const contract = loadListingContract(web3);

  try {
    const txn = await contract.methods.getPendingWithdraws(account).call();
    return txn;
  } catch (error) {
    console.log(error);
    //return null;
    throw error;
  }
}

export async function acceptOffer(account: string, web3: Web3, key: string) {
  const contract = loadListingContract(web3);

  try {
    const latestGasPrice: any = await getGasPrice(web3);
    const txn = await contract.methods.acceptOffer(key).send({
      from: account,
      ...latestGasPrice,
    });

    return txn;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export async function WithdrawRefunds(account: string, web3: Web3) {
  const contract = loadListingContract(web3);

  try {
    const latestGasPrice: any = await getGasPrice(web3);
    const txn = await contract.methods.withdrawRefunds().send({
      from: account,
      ...latestGasPrice,
    });

    return txn;
  } catch (e) {
    console.log(e);
    throw e;
  }
}

export async function extendBulkListing(account: string, web3: Web3, keys: string[]) {
  const contract = loadListingContract(web3);

  try {
    const latestGasPrice: any = await getGasPrice(web3);
    const txn = await contract.methods.extendBulkListing(keys).send({
      from: account,
      ...latestGasPrice,
    });

    return txn;
  } catch (e) {
    console.log(e);
    throw e;
  }
}
