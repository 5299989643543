import { getTokenIcon, getPaymentTokenDecimalsByAddress } from 'src/utils/helper';
import React, { useContext, useEffect, useState } from 'react';
import { convertFromWei } from 'src/utils/blockchainInteractor';

import _ from 'lodash';
import { AppContext } from 'src/contexts/AppContext';
import { useTranslation } from 'react-i18next';
import { xwinaddress, bnbaddress, usdtaddress } from 'src/constant';
import { displayBalance, getPriceInUSD } from 'src/utils/helper';
import Accordion from '../components/Accordion';

const CollectionByCollStats = ({ allSalesHistory, allMinPrices, collection, prices }) => {
  const { t } = useTranslation(['page.marketplace']);
  const { account, web3 } = useContext(AppContext);

  const [stats, setStats] = useState({
    floorPrice: 0,
    floorPriceXWIN: 0,
    floorPriceUSDT: 0,
    totalSaleValue: 0,
    totalSaleValueXWIN: 0,
    totalSaleValueUSDT: 0,    
  });

  useEffect(() => {
    init();
  }, [allSalesHistory, allMinPrices]);

  const init = async () => {
      setStats({
        floorPrice: 0,
        floorPriceXWIN: 0,
        floorPriceUSDT: 0,
        totalSaleValue: 0,
        totalSaleValueXWIN: 0,
        totalSaleValueUSDT: 0,
      });

      const totalValueSold = getSalesAmount(bnbaddress);
      const totalValueSoldXWIN = getSalesAmount(xwinaddress);
      const totalValueSoldUSDT = getSalesAmount(usdtaddress);
      
      const floorPriceBNB = getFloorPrice(bnbaddress);
      const floorPriceXWIN = getFloorPrice(xwinaddress);
      const floorPriceUSDT = getFloorPrice(usdtaddress);

      const bnbDecimals = getPaymentTokenDecimalsByAddress(bnbaddress);
      const xwinDecimals = getPaymentTokenDecimalsByAddress(xwinaddress);
      const usdtDecimals = getPaymentTokenDecimalsByAddress(usdtaddress);
      

      setStats({
        totalSaleValue: Number(convertFromWei(totalValueSold.toString(), bnbDecimals)),
        totalSaleValueXWIN: Number(convertFromWei(totalValueSoldXWIN.toString(), xwinDecimals)),
        totalSaleValueUSDT: Number(convertFromWei(totalValueSoldUSDT.toString(), usdtDecimals)),
        floorPrice: Number(convertFromWei(floorPriceBNB.toString(), bnbDecimals)),
        floorPriceXWIN: Number(convertFromWei(floorPriceXWIN.toString(), xwinDecimals)),
        floorPriceUSDT: Number(convertFromWei(floorPriceUSDT.toString(), usdtDecimals)),
      });
      return;
    }
    
    
  const getSalesAmount = (tokenPayment: string) => {
    
    const selected = allSalesHistory.find((x) => x.userCollectionId === collection._id && x.tokenPayment.toLowerCase() === tokenPayment.toLowerCase());
    return selected?.totalSalesBalance === undefined ? "0" : selected?.totalSalesBalance.toString()

  };

  const getFloorPrice = (tokenPayment: string) => {
    const selected = allMinPrices.find((x) => x.userCollectionId === collection._id && x.tokenPayment.toLowerCase() === tokenPayment.toLowerCase());
    return selected?.minPrice === undefined ? "0" : selected?.minPrice.toString()
  };

  const displayInUSD = (currentPrice, tokenPayment) => {
    return getPriceInUSD(prices, currentPrice, tokenPayment);
  };

  return (
    <>
      <div className="row text-center">
        <div className="col">
          {t('Items')}
          <h4>{collection?.nftCount || 0}</h4>
        </div>
        <div className="col">
          {t('Owners')}
          <h4>{collection?.ownerCount || 0}</h4>
        </div>
      </div>

      <Accordion defaultOpen={false} className="mt-4 " icon="fa fa-align-left" title={t('Volume Traded')}>
        <div className="row text-center">
          <div className="col">
            <img
              src={getTokenIcon(bnbaddress)}
              className="my-3 pr-3"
              style={{ height: '1.45rem' }}
            />
            <h4>{displayBalance(false, stats.totalSaleValue.toFixed(3))}</h4>
            ~${displayInUSD(stats.totalSaleValue, bnbaddress)}
          </div>
          <div className="col">
            <img
              src={getTokenIcon(xwinaddress)}
              className="my-3 pr-3"
              style={{ height: '1.45rem' }}
            />
            <h4>{displayBalance(false, stats.totalSaleValueXWIN.toFixed(3))}</h4>
            ~${displayInUSD(stats.totalSaleValueXWIN, xwinaddress)}
          </div>
          <div className="col">
            <img
              src={getTokenIcon(usdtaddress)}
              className="my-3 pr-3"
              style={{ height: '1.45rem' }}
            />
            <h4>{displayBalance(false, stats.totalSaleValueUSDT.toFixed(3))}</h4>
            ~${displayInUSD(stats.totalSaleValueUSDT, usdtaddress)}
          </div>
        </div>
      </Accordion>
      <Accordion defaultOpen={false} className="mt-4 " icon="fa fa-align-left" title={t('Floor Price')}>
        <div className="row text-center">
          <div className="col">
            <img
              src={getTokenIcon(bnbaddress)}
              className="my-3 pr-3"
              style={{ height: '1.45rem' }}
            />
            <h4>{displayBalance(false, stats.floorPrice.toFixed(3))}</h4>
            ~${displayInUSD(stats.floorPrice, bnbaddress)}
          </div>
          <div className="col">
            <img
              src={getTokenIcon(xwinaddress)}
              className="my-3 pr-3"
              style={{ height: '1.45rem' }}
            />
            <h4>{displayBalance(false, stats.floorPriceXWIN.toFixed(3))}</h4>
            ~${displayInUSD(stats.floorPriceXWIN, xwinaddress)}
          </div>
          <div className="col">
            <img
              src={getTokenIcon(usdtaddress)}
              className="my-3 pr-3"
              style={{ height: '1.45rem' }}
            />
            <h4>{displayBalance(false, stats.floorPriceUSDT.toFixed(3))}</h4>
            ~${displayInUSD(stats.floorPriceUSDT, usdtaddress)}
          </div>
        </div>
      </Accordion>
    </>
  );
};
export default CollectionByCollStats;
