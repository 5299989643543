import { capitalize } from 'lodash';
import moment from 'moment';

type Props = {
  active?: boolean;
  payload?: any;
  label?: string | number;
  dataKey: {
    x: string;
    y: string;
  };
};

const CustomTooltip = (props: Props) => {
  if (props.active && props.payload && props.payload.length) {
    return (
      <div className="custom-tooltip">
        <div className="card">
          <div className="rounded-card-body card-body">
            <p className="mb-2">{`${moment(props.label).format('DD/MM/YYYY')}`}</p>
            <p>{`${capitalize(props.dataKey.y)}: ${props.payload[0].payload[props.dataKey.y]}`}</p>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
