import { getPaginatedNftMeta } from '../services/metaService';
import { ListingType } from '../constant';

export const getPaginatedNFTs = async ({
  tokenIDs,
  categories,
  paymentTypes,
  listingType,
  ownerAddress,
  page,
  perPage,
  keyWord,
  collectioName,
 }: {
  tokenIDs?: string[],
  categories?: string[],
  paymentTypes?: string[],
  listingType?: ListingType[],
  ownerAddress?: string | null,
  page?: number,
  perPage?: number,
  keyWord?: string | null,
  collectioName?: string | null,
}): Promise<Nft.NftPaginatedMeta> => {
  return (
    await getPaginatedNftMeta(
      tokenIDs,
      categories,
      paymentTypes,
      listingType,
      ownerAddress,
      page,
      perPage,
      keyWord,
      collectioName,
    )
  )?.data;
};
