import confetti from 'canvas-confetti';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserContext from '../contexts/UserContext';
import { displayMessage, getTokenSymbol, resolvePromise } from '../utils/helper';
import { AppContext } from '../contexts/AppContext';
import Accordion from '../components/Accordion';
import WithdrawalFAQ from '../components/FAQ/WithdrawalFAQ';
import { CommonRoutes } from '../constant';
import WithdrawCard from '../components/WithdrawCard';
import { getWithdrawals, WithdrawRefunds } from '../utils/newMarketPlaceInteractor';
import ProgressLoader from '../components/ProgressLoader';

const Withdrawal = (props) => {
  const { t } = useTranslation(['page.import-nft', 'common']);
  const history = useHistory();
  const { account, web3 } = useContext(AppContext);
  const userContext = useContext(UserContext);
  const { handleStart, state } = userContext;
  const { user } = state;
  const [isLoading, setIsLoading] = useState({
    loading: false,
    type: 'page',
    message: '',
  });
  const [withdrawals, setWithdrawals] = useState<Nft.OfferWithdrawal[]>();
  const [isCardLoading, setIsCardLoading] = useState(false);
  
  useEffect(() => {
    init();
  }, [account]);

  const init = async () => {
    if (!account && !user) {
      history.replace(CommonRoutes.MARKETPLACE);
      return;
    }
    const res = await getWithdrawals(web3, account);
    setWithdrawals(res || []);
  };

  const handleWithdraw = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
    }

    try {
      setIsLoading({
        loading: true,
        type: 'page',
        message: t('component:withdrawing-fund'),
      });
      const [txn, err] = await resolvePromise(WithdrawRefunds(account, web3));
      if (!err) {
        confetti();
        init();
      }else{
        displayMessage(err, t)
      }
      return;
    } catch (error) {
      toast.error(t('common:toast.something-went-wrong'));
    } finally {
      setIsLoading({ loading: false, type: 'page', message: '' });
    }
  };

  return (
    <>
      <ProgressLoader
        loading={isLoading.loading}
        type={isLoading.type}
        message={isLoading.message}
      />
      <div className="spacer-20"></div>
      <section className="container">
        <div className="d-flex mt-4  w-100 justify-content-center align-content-center">
          <div className="mr-4 w-20">
            <button
              className="btn-main"
              disabled={withdrawals?.length == 0}
              onClick={handleWithdraw}
            >
              {t('component:withdraw-fund')}
            </button>
          </div>
        </div>

        <Accordion
          alwaysOpen
          className="mt-4 "
          icon="fa fa-file-image-o"
          title={
            isCardLoading
              ? 'Loading...'
              : withdrawals?.length == 0
              ? t('component:no-bid-refund')
              : t('component:withdraw-fund')
          }
        >
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 offset-xl-0 offset-lg-0 offset-md-0">
              <div>
                <div className="easy-box">
                  <div className="row"></div>
                  <div className="row">
                    {withdrawals?.map((w) => (
                      <WithdrawCard hashedKey={w.key} withdrawal={w} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Accordion>
        <div className="spacer-40"></div>

        <WithdrawalFAQ />
      </section>
    </>
  );
};

export default Withdrawal;
