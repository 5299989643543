import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import confetti from 'canvas-confetti';
import ProgressLoader from '../components/ProgressLoader';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import TextField from '@mui/material/TextField';

import useLoader from '../hooks/useLoader';
import { approveToken, checkIfApproved } from '../utils/erc721Interactor';
import { listTokenMarketplace } from '../utils/newMarketPlaceInteractor';
import { getTimeStampFromBlockNumber } from '../utils/blockchainInteractor';
import tokenConfig from '../configs/tokenConfig';
import { getPaymentTypesList } from '../services/paymentTypesService';
import { resolvePromise, getListingTypeMapToMongo, getPaymentTokenDecimalsByAddress, displayMessage } from '../utils/helper';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../contexts/AppContext';
import { updateMeta } from '../services/metaService';
import { values } from 'lodash';
import { duration } from 'moment';
import { useTheme } from '../contexts/ThemeContext';

const ListForm = ({ hashedKey, tokenId, nftDetails, reset }) => {
  const { themeType } = useTheme();
  const { t } = useTranslation(['component']);
  const { account, web3 } = useContext(AppContext);
  const [darkTheme] = useState(themeType == 'dark');
  const [isApproving, setIsApproving] = useState(true);
  const [listingTitle, setListingTitle] = useState('Fixed Price Listing');
  const [listingInfo, setListingInfo] = useState({
    symbol: tokenConfig.default.token,
    address: tokenConfig.default.address,
    price: '1.0',
    priceType: 0,
    listingType: '0',
    startPrice: '1.0',
    endPrice: '0.50',
    duration: 1210000,
  });
  const [isLoading, setIsLoading] = useState({
    loading: false,
    type: 'page',
    message: '',
  });

  const [nftPaymentTypes, setNftPaymentTypes] = useState<Nft.NftPaymentType[]>([]);
  const [val, setValue] = React.useState<Date | null>(new Date());

  const currentDate = Date.now();

  // const newValue = val?.getTime()

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
      return;
    }
    try {
      setIsLoading({ loading: true, type: 'page', message: '' });

      const [nftPaymentTypesResponse, getPaymentTypesError] = await resolvePromise(
        getPaymentTypesList(),
      );
      if (nftPaymentTypesResponse && !getPaymentTypesError) {
        setNftPaymentTypes(
          (nftPaymentTypesResponse?.data?.data || []).filter((d) => d.priority !== 0),
        );
      }
      let isApproved = await checkIfApproved(web3, tokenId, nftDetails.contractAddress);
      setIsApproving(isApproved);
      // setValue(Date.now() + 14)
      setValue(new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000));
      onListingTypeClick('0');
    } catch (e) {
      console.log(e);
      //toast.error('Please Approve Token!');
    } finally {
      setIsLoading({ loading: false, type: 'page', message: '' });
      // hideLoader();
    }
  };
  const approveNFT = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
      return;
    }
    
    try {
      setIsLoading({
        loading: true,
        type: 'page',
        message: t('component:enable-nft'),
      });

      const [app, appError] = await resolvePromise(
        approveToken(account, web3, tokenId, nftDetails.contractAddress),
      );
      if (!appError) {
        setIsApproving(true);
        await list();
      }else{
        displayMessage(appError, t)
      }
    } catch (e) {
      console.error(e);
      toast.error('Something went wrong!');
    } finally {
      setIsLoading({ loading: false, type: 'page', message: '' });
    }
  };

  const validate = async () => {
    if (listingInfo.listingType == '1' && Number(listingInfo.startPrice) <= 0) {
      toast.error(t('component:input-starting-price'));
      return false;
    }
    if (listingInfo.listingType == '1' && Number(listingInfo.endPrice) <= 0) {
      toast.error(t('component:input-end-price'));
      return false;
    }
    if (listingInfo.listingType == '1' && listingInfo.startPrice <= listingInfo.endPrice) {
      toast.error(t('component:startprice-endprice'));
      return false;
    }
    if (listingInfo.listingType == '2' && Number(listingInfo.startPrice) <= 0) {
      toast.error(t('component:input-reserve-price'));
      return false;
    }
    if (listingInfo.listingType == '0' && Number(listingInfo.price) <= 0) {
      toast.error(t('component:input-listing-price'));
      return false;
    }
    if (
      (listingInfo.listingType == '1' || listingInfo.listingType == '2') &&
      Number(listingInfo.duration) <= 0
    ) {
      toast.error(t('component:input-duration'));
      return false;
    }

    let isApproved = await checkIfApproved(web3, tokenId, nftDetails.contractAddress);
    if (!isApproved) {
      toast.error(t('component:not-approve-nft'));
      return false;
    }

    return true;
  };

  const list = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
      return;
    }
    if (await !validate()) return;

    try {
      setIsLoading({
        loading: true,
        type: 'page',
        message: t('component:listing-nft'),
      });
      let priceType = getPriceType(listingInfo.symbol);
      const decimals = getPaymentTokenDecimalsByAddress(listingInfo.address);
      if (listingInfo.listingType == '0') {
        const [listingData, listError] = await resolvePromise(
          listTokenMarketplace(
            account,
            web3,
            tokenId,
            listingInfo.listingType,
            listingInfo.price,
            '0',
            0,
            priceType,
            listingInfo.address,
            nftDetails.contractAddress,
            decimals
          ),
        );
        if (!listError) {
          await resolvePromise(
            updateMeta(hashedKey, {
              paymentType: listingInfo.symbol,
              listingType: getListingTypeMapToMongo(listingInfo.listingType),
              // auctionId: listingData.id,
              priceType: listingData.priceType,
              tokenPayment: listingData.tokenPayment,
              price: listingInfo.price,
            }),
          );
          confetti();
          reset();
        }else{
          displayMessage(listError, t)
        }
      } else if (listingInfo.listingType == '1') {
        const [listingData, listError] = await resolvePromise(
          listTokenMarketplace(
            account,
            web3,
            tokenId,
            listingInfo.listingType,
            listingInfo.startPrice.toString(),
            listingInfo.endPrice.toString(),
            listingInfo.duration,
            priceType,
            listingInfo.address,
            nftDetails.contractAddress,
            decimals
          ),
        );
        if (!listError) {
          await resolvePromise(
            updateMeta(hashedKey, {
              paymentType: listingInfo.symbol,
              listingType: getListingTypeMapToMongo(listingInfo.listingType),
              price: null,
              priceType: priceType.toString(),
              tokenPayment: listingData.tokenPayment,
              duration: listingData.duration, //listingInfo.duration.toString(),
              reservePrice: null,
              auctionId: listingData.id,
              startedAt: listingData.startedAt,
              endingPrice: listingInfo.endPrice.toString(),
              startingPrice: listingInfo.startPrice.toString(),
            }),
          );
          confetti();
          reset();
        }else{
          displayMessage(listError, t)
        }
      } else if (listingInfo.listingType == '2') {
        const [listingData, listError] = await resolvePromise(
          listTokenMarketplace(
            account,
            web3,
            tokenId,
            listingInfo.listingType,
            listingInfo.startPrice.toString(),
            '0',
            listingInfo.duration,
            priceType,
            listingInfo.address,
            nftDetails.contractAddress,
            decimals
          ),
        );
        if (!listError) {
          await resolvePromise(
            updateMeta(hashedKey, {
              paymentType: listingInfo.symbol,
              listingType: getListingTypeMapToMongo(listingInfo.listingType),
              price: listingInfo.startPrice.toString(),
              priceType: priceType.toString(),
              tokenPayment: listingData.tokenPayment,
              duration: listingData.duration, //listingInfo.duration.toString(),
              reservePrice: listingInfo.startPrice.toString(),
              auctionId: listingData.id,
              startedAt: listingData.startedAt,
              endingPrice: null,
              startingPrice: null,
            }),
          );
          confetti();
          reset();
        }else{
          displayMessage(listError, t)
        }
      }
    } catch (e) {
      console.error(e);
      toast.error('Something went wrong!');
    } finally {
      setIsLoading({ loading: false, type: 'page', message: '' });
    }
  };

  const setPaymentAddress = (event) => {
    let symbol = event.target.value;
    const selected = nftPaymentTypes.find((t) => t.name == symbol);
    setListingInfo({
      ...listingInfo,
      address: selected?.address ?? '',
      symbol: symbol.toUpperCase(),
    });
  };

  const onListingTypeClick = (value) => {
    setListingInfo({
      ...listingInfo,
      listingType: value,
    });

    document.getElementById('Tabbtn1')?.classList.remove('active1');
    document.getElementById('Tabbtn1')?.classList.remove('active');

    document.getElementById('Tabbtn2')?.classList.remove('active1');
    document.getElementById('Tabbtn2')?.classList.remove('active');

    document.getElementById('Tabbtn3')?.classList.remove('active1');
    document.getElementById('Tabbtn3')?.classList.remove('active');

    // document.getElementById("Tabbtn1")?.classList.remove("active");
    // document.getElementById("Tabbtn2")?.classList.remove("active");
    // document.getElementById("Tabbtn3")?.classList.remove("active");
    if (value === '0') {
      {
        document.getElementById('Tabbtn3')?.classList.add(darkTheme ? 'active' : 'active1');
      }
    } else if (value === '1') {
      {
        document.getElementById('Tabbtn2')?.classList.add(darkTheme ? 'active' : 'active1');
      }
    } else if (value === '2') {
      {
        document.getElementById('Tabbtn1')?.classList.add(darkTheme ? 'active' : 'active1');
      }
    }

    // if (value === "0") {
    //   document.getElementById("Tabbtn3")?.classList.add("active");
    // } else if (value === "1") {
    //   document.getElementById("Tabbtn2")?.classList.add("active");
    // } else if (value === "2") {
    //   document.getElementById("Tabbtn1")?.classList.add("active");
    // }

    const title =
      value === '0'
        ? t('Fixed Price Listing')
        : value === '1'
        ? t('Declining Price Listing')
        : t('Open for Bids Listing');
    setListingTitle(title);
  };

  const getPriceType = (symbol) => {
    const selected = tokenConfig.paymentTokens.find((t) => t.name == symbol);
    console.log(selected)
    return selected == undefined ? 0 : selected?.priceType;
  };

  const handleChange = (e) => {
    const updated = { [e.target.name]: e.target.value };
    setListingInfo({
      ...listingInfo,
      ...updated,
    });
  };

  const handleDate = (event) => {
    let newDate = event.target.value;
    setListingInfo({
      ...listingInfo,
      ...newDate,
    });
  };

  const getTotalDuration = (newValue) => {
    let newDate = newValue.getTime();
    setValue(newDate);

    const totalDuration = Math.abs(newDate / 1000 - currentDate / 1000);
    const finalTotalDuration = Math.round(totalDuration);
    setListingInfo({
      ...listingInfo,
      duration: finalTotalDuration,
    });
  };

  return (
    <>
      <ProgressLoader
        loading={isLoading.loading}
        type={isLoading.type}
        message={isLoading.message}
      />
      <div className="wallet-detected-info">
        <ul className="de_nav">
          <li id="Tabbtn1" onClick={() => onListingTypeClick('2')}>
            <span>
              <i className={'fa fa-users '} />
            </span>
          </li>
          <li id="Tabbtn2" className="" onClick={() => onListingTypeClick('1')}>
            <span>
              <i className="fa fa-hourglass" />
            </span>
          </li>
          <li
            id="Tabbtn3"
            className={darkTheme ? 'active' : 'active1'}
            onClick={() => onListingTypeClick('0')}
          >
            <span>
              <i className="fa fa-tag"></i>
            </span>
          </li>
        </ul>

        <div className="mt-4">
          <h3>{listingTitle}</h3>
        </div>
        <div className="detailcheckout mt-4">
          <div className="listcheckout">
            <h6>{t('list-form.label.token-type')}</h6>
            <select
              className="form-control"
              value={listingInfo.symbol}
              onChange={setPaymentAddress}
              name="paymetType"
            >
              <option disabled value="">
                Select Payment Type
              </option>
              {nftPaymentTypes.map((type) => {
                return (
                  <option key={type._id} value={type.name}>
                    {type.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        {listingInfo?.listingType === '0' && (
          <div className="detailcheckout mt-3">
            <div className="listcheckout">
              <h6>{t('list-form.label.amount', { token: listingInfo.symbol })}</h6>
              <input
                className="form-control"
                type="text"
                name="walletAddress"
                value={listingInfo.price}
                placeholder="0.50"
                onChange={({ currentTarget }) =>
                  setListingInfo({
                    ...listingInfo,
                    price: currentTarget.value,
                  })
                }
              />
            </div>
          </div>
        )}

        {listingInfo?.listingType === '1' && (
          <div>
            <div className="detailcheckout mt-3">
              <div className="listcheckout">
                <h6>
                  {t('component:auction-form.label.start-price', {
                    token: listingInfo.symbol,
                  })}
                </h6>
                <input
                  value={listingInfo.startPrice}
                  onChange={handleChange}
                  className="form-control"
                  type="text"
                  name="startPrice"
                  placeholder="1.0"
                />
              </div>
            </div>
            <div className="detailcheckout mt-3">
              <div className="listcheckout">
                <h6>
                  {t('component:auction-form.label.end-price', {
                    token: listingInfo.symbol,
                  })}
                </h6>
                <input
                  value={listingInfo.endPrice}
                  onChange={handleChange}
                  className="form-control"
                  type="text"
                  name="endPrice"
                  placeholder="0.5"
                />
              </div>
            </div>
            <div className="detailcheckout mt-3">
              <div className="listcheckout">
                <h6>{t('component:auction-form.label.duration')}</h6>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    disablePast
                    value={val}
                    onChange={getTotalDuration}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                {/* <input
                  value={listingInfo.duration}
                  onChange={handleChange}
                  className="form-control"
                  type="text"
                  name="duration"
                /> */}
              </div>
            </div>
          </div>
        )}
        {listingInfo?.listingType === '2' && (
          <div>
            <div className="detailcheckout mt-3">
              <div className="listcheckout">
                <h6>
                  {t('component:auction-english-form.label.reserve-price', {
                    token: listingInfo.symbol,
                  })}
                </h6>
                <input
                  value={listingInfo.startPrice}
                  onChange={handleChange}
                  className="form-control"
                  type="text"
                  name="startPrice"
                />
              </div>
            </div>
            <div className="detailcheckout mt-3">
              <div className="listcheckout">
                <h6>{t('component:auction-form.label.duration')}</h6>
                {/* <input
                  value={listingInfo.duration}
                  onChange={handleChange}
                  className="form-control"
                  type="text"
                  name="duration"
                /> */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    disablePast
                    value={val}
                    onChange={getTotalDuration}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-center mt-2">
          {isApproving ? (
            ''
          ) : (
            <button
              onClick={approveNFT}
              className={`btn-main1 ${darkTheme ? 'green' : 'purple'} m-1`}
            >
              {t('Approve NFT')}
            </button>
          )}
          {isApproving ? (
            <button onClick={list} className={`btn-main1 ${darkTheme ? 'green' : 'purple'} m-1`}>
              {t('Sell NFT')}
            </button>
          ) : (
            <button className="btn-disable  m-1">{t('Sell NFT')}</button>
          )}

          {/* <button
            onClick={isApproving ? list : approveNFT}
            className="btn-main lead mb-5"
          >
            {isApproving ? t("Sell NFT") : t("Approve NFT")}
          </button> */}
        </div>
      </div>
    </>
  );
};

export default ListForm;
