import { useTranslation } from 'react-i18next';
import { ListingType } from '../constant';
import CollectionStats from '../components/CollectionStats';
import CollectionByCollStats from '../components/CollectionByCollStats';

interface Props {
  showCategoryFilter?: boolean;
  showOtherListingType?: boolean;
  showMultipleListing?: boolean;
  showStats?: boolean;
  showBulk?: boolean;
  showBulkExtend?: boolean;
  listingTypeOnChange: any;
  categoryTypeOnChange: any;
  openListBulk: any;
  performBulkExtend: any;
  clearListingFilter: any;
  changeBulk: any;
  changeBulkExtend: any;
  nftCategories: Nft.NftCategory[];
  catFilters: string[];
  payFilters: string[];
  nftPaymentTypes: Nft.NftPaymentType[];
  paymentTypeOnChange: any;
  nftaddress: any;
  allSalesHistory: Nft.SalesHistory[];
  allMinPrices: Nft.AuctionHistory[];
  allMinPricesByCollection: Nft.AuctionHistoryByCollection[];
  allSalesHistoryByCollection: Nft.SalesHistoryByCollection[];
  collection: any;
  prices: any;
}

const MarketplaceFilter = (props: Props) => {
  const { t } = useTranslation(['component']);
  // const [showBulk, setShowBulk] = useState(false);

  const {
    showCategoryFilter,
    showOtherListingType,
    showMultipleListing,
    listingTypeOnChange,
    categoryTypeOnChange,
    clearListingFilter,
    changeBulk,
    changeBulkExtend,
    openListBulk,
    performBulkExtend,
    nftCategories,
    catFilters,
    showStats,
    showBulk,
    showBulkExtend,
    payFilters,
    nftPaymentTypes,
    paymentTypeOnChange,
    nftaddress,
    allSalesHistory,
    allMinPrices,
    allMinPricesByCollection,
    allSalesHistoryByCollection,
    collection,
    prices,
  } = props;

  return (
    <>
      {showMultipleListing && (
        <div className="item_filter_group">
          {/* <div className="de_form">
          <div className="de_checkbox" key={99}>
            <input
                id={"99"}
                type="checkbox"
                checked={showBulk}
                onChange={e => changeBulk(e)}
              />
              <label htmlFor={"99"}>{t("marketplace-filter.label.list-bulk")}</label>
          </div>
          </div>
          {
            showBulk && (
              <div className="de_form">
                <button onClick={openListBulk} className="btn-main">
                    {t("marketplace-filter.label.upload-bulk-listing")}
                  </button>
              </div>
            )
          }
          <div className="spacer-20"></div> */}
          <div className="de_form">
            <div className="de_checkbox" key={98}>
              <input
                id={'98'}
                type="checkbox"
                checked={showBulkExtend}
                onChange={(e) => changeBulkExtend(e)}
              />
              <label htmlFor={'98'}>{t('marketplace-filter.label.extend-bulk')}</label>
            </div>
          </div>
          {showBulkExtend && (
            <div className="de_form">
              <button onClick={performBulkExtend} className="btn-main">
                {t('marketplace-filter.label.extend-bulk-listing')}
              </button>
            </div>
          )}
        </div>
      )}

      {showCategoryFilter && (
        <div className="item_filter_group">
          <h4>{t('marketplace-filter.label.categories')}</h4>
          <div className="de_form">
            {nftCategories.map((item) => {
              return (<div className="de_checkbox" key={item._id}>
                <input
                  checked={catFilters.includes(item.category)}
                  id={item._id}
                  type="checkbox"
                  onChange={() => categoryTypeOnChange(item.category)}
                />
                <label htmlFor={item._id}>{item.category}</label>
              </div>)
            })}
          </div>
        </div>
      )}

      {showStats && (
        <div className="item_filter_group">
          <div className="de_form">
            <CollectionStats
              catFilters={catFilters}
              nftCategories={nftCategories}
              allSalesHistory={allSalesHistory}
              allMinPrices={allMinPrices}
              prices={prices}
            />
          </div>
        </div>
      )}

      {allMinPricesByCollection.length > 0 && (
        <div className="item_filter_group">
          <div className="de_form">
            <CollectionByCollStats
              // catFilters={catFilters}
              // nftCategories={nftCategories}
              allSalesHistory={allSalesHistoryByCollection}
              allMinPrices={allMinPricesByCollection}
              collection={collection}
              prices={prices}
            />
          </div>
        </div>
      )}

      <div className="item_filter_group">
        <h4>{t('marketplace-filter.label.listing-type')}</h4>
        <div className="de_form">
          <div className="de_checkbox">
            <input
              onChange={() => listingTypeOnChange(ListingType.ENGLISH_AUCTION)}
              id="styled-checkbox-10"
              type="checkbox"
              defaultValue="value1"
            />
            <label htmlFor="styled-checkbox-10">
              <span>{t('Open Bid')}</span>
            </label>
          </div>
          <div className="de_checkbox">
            <input
              onChange={() => listingTypeOnChange(ListingType.AUCTION)}
              id="styled-checkbox-8"
              type="checkbox"
              defaultValue="value1"
            />
            <label htmlFor="styled-checkbox-8">
              <span>{t('Declining Price')}</span>
            </label>
          </div>
          <div className="de_checkbox">
            <input
              onChange={() => listingTypeOnChange(ListingType.MARKETPLACE)}
              id="styled-checkbox-9"
              type="checkbox"
              defaultValue="value1"
            />
            <label htmlFor="styled-checkbox-9">
              <span>{t('Fixed Price')}</span>
            </label>
          </div>

          {showOtherListingType && (
            <div className="de_checkbox">
              <input
                onChange={() => listingTypeOnChange(ListingType.NOT_LISTED)}
                id="styled-checkbox-7"
                type="checkbox"
              />
              <label htmlFor="styled-checkbox-7">
                <span>Not Listed</span>
              </label>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default MarketplaceFilter;
