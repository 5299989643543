import Web3 from 'web3';
import { loadDynamicERC721 } from './blockchainInteractor';
import { getHashKey } from './blockchainInteractor';
import { resolvePromise, convertIPFSToHTTPS } from './helper';
import { moralisGetNFT } from './moralisInteractor';
import { getERC721Metadata } from '../services/utilService';

export async function getTokensExternal(
  web3: Web3,
  address: string,
  owner: string,
): Promise<Nft.NftMeta[]> {
  const contract = loadDynamicERC721(web3, address);

  let nftdetails: Nft.NftMeta[] = [];
  try {
    const txn = await moralisGetNFT(owner, address);
    if (!txn) {
      throw new Error('Unable to get NFT');
    }
    const promises = txn.map(async (c) => {
      const promiseData = await Promise.all([
        contract.methods.tokenURI(c.token_id).call(),
        contract.methods.symbol().call(),
      ]);
      const tokenURI = promiseData[0]; //await contract.methods.tokenURI(c.token_id).call();
      const symbol = promiseData[1]; //await contract.methods.symbol().call();
      const dataLink: any = convertIPFSToHTTPS(tokenURI);
      const response = await fetch(dataLink);
      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }
      const data = await response.json();
      const hashedKey = getHashKey(address, c.token_id, web3);
      let newNFT: Nft.NftMeta = {
        tokenID: c.token_id,
        hashedKey: hashedKey,
        title: data?.name,
        description: data?.description,
        ownerAddress: owner,
        NFTImage: convertIPFSToHTTPS(data?.image),
        tokenPayment: '',
        category: symbol,
      };
      return newNFT;
    });
    nftdetails = await Promise.all(promises);
    return nftdetails;
  } catch (e) {
    console.log(e);
  }
  return nftdetails;
}

export async function getTokensERC721ByAddress(
  web3: Web3,
  address: string,
  owner: string,
): Promise<Nft.NftMeta[]> {
  const contract = loadDynamicERC721(web3, address);

  let nftdetails: Nft.NftMeta[] = [];

  let [ownerTokenCount, getOwnerTokeError] = await resolvePromise(
    contract.methods.balanceOf(owner).call(),
  );
  if (getOwnerTokeError) {
    console.log('getTokensERC721ByAddress() - getOwnerTokeError', getOwnerTokeError);
    return nftdetails;
  }
  if (ownerTokenCount > 18) {
    ownerTokenCount = 18;
  }
  for (let i = 0; i < ownerTokenCount; i++) {
    const [tokenId, getTokenIdError] = await resolvePromise(
      contract.methods.tokenOfOwnerByIndex(owner, i).call(),
    );
    if (getTokenIdError) {
      console.log('getTokensERC721ByAddress() - getTokenIdError', getTokenIdError);
      continue;
    }
    const [tokenURI, getTokenURIError] = await resolvePromise(
      contract.methods.tokenURI(tokenId).call(),
    );
    if (getTokenURIError) {
      console.log('getTokensERC721ByAddress() - getTokenURIError', getTokenURIError);
      continue;
    }

    const [metaResponse, getMetaResponseError] = await resolvePromise(getERC721Metadata(tokenURI));
    if (getMetaResponseError) {
      console.log('getTokensERC721ByAddress() - getMetaResponseError', getMetaResponseError);
      continue;
    }
    const hashedKey = getHashKey(address, tokenId.toString(), web3);

    if (metaResponse) {
      let newNFT: Nft.NftMeta = {
        tokenID: tokenId.toString(),
        hashedKey: hashedKey,
        title: metaResponse?.name || `${metaResponse?.title} ${tokenId}`,
        description: metaResponse?.description,
        ownerAddress: owner,
        NFTImage: metaResponse?.NFTImage || metaResponse?.image,
      };
      nftdetails.push(newNFT);
    }
  }
  return nftdetails;
}
