import xWinLogo from '../assets/images/xWinLogo.png';
import React, { useContext, useEffect, useState } from 'react';
import { getTokenSymbol, getTokenIcon, getAddressShort } from 'src/utils/helper';
import { useTranslation } from 'react-i18next';
import { userInfo } from 'os';

const CollectionHeader = ({ card, configData }) => {
  const { t } = useTranslation(['page.marketplace']);
  const [openMenu1, setOpenMenu1] = React.useState(true);
  const [openMenu2, setOpenMenu2] = React.useState(false);

  const handleBtnClick1 = () => {
    if (!openMenu1) {
      setOpenMenu1(true);
      setOpenMenu2(false);
      document.getElementById('Mainbtn1')?.classList.add('active');
      document.getElementById('Mainbtn2')?.classList.remove('active');
    }
  };
  const handleBtnClick2 = () => {
    if (!openMenu2) {
      setOpenMenu1(false);
      setOpenMenu2(true);
      document.getElementById('Mainbtn1')?.classList.remove('active');
      document.getElementById('Mainbtn2')?.classList.add('active');
    }
  };

  return (
    <>
      <section className="jumbotron breadcumb no-bg">
        {configData?.imageBannerURL !== '' && (
          <img src={configData?.imageBannerURL || ''} className="bannar-image" />
        )}
      </section>
      <section className="container d_coll no-top no-bottom">
        <div className="profile_avatar">
          <div className="d_profile_img">
            {configData?.imageURL !== '' && <img src={configData?.imageURL || xWinLogo} alt="" />}
          </div>
        </div>
      </section>

      <div className="de_tab">
        <ul className="de_nav">
          <li id="Mainbtn1" className="active">
            <span onClick={handleBtnClick1}>{t('Details')}</span>
          </li>
          <li id="Mainbtn2">
            <span onClick={handleBtnClick2}>{t('About Artist')}</span>
          </li>
        </ul>

        <div className="de_tab_content">
          {openMenu1 && (
            <div className="tab-1 onStep fadeIn">
              <div className="spacer-20"></div>

              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="spacer-10"></div>
                {configData?.tokenPayment !== '' && card?.costPerNFT !== '' && (
                  <div className="d-flex">
                    <img
                      src={getTokenIcon(configData?.tokenPayment)}
                      className="pr-2"
                      style={{ height: '1.75rem' }}
                    />
                    <h3>
                      {`${Number(card?.costPerNFT as unknown as string).toFixed(
                        3,
                      )} ${getTokenSymbol(configData?.tokenPayment)} / NFT`}
                    </h3>
                  </div>
                )}

                <div className="item_filter_group">
                  <div className="row text-center text-nowrap">
                    <div className="col">
                      {t('Items')}
                      <h4>{Number(card?.totalSupply).toFixed(0)}</h4>
                    </div>
                    <div className="col">
                      {t('Max Supply')}
                      <h4>{Number(configData?.maxSupply).toFixed(0)}</h4>
                    </div>
                    {card?.totalVolume !== '' && (
                      <div className="col">
                        {t('Volume Traded')}
                        <h4>{Number(card?.totalVolume).toFixed(2)}</h4>
                      </div>
                    )}
                    <div className="col">
                      {t('Owners')}
                      <h4>{Number(configData?.totalunique).toFixed(0)}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {openMenu2 && (
            <div className="tab-2 onStep fadeIn">
              <div className="spacer-20"></div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div className="mt-2">
                  <h4 className="bio mb-4 text-center">
                    <a
                      href={'https://www.bscscan.com/address/' + configData.contractaddress}
                      target="_blank"
                    >
                      {configData?.description}
                    </a>
                  </h4>
                  <p className="pl-3 pr-3 text-center">
                    <div
                      className="pl-5 pr-5"
                      dangerouslySetInnerHTML={{ __html: configData?.longdesc }}
                    />
                  </p>
                </div>
              </div>
              <div className="spacer-20"></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default CollectionHeader;
