import React from 'react';

const Loader = () => {
  return (
    <div className="theme-preloader">
      <span className="preloader-img" />
    </div>
  );
};

export default Loader;
