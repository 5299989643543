import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import confetti from 'canvas-confetti';
import { updateCreatorFee, getCreatorFee } from '../utils/masterSettingInteractor';
import { displayMessage, resolvePromise } from '../utils/helper';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../contexts/AppContext';
import ProgressLoader from '../components/ProgressLoader';
import { useTheme } from 'src/contexts/ThemeContext';

const CreatorFeeSetup = ({ reset }) => {
  // const classes = useStyles();
  const { themeType } = useTheme();
  const { t } = useTranslation(['component']);
  const { account, web3 } = useContext(AppContext);
  const [darkTheme] = useState(themeType == 'dark');
  const [values, setValues] = useState({
    royaltyfee: 10,
  });
  const [isLoading, setIsLoading] = useState({
    loading: false,
    type: 'page',
    message: '',
  });

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
      return;
    }
    try {
      setIsLoading({ loading: true, type: 'page', message: '' });

      const res = await getCreatorFee(web3, account);
      setValues({
        royaltyfee: res.fee / 100,
      });
    } catch (e) {
      console.log(e);
      toast.error('Error getting royalty fee!');
    } finally {
      setIsLoading({ loading: false, type: 'page', message: '' });
    }
  };

  const updateFee = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
      return;
    }

    if (Number(values.royaltyfee) > 10) {
      toast.error(t('component:max-royalty-fee', { fee: 10 }));
      return;
    }

    try {
      setIsLoading({
        loading: true,
        type: 'page',
        message: t('component:updating-fee'),
      });
      const [res, listError] = await resolvePromise(
        updateCreatorFee(web3, values.royaltyfee.toString(), account),
      );
      if (!listError) {
        toast.success(t('component:feeupdated', { royaltyfee: values.royaltyfee }));
      }else{
        displayMessage(listError, t)
      }
    } catch (e) {
      console.error(e);
      toast.error('Error Updating Creator Fee');
    } finally {
      setIsLoading({ loading: false, type: 'page', message: '' });
      reset();
    }
  };

  const handleChange = (e) => {
    const updated = { [e.target.name]: e.target.value };
    setValues({
      ...values,
      ...updated,
    });
  };

  return (
    <>
      <ProgressLoader
        loading={isLoading.loading}
        type={isLoading.type}
        message={isLoading.message}
      />
      <div className="wallet-detected-info">
        <div className="detailcheckout mt-4">
          <div className="listcheckout">
            <h6>{t('Royalty Fee')}%</h6>
            <input
              type="text"
              name="royaltyfee"
              onChange={handleChange}
              value={values.royaltyfee}
              placeholder={t('10%')}
              color="#000"
              className="form-control"
            />
            <div className="mt-2">
              <button
                className={darkTheme ? 'btn-main green' : 'btn-main purple'}
                onClick={updateFee}
              >
                {t('Update')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatorFeeSetup;
