import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createGlobalStyle } from 'styled-components';
import { useTheme } from '../contexts/ThemeContext';

type Props = {
  onSignUp: (information: Information, t: any) => void;
};

export type Information = {
  email: string;
  username: string;
};

const GlobalStyles = createGlobalStyle`
.greyscheme .nft__item img[class="token_icon"] {
  height: 1.75rem !important;
  width: 1.75rem !important;
}
`;

const SignUpForm = (props: Props) => {
  const { themeType } = useTheme();
  const { t } = useTranslation(['component', 'page.profile']);
  const [darkTheme] = useState(themeType == 'dark');
  const [information, setInformation] = useState<Information>({
    email: '',
    username: '',
  });

  const handleChange = (e) => {
    const updated = { [e.target.name]: e.target.value };

    setInformation({ ...information, ...updated });
  };

  return (
    <>
      <p>{t('sign-up-form.description.email')}</p>
      <p>{t('sign-up-form.description.username')}</p>
      <h5>{t('sign-up-form.label.email')}</h5>
      <input
        value={information.email}
        onChange={handleChange}
        className="form-control"
        type="text"
        name="email"
      />

      <h5>{t('sign-up-form.label.username')}</h5>
      <input
        value={information.username}
        onChange={handleChange}
        className="form-control"
        type="text"
        name="username"
      />

      <div className="col d-flex justify-content-center">
        <button
          disabled={!information.email || !information.username}
          onClick={() => props.onSignUp(information, t)}
          className={`btn-main1 ${darkTheme ? 'green' : 'purple'} m-1`}
        >
          {t('sign-up-form.button.continue')}
        </button>
      </div>
    </>
  );
};

export default SignUpForm;
