// import { useTranslation } from 'react-i18next';
// import Avatar from 'react-avatar';
// import xWinLogo from '../assets/images/xWinLogo.png';
// import { useSelector, useDispatch } from 'react-redux';
import Artist from './Artist';

type Props = {
  featuredArtists: LandingPage.LandingPageInfo[];
};

const Artists = (props: Props) => {
  const { featuredArtists } = props;
  return (
    <div>
      <ol className="author_list">
        {featuredArtists.map((artist) => {
          return (
            <li key={artist._id}>
              <Artist artist={artist} />
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default Artists;
