import { Watch, Bars } from 'react-loader-spinner';

const SpinnerLoader = ({ loading }) => {
  if (loading) {
    return (
      <div>
        <Bars height="200" width="100%" color="grey" arialLabel="loading-indicator" />
      </div>
    );
  }
  return null;
};

export default SpinnerLoader;
