import * as Yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import confetti from 'canvas-confetti';
import MintFAQ from '../components/FAQ/MintFAQ';
import ProgressLoader from '../components/ProgressLoader';

import UserContext from '../contexts/UserContext';
import { mintNFT } from '../utils/mintNFTInteractor';
import { getMintFee } from '../utils/masterSettingInteractor';
import { getTokenIdFromTxn, getHashKey, convertFromWei } from '../utils/blockchainInteractor';
import {
  getNetworkById,
  resolvePromise,
  getNftType,
  getERC721ContractAddressByCategory,
  displayMessage,
} from '../utils/helper';
import { createMeta, getNftCategoriesList } from '../services/metaService';
import { uploadIPFS, pinAtPinata } from '../services/utilService';
import { CommonRoutes, ListingType, MintType, NftType } from 'src/constant';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'src/contexts/AppContext';
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import systemConfig from 'src/configs/systemConfig';
import { getCollections } from 'src/services/collectionService';

const re =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

const MintPage = (props) => {
  const { t } = useTranslation(['page.mint-page', 'common']);
  const history = useHistory();
  const { account, web3 } = useContext(AppContext);
  const userContext = useContext(UserContext);
  const { handleStart, state } = userContext;
  const { user } = state;
  const [mintFee, setMintFee] = useState('0');
  const [nftCategories, setNftCategories] = useState<Nft.NftCategory[]>([]);
  const [myCollection, setMyCollection] = useState<Collection.MyCollection[]>([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [imageFileMemoryError, setImageFileMemoryError] = useState(false);
  const [videoFileMemoryError, setVideoFileMemoryError] = useState(false);
  const [isLoading, setIsLoading] = useState({
    loading: false,
    type: 'page',
    message: '',
  });

  const NFT_VALIDATION_SCHEMA = Yup.object().shape({
    title: Yup.string()
      .max(120, t('validation-message.max.title'))
      .required(t('validation-message.required.title')),
    description: Yup.string().required(t('validation-message.required.description')),
    category: Yup.string().when(['mintType'], {
      is: (val) => val === MintType.Category,
      then: Yup.string().required(t('validation-message.required.category')),
    }),
    collectionId: Yup.string().when(['mintType'], {
      is: (val) => val === MintType.Collection,
      then: Yup.string().required(t('validation-message.required.collection')),
    }),
    NFTImage: Yup.string().required(t('validation-message.required.image')),
    externalLink: Yup.string().matches(re, t('validation-message.matches.external-link')),
    attributes: Yup.array().of(
      Yup.object().shape({
        trait_type: Yup.string().required(t('validation-message.required.attribute_type')),
        value: Yup.string().required(t('validation-message.required.attribute_value')),
        // showTrait: Yup.boolean(),
        // trait_type: Yup.string().required().when( 'showTrait',
        // {
        //   is: true,
        //   then: Yup.string().required(t("validation-message.required.attribute_type"))
        // }
        // ),
        // trait_type: Yup.string(),
        // value: Yup.string(),
      }),
    ),
  });

  const init = async () => {
    setMintFee(await getMintFee(web3));

    const [nftCategoryResponse, getNftCategoryError] = await resolvePromise(getNftCategoriesList());
    if (!getNftCategoryError) {
      setNftCategories(
        (nftCategoryResponse?.data?.data || []).filter(
          (d) => d.priority !== 0 && d.systemCategory === true,
        ),
      );
    }

    const [collection] = await resolvePromise(getCollections(state.user?.address, false));
    setMyCollection(collection?.data || []);
  };

  useEffect(() => {
    init();
  }, [user]);

  const newNFT = {
    title: '',
    description: '',
    externalLink: '',
    mintType: MintType.Category,
    collectionId: '',
    category: '',
    contentType: '',
    NFTImage: '',
    attributes: [
      {
        trait_type: '',
        value: '',
      },
    ],
  };

  const onAddNewAttribute = (values, setFieldValue) => {
    values.attributes.push({ trait_type: '', value: '' });
    setFieldValue('attributes', values.attributes);
  };

  const onRemoveAttribute = (values, index, setFieldValue) => {
    values.attributes.splice(index, 1);
    setFieldValue('attributes', values.attributes);
  };

  const onSubmit = async (values, { resetForm, setSubmitting }) => {
    await submit(values);
    setSubmitting(false);
    //resetForm();
  };

  const setSelectedFileName = async (files, setFieldValue) => {
    const imageURL = URL.createObjectURL(files[0]);

    const fileType = files[0].type;

    // Calculating bytes to kilobytes (bytes divided by 1024)

    const fileMemory = Math.round(files[0].size / 1024);

    if (fileMemory >= 50240 && getNftType(fileType) == NftType.Video) {
      setVideoFileMemoryError(true);
      setImageFileMemoryError(false);
      return;
    } else if (fileMemory >= 10240 && getNftType(fileType) == NftType.Image) {
      setImageFileMemoryError(true);
      setVideoFileMemoryError(false);
      return;
    }
    setImageFileMemoryError(false);
    setVideoFileMemoryError(false);
    setFieldValue('NFTImage', imageURL);
    setFieldValue('contentType', files[0].type);
    setSelectedFile(files[0]);
  };

  const submit = async (values) => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
      return;
    }
    try {
      let nftContract;
      let selectedCollection
      if (values.mintType === MintType.Category) {
        nftContract = getERC721ContractAddressByCategory(values.category, nftCategories);
      }
      if (values.mintType === MintType.Collection) {
        selectedCollection = myCollection.find((obj) => obj._id === values.collectionId);
        nftContract = getERC721ContractAddressByCategory(
          selectedCollection?.category,
          nftCategories,
        );
        values.userCollectionId = values.collectionId;
        values.category = selectedCollection?.category;
      }

      if (!nftContract) {
        toast.error(t('Address not supported!'));
        return;
      }

      setIsLoading({
        loading: true,
        type: 'page',
        message: t('component:uploading-image'),
      });
      const [metaData, metaDataError] = await resolvePromise(uploadIPFS(values, selectedFile));

      if (!metaDataError) {
        const cid = metaData.ipnft;
        const pathname = metaData.data.image.href; //IMAGE_HTTP + metaData.data.image.pathname;
        const [imgCid, ...filePathComponents] = pathname.substring(7).split('/'); // getting cid from pathname
        pinAtPinata(imgCid); // manually pin image cid at pinata

        values.NFTImage = pathname;
        setIsLoading({
          loading: true,
          type: 'page',
          message: t('component:minting-nft'),
        });

        const [txn, txnError] = await resolvePromise(
          mintNFT(account, web3, nftContract, cid, mintFee),
        );

        if (!txnError) {
          const tokenID = await getTokenIdFromTxn(web3, txn);
          const hashedKey = getHashKey(nftContract, tokenID, web3);
          const nftMeta = await createMeta({
            ...values,
            tokenID,
            contractAddress: nftContract,
            listingType: ListingType.NOT_LISTED,
            hashedKey,
            chainId: systemConfig.chainId.toString(),
          });

          if (values.mintType === MintType.Collection) {
            history.push(`${CommonRoutes.COLLECTION}/${selectedCollection.name}`)
          }else{
            history.push(CommonRoutes.MY_NFT);
          }
          toast.success(t('toast.success', { id: tokenID }));
          confetti();
        }else{
          displayMessage(txnError, t)
        }
      }
    } catch (err) {
      console.log(err);
      toast.error(t('common:toast.something-went-wrong'));
    } finally {
      setIsLoading({ loading: false, type: 'page', message: '' });
    }
  };

  return (
    <>
      <section className="container">
        <ProgressLoader
          loading={isLoading.loading}
          type={isLoading.type}
          message={isLoading.message}
        />
        <div className="spacer-20"></div>
        <div className="mainbreadcumb">
          <div className="container">
            <div className="row m-10-hor">
              <div className="col-12">
                <h1 className="text-center">{`${t('label.create')}`}</h1>
              </div>
            </div>
          </div>
        </div>
        <Formik initialValues={newNFT} validationSchema={NFT_VALIDATION_SCHEMA} onSubmit={onSubmit}>
          {({ isSubmitting, values, setFieldValue, errors, touched, handleChange }) => (
            <div className="row">
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 offset-xl-2 offset-lg-2 offset-md-2">
                <Form autoComplete="random_string" className="form-border">
                  <div className="field-set">
                    <h5>{t('label.title')}</h5>
                    <Field
                      type="text"
                      name="title"
                      className="form-control"
                      value={values?.title}
                      placeholder={'i.e. Crypto Cat'}
                    />
                    {errors.title && touched.title ? (
                      <div className="text-danger mt-2">{errors.title}</div>
                    ) : null}
                    <div className="spacer-10"></div>

                    <h5>{t('label.mint_by')}</h5>
                    <Field
                      component="select"
                      name="mintType"
                      className="select"
                      value={values.mintType}
                    >
                      <option disabled value="">
                        {t('label.mint_by')}
                      </option>
                      {Object.keys(MintType).map((data) => {
                        return (
                          <option key={data} value={data}>
                            {data}
                          </option>
                        );
                      })}
                    </Field>
                    <div className="spacer-40"></div>

                    {values.mintType === MintType.Category && (
                      <>
                        <h5>{t('label.category')}</h5>
                        <Field
                          component="select"
                          name="category"
                          className="select"
                          value={values.category}
                        >
                          <option disabled value="">
                            {t('Select Category')}
                          </option>
                          {nftCategories.map((cat) => {
                            return (
                              <option key={cat._id} value={cat.category}>
                                {cat.category}
                              </option>
                            );
                          })}
                        </Field>
                        {errors.category && touched.category ? (
                          <div className="text-danger mt-2">{errors.category}</div>
                        ) : null}
                        <div className="spacer-40"></div>
                      </>
                    )}

                    {values.mintType === MintType.Collection && (
                      <>
                        <h5>{t('label.collection')}</h5>
                        <Field
                          component="select"
                          name="collectionId"
                          className="select"
                          value={values.collectionId}
                        >
                          <option disabled value="">
                            {t('Select Collection')}
                          </option>
                          {myCollection.map((col) => {
                            return (
                              <option key={col._id} value={col._id} label={col.name}>
                                {col.name}
                              </option>
                            );
                          })}
                        </Field>
                        {errors.collectionId && touched.collectionId ? (
                          <div className="text-danger mt-2">{errors.collectionId}</div>
                        ) : null}
                        <div className="spacer-40"></div>
                      </>
                    )}

                    {/* <h5>{t("label.external-link")}</h5>
                    <Field
                      type="text"
                      name="externalLink"
                      className="form-control"
                      value={values?.externalLink}
                      placeholder={t("label.external-link")}
                    />
                    {errors.externalLink && touched.externalLink ? (
                      <div className="text-danger mt-2">
                        {errors.externalLink}
                      </div>
                    ) : null}
                    <div className="spacer-10"></div> */}

                    <h5>{t('label.upload')}</h5>
                    <div className="d-create-file">
                      {isImageLoading ? (
                        <div className="text-center">
                          <Spinner
                            style={{ width: '6rem', height: '6rem' }}
                            size="lg"
                            color="primary"
                          />
                        </div>
                      ) : values.NFTImage ? (
                        <div className="upload-images-preview">
                          <div
                            onClick={() => setFieldValue('NFTImage', '')}
                            className="d-flex mb-2"
                          >
                            <i className="fas fa-times ml-auto" />
                          </div>
                          {getNftType(values?.contentType as string) === NftType.Audio ||
                          getNftType(values?.contentType as string) === NftType.Video ? (
                            <ReactPlayer width="100%" url={values?.NFTImage} />
                          ) : (
                            <img
                              className="rounded w-100"
                              src={values?.NFTImage || '/assets/images/preloader.png'}
                              alt=""
                            />
                          )}
                        </div>
                      ) : (
                        <div className="browse">
                          <div className="browse">
                            <input
                              type="button"
                              id="get_file"
                              className="btn-main"
                              value={t('placeholder.add').valueOf()}
                            />
                            <input
                              id="upload_file"
                              name="nftImage"
                              type="file"
                              accept="audio/*,video/*,image/*"
                              multiple
                              onChange={({ currentTarget }) => {
                                setSelectedFileName(currentTarget.files, setFieldValue);
                                currentTarget.value = null as unknown as string;
                              }}
                            />
                          </div>
                        </div>
                      )}
                      {errors.NFTImage && touched.NFTImage ? (
                        <div className="text-danger mt-2">{errors.NFTImage}</div>
                      ) : null}

                      {imageFileMemoryError ? (
                        <div className="text-danger mt-2">
                          {'Please upload image less than 10mb'}
                        </div>
                      ) : null}

                      {videoFileMemoryError ? (
                        <div className="text-danger mt-2">
                          {'Please upload video less than 50mb'}
                        </div>
                      ) : null}
                    </div>
                    <div className="spacer-single"></div>

                    <h5>{t('label.description')}</h5>
                    <Field
                      component="textarea"
                      name="description"
                      className="form-control"
                      placeholder={t('placeholder.enter-description')}
                      value={values.description}
                      maxrows={4}
                      rows={4}
                      multiline={'true'}
                    />
                    {errors.description && touched.description ? (
                      <div className="text-danger mt-2">{errors.description}</div>
                    ) : null}

                    <div className="spacer-single"></div>
                    <div className="d-flex align-items-center pb-3">
                      <h5 className="mb-0">{t('label.attributes')}</h5>
                      <button
                        className="btn-main pt-1 pb-1 pl-3 pr-3 ml-3"
                        onClick={() => onAddNewAttribute(values, setFieldValue)}
                      >
                        {t('button.add')}
                      </button>
                    </div>
                    <div className="theme-input-box">
                      {values?.attributes?.map((_, index) => (
                        <div className="d-flex pb-1" key={index}>
                          <Field
                            type="text"
                            name={`attributes[${index}].trait_type`}
                            className="form-control mr-3"
                            value={values.attributes[index].trait_type}
                            placeholder={t('name')}
                          />
                          <Field
                            type="text"
                            name={`attributes[${index}].value`}
                            className="form-control"
                            value={values.attributes[index].value}
                            placeholder={t('Toppy')}
                          />
                          <div className="m-1">
                            <FontAwesomeIcon
                              icon={faTrashAlt}
                              onClick={() => onRemoveAttribute(values, index, setFieldValue)}
                              className="icon-link"
                            />
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="d-flex justify-content-center m-2">
                      <button type="submit" disabled={isSubmitting} className="btn-main">
                        {t('button.create')}
                      </button>
                    </div>
                    <div className="d-flex justify-content-center m-2">
                      <p className="mb-0">
                        {t('label.mint-fee')} {convertFromWei(mintFee)}{' '}
                        {getNetworkById(systemConfig.chainId).nativeCurrency.symbol}
                      </p>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          )}
        </Formik>
        <div className="spacer-40"></div>
        <MintFAQ />
      </section>
    </>
  );
};

export default MintPage;
