import React from 'react';
import classnames from 'classnames';
import { Card as BootstrapCard, CardBody, CardHeader, Collapse } from 'reactstrap';

type Props = {
  alwaysOpen?: boolean;
  defaultOpen?: boolean;
  children: React.ReactNode;
  className?: string;
  icon?: string;
  title: string;
};

const Accordion = (props: Props) => {
  const [isOpen, setIsOpen] = React.useState(props.defaultOpen);
  return (
    <BootstrapCard
      className={classnames(props.className, {
        'card-expanded': props.alwaysOpen ? props.alwaysOpen : isOpen,
      })}
    >
      <CardHeader onClick={() => setIsOpen(!isOpen)}>
        <div className="d-flex align-items-center">
          {props.icon && <i className={classnames('icon', props.icon)} />}
          &nbsp;
          <div className="title">{props.title}</div>
        </div>
      </CardHeader>
      <Collapse isOpen={props.alwaysOpen ? props.alwaysOpen : isOpen}>
        <CardBody>{props.children}</CardBody>
      </Collapse>
    </BootstrapCard>
  );
};
export default Accordion;
