import _, { Dictionary } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import confetti from 'canvas-confetti';
import UserContext from '../contexts/UserContext';
import useLoader from '../hooks/useLoader';
import { NftType } from '../constant';
import Accordion from '../components/Accordion';
import {
  delayExecution,
  getCheckedSumAddress,
  getAddressShort,
  getNftType,
  getLinkShort,
} from '../utils/helper';
import { useTranslation } from 'react-i18next';
import SocialMedia from 'src/components/SocialMedia';
import { AppContext } from 'src/contexts/AppContext';
import { getTokenMetaData } from '../utils/mysteriosBoxNFTInteractor';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { IconButton } from '@material-ui/core';

const NftDetailExternal = ({ match }) => {
  const { t } = useTranslation(['page.nft.detail', 'enum']);
  const { state } = useContext(UserContext);
  const { account, web3 } = useContext(AppContext);
  const history = useHistory();
  const [nftDetails, setNftDetails] = useState<Nft.Information>();
  const [user, setUser] = useState<User.Information>();
  const [nftContract, setNftContract] = useState('');
  const [tokenId, setTokenId] = useState('');
  const [alreadyImported, setAlreadyImported] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const nftContractAddress = match?.params?.address;
      const Id = match?.params?.id;
      setNftContract(nftContractAddress);
      setTokenId(Id);
      const metaData = await getTokenMetaData(web3, nftContractAddress, Id);
      if (metaData) {
        setNftDetails(metaData || []);
      }
    } catch (error) {
      console.log(error);
      toast.error(t('toast.error-fetch-nft'));
      await delayExecution();
    } finally {
    }
  };

  // const handleImport = async () => {
  //   if (!account) {
  //     toast.error(t("common:account.not-sign-in"));
  //     return;
  //   }
  //   try {
  //     showLoader();

  //     const hashedKey = getHashKey(nftContract, tokenId, web3);
  //     console.log(nftDetails?.attributes)
  //     const nftMeta = await createMeta({
  //       hashedKey: hashedKey,
  //       title: nftDetails?.title,
  //       description: nftDetails?.description,
  //       ownerAddress: account,
  //       NFTImage: nftDetails?.NFTImage,
  //       category: nftDetails?.category,
  //       externalLink : nftDetails?.externalLink,
  //       tokenID : tokenId,
  //       contractAddress: nftContract,
  //       listingType: ListingType.NOT_LISTED,
  //       attributes : nftDetails?.attributes
  //     });
  //     history.push(`/my-nfts`);
  //     toast.success(t("toast.success", { id: tokenId }));
  //     confetti()

  //   } catch (error) {
  //     toast.error(t("toast.error-import-nft"));
  //   } finally {
  //     hideLoader();
  //   }
  // };

  return (
    <>
      <section className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
            <div className="browse-detail-images">
              <div className="browse-detail-large w-100">
                {getNftType(nftDetails?.contentType as string) === NftType.Audio ||
                getNftType(nftDetails?.contentType as string) === NftType.Video ? (
                  <ReactPlayer controls loop playing width="100%" url={nftDetails?.NFTImage} />
                ) : (
                  <img
                    className="rounded w-100"
                    src={nftDetails?.NFTImage || '/assets/images/preloader.png'}
                    alt=""
                  />
                )}
              </div>
              <div className="browse-detail-info">
                <Accordion
                  defaultOpen
                  className="mt-4 "
                  icon="fas fa-align-left"
                  title={t('label.description')}
                >
                  <div className="text">{nftDetails?.description}</div>
                </Accordion>
              </div>
            </div>
          </div>
          <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
            <IconButton onClick={history.goBack} color="primary">
              <ArrowCircleLeftIcon />
            </IconButton>
            <div className="browse-detail-info">
              <div className="rounded-top pb-3">
                <h1 className="theme-title">{nftDetails?.title}</h1>
                <div className="d-flex align-items-center justify-content-between mt-4 mb-2">
                  <p className="theme-description mb-0">
                    {`${t('label.owned-by')}: `}
                    {getAddressShort(nftDetails?.ownerAddress)}
                  </p>
                  <SocialMedia user={user as User.Information} />
                </div>
              </div>
              {/* {!alreadyImported && getCheckedSumAddress(nftDetails?.ownerAddress, web3) ===
                  getCheckedSumAddress(state?.user?.address, web3) && (
                  <>
                    <div className="browse-buy-btn">
                      <button
                      onClick={handleImport}
                      className="theme-btn"
                      >
                      {t("Import")}
                      <i className="fas fa-arrow-right" />
                      </button>
                    </div>
                  </>
                )} */}
            </div>
            <br />
            <div className="browse-detail-info">
              <Accordion
                defaultOpen
                className="mt-4 "
                icon="fas fa-clipboard-list"
                title={t('label.details')}
              >
                <div className="d-flex text justify-content-between">
                  <span>{t('details.token-id')}</span>
                  <span>{nftDetails?.tokenID}</span>
                </div>
                <div className="d-flex text justify-content-between">
                  <span>{t('details.category')}</span>
                  <span>{nftDetails?.category}</span>
                </div>
                <div className="d-flex text justify-content-between">
                  <span>{t('NFT Address')}</span>
                  <span>{getAddressShort(nftDetails?.contractAddress)}</span>
                </div>
                <div className="d-flex text justify-content-between">
                  <span>{t('details.token-standard')}</span>
                  <span>{'ERC721'}</span>
                </div>
                <div className="d-flex text justify-content-between">
                  <span>{t('details.token-chain')}</span>
                  <span>{'BSC'}</span>
                </div>
                {nftDetails?.externalLink && (
                  <div className="d-flex text justify-content-between">
                    <span>{t('details.external-link')}</span>
                    <a href={nftDetails?.externalLink} target="_blank">
                      {getLinkShort(nftDetails?.externalLink)}
                    </a>
                  </div>
                )}
              </Accordion>

              {(nftDetails?.attributes as Nft.NftAttribute[])?.length > 0 && (
                <Accordion
                  defaultOpen
                  className="mt-4 "
                  icon="fas fa-user-friends"
                  title={t('label.trait')}
                >
                  {nftDetails?.attributes?.map((attribute) => (
                    <div className="d-flex text justify-content-between">
                      <span>{attribute.trait_type}</span>
                      <span>{attribute.value}</span>
                    </div>
                  ))}
                </Accordion>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NftDetailExternal;
