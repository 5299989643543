import { CommonRoutes } from '../../constant';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation(['component']);

  return (
    <footer className="footer-light">
      <div className="container1">
        <div className="row">
          <div className="col-md-3 col-sm-6 col-xs-1">
            <div className="widget">
              <h5>{t('Category')}</h5>
              <ul>
                <li>
                  <Link to={`${CommonRoutes.MARKETPLACE}/Collectibles`}>{t('Collectibles')}</Link>
                </li>
                <li>
                  <Link to={`${CommonRoutes.MARKETPLACE}/Art`}>{t('Art')}</Link>
                </li>
                <li>
                  <Link to={`${CommonRoutes.MARKETPLACE}/Music`}>{t('Music')}</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-1">
            <div className="widget">
              <h5>{t('footer.section-header.explore')}</h5>
              <ul>
                <li>
                  <Link to={`/`}>{t('footer.navigation.home')}</Link>
                </li>
                <li>
                  <Link to={CommonRoutes.MARKETPLACE}>{t('footer.navigation.explore')}</Link>
                </li>
                <li>
                  <Link to={CommonRoutes.CREATE_NFT}>
                    {t('dashboard-header.navigation.create-nft')}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-1">
            <div className="widget">
              <h5>{t('footer.section-header.community')}</h5>
              <ul>
                <li>
                  <a href={'https://xwin.medium.com/'} target="_blank">
                    {t('footer.community.blog')}
                  </a>
                </li>
                <li>
                  <Link to={'/termservice'}>{t('footer.privacy.terms-and-conditions')}</Link>
                </li>
                <li>
                  <Link to="/privacy">{t('footer.privacy.privacy-policy')}</Link>
                </li>
                <li>
                  <a
                    href={
                      'https://github.com/xwinfinance/ToppyMarketplace/blob/main/audit/Xwin%20Finance%20Toppy%20Marketplace-%20Report.pdf'
                    }
                    target="_blank"
                  >
                    {t('Audit Report')}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-1">
            <div className="widget">
              <h5>
                <img src={Logo} alt="" className="img-fluid" />
              </h5>
              <ul>
                <li>{t('footer.description')}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="subfooter">
        <div className="container1">
          <div className="row">
            <div className="col-md-12">
              <div className="de-flex">
                <div className="de-flex-col">
                  <span onClick={() => window.open('', '_self')}>
                    <span className="copy">{t('footer.copyright')}</span>
                  </span>
                </div>
                <div className="de-flex-col">
                  <div className="social-icons">
                    <span
                      onClick={() => window.open('https://www.facebook.com/toppy.xwin', '_self')}
                    >
                      <i className="fa fa-facebook fa-lg"></i>
                    </span>
                    <span onClick={() => window.open('https://twitter.com/xwinfinance', '_self')}>
                      <i className="fa fa-twitter fa-lg"></i>
                    </span>
                    <span onClick={() => window.open('https://www.t.me/xwinfinance', '_self')}>
                      <i className="fa fa-telegram fa-lg"></i>
                    </span>
                    <span
                      onClick={() =>
                        window.open('https://github.com/xwinfinance/ToppyMarketplace', '_self')
                      }
                    >
                      <i className="fa fa-github fa-lg"></i>
                    </span>
                    {/* <span onClick={() => window.open("", "_self")}><i className="fa fa-rss fa-lg"></i></span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
