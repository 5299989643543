import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faTelegram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import UserContext from '../contexts/UserContext';
import { updateUser, getUserByUsername } from '../services/userService';
import CreatorFeeSetup from '../components/CreatorFeeSetup';
import { AppContext } from '../contexts/AppContext';
import { getCheckedSumAddress, resolvePromise } from '../utils/helper';
import { CommonRoutes, bnbaddress } from '../constant';
import UserProfileHeader from '../components/UserProfileHeader';
import { getCreatorFee } from '../utils/masterSettingInteractor';
import { ToastContainer, toast } from 'react-toastify';
import { Link, NavLink } from 'react-router-dom';
import ProfileFAQ from '../components/FAQ/ProfileFAQ';
import { useTheme } from 'src/contexts/ThemeContext';

const Profile = (initialState) => {
  const { t } = useTranslation(['page.profile', 'common']);
  const { themeType } = useTheme();
  const [darkTheme, setDarkTheme] = React.useState(themeType == 'dark');

  // const { themeType } = useTheme();
  const { state, changeState } = useContext(UserContext);
  const { user } = state;
  const history = useHistory();
  const [openFeeModal, setOpenFeeModal] = useState(false);
  const [showRoyaltyFeeSetup, setShowRoyaltyFeeSetup] = useState(false);
  const { account, web3 } = useContext(AppContext);

  const [information, setInformation] = useState<User.Information>({
    profileImage: '',
    bannerImage: '',
    username: '',
    email: '',
    address: '',
    bio: '',
    language: '',
  });

  useEffect(() => {
    if (!account && !user) {
      history.replace(CommonRoutes.MARKETPLACE);
      return;
    }
    setInformation({
      profileImage: user?.profileImage || '',
      bannerImage: user?.bannerImage || '',
      username: user?.username || '',
      email: user?.email || '',
      address: getCheckedSumAddress(user?.address, web3) || '',
      bio: user?.bio || '',
      facebook: user?.facebook || '',
      twitter: user?.twitter || '',
      telegram: user?.telegram || '',
      language: user?.language || 'English',
    });

    checkRoyaltyFeeSetup();
    setDarkTheme(themeType == 'dark');
  }, [account, user, themeType]);

  const checkRoyaltyFeeSetup = async () => {
    const res = await getCreatorFee(web3, account);
    if (res.fee == '0') {
      toast.info(getMessage(), {
        position: 'bottom-center',
        autoClose: 10000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  };

  const getMessage = () => {
    return <span onClick={() => handleUpdateFee()}>{t('component:royaltyFee-setup-message')}</span>;
  };

  const handleChange = (e) => {
    setInformation({ ...information, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    const [user] = await resolvePromise(getUserByUsername(information?.username || ''));

    if (user?.data?.address === information?.address) {
      await saveUserInfo(information);
      return;
    }

    toast.error(t('error.duplicateUsername'));
  };

  const saveUserInfo = async (userInfo) => {
    try {
      const { data: returnedUser } = await updateUser(userInfo);

      if (returnedUser) {
        const updatedUser = {
          ...state.user,
          ...returnedUser,
        } as User.Information;

        localStorage.setItem('user', JSON.stringify(updatedUser));
        changeState({ user: updatedUser });
        toast.success(t('component:profile-updated'));
      }
    } catch (e) {
      toast.error(t('common:toast.something-went-wrong'));
    }
  };

  const handleUpdateFee = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
      return;
    }
    setOpenFeeModal(true);
  };

  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={10000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
      />

      <UserProfileHeader user={information} editable={true} />

      <div className="spacer-20"></div>
      <section className="jumbotron breadcumb no-bg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center">{t('title')}</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <div className="row">
          <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 offset-xl-2 offset-lg-2 offset-md-2">
            <div>
              <div className="field-set">
                <h5>{t('label.username')}</h5>
                <input
                  type="text"
                  name="username"
                  onChange={handleChange}
                  value={information?.username}
                  placeholder={'i.e. Fumihiro Arasawa'}
                  className="form-control"
                />

                <div className="spacer-10"></div>
                <h5>{t('label.wallet-address')}</h5>
                <input
                  type="text"
                  onChange={handleChange}
                  value={getCheckedSumAddress(information?.address || '', web3)}
                  placeholder={'i.e.' + bnbaddress}
                  className="form-control"
                />

                <div className="spacer-10"></div>
                <h5>{t('label.email')}</h5>
                <input
                  type="text"
                  name="email"
                  onChange={handleChange}
                  value={information?.email}
                  placeholder={'i.e. fumi@toppy.market'}
                  className="form-control"
                />

                <div className="spacer-10"></div>
                <h5>{t('label.bio')}</h5>
                <textarea
                  name="bio"
                  onChange={handleChange}
                  value={information?.bio}
                  placeholder={'i.e. Introduce your self in elegant way...'}
                  rows={4}
                  className="form-control"
                />

                <div className="spacer-10"></div>
                <h5>
                  <FontAwesomeIcon icon={faFacebook as IconDefinition} />
                  &nbsp;{t('label.facebook')}
                </h5>
                <input
                  type="text"
                  name="facebook"
                  onChange={handleChange}
                  value={information?.facebook}
                  placeholder={t('placeholder.facebook')}
                  className="form-control"
                />

                <div className="spacer-10"></div>
                <h5>
                  <FontAwesomeIcon icon={faTwitter as IconDefinition} />
                  &nbsp;{t('label.twitter')}
                </h5>
                <input
                  type="text"
                  name="twitter"
                  onChange={handleChange}
                  value={information?.twitter}
                  placeholder={t('placeholder.twitter')}
                  className="form-control"
                />

                <div className="spacer-10"></div>
                <h5>
                  <FontAwesomeIcon icon={faTelegram as IconDefinition} />
                  &nbsp;{t('label.telegram')}
                </h5>
                <input
                  type="text"
                  name="telegram"
                  onChange={handleChange}
                  value={information?.telegram}
                  placeholder={t('placeholder.telegram')}
                  className="form-control"
                />

                <div className="spacer-10"></div>
                <h5>
                  <i className="fa fa-language" aria-hidden="true"></i>&nbsp; &nbsp;{t('Language')}
                </h5>
                <select
                  className="select"
                  value={information?.language}
                  onChange={handleChange}
                  name="language"
                >
                  <option key={'english'} value={'English'}>
                    {'English'}
                  </option>
                  <option key={'japanese'} value={'Japanese'}>
                    {'Japanese'}
                  </option>
                </select>
                <div className="spacer-30"></div>
                <div className="d-flex justify-content-center">
                  <div>
                    <button className="btn-main m-1" onClick={handleSave}>
                      {t('button.update-profile')}
                    </button>
                  </div>
                  <div>
                    <button className="btn-main m-1" onClick={handleUpdateFee}>
                      {t('button.update-creator-fee')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spacer-40"></div>

        <ProfileFAQ />
      </section>
      <Modal
        isOpen={openFeeModal}
        toggle={() => setOpenFeeModal(!openFeeModal)}
        backdrop
        keyboard={false}
        size="sm"
      >
        <div className="checkout">
          <div className="maincheckout">
            {/* {darkTheme ? (
              <button
                className="btn-close green"
                onClick={() => setOpenFeeModal(!openFeeModal)}
              >
                x
              </button>
            ) : (
              <button
                className="btn-close purple"
                onClick={() => setOpenFeeModal(!openFeeModal)}
              >
                x
              </button>
            )} */}

            <button
              className={`btn-close ${darkTheme ? 'green' : 'purple'}`}
              onClick={() => {
                setOpenFeeModal(!openFeeModal);
              }}
            >
              x
            </button>

            <CreatorFeeSetup
              reset={() => {
                setOpenFeeModal(false);
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Profile;
